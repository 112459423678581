import React,{useState,useEffect} from 'react'
import style from './Invite.module.css'
import learnersLOgo from "../../assests/1o1LearnersClub-Logo1-100-×-100px-2-300x300.png";
import { useLocation,useNavigate } from 'react-router-dom';
import {baseurl} from '../Url'

const Testing = () => {
const location=useLocation()
const navigate=useNavigate()
const searchParams = new URLSearchParams(location.search);
const token = searchParams.get('token');
const[username,setUserName]=useState("")
const[validation,setValidation]=useState(false)
async function validateToken() {
    await fetch(`${baseurl}/validate_invite/token`, {
      method: "POST",
      body: JSON.stringify({
        token:token
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          setValidation(true)
            setUserName(data.data[0].name)
        }
      });
  }
  useEffect(()=>{
   validateToken()
  },[token])
  return (
    <div className={style.Container}>
        <div className={style.InvitationContainer}>
            <div className={style.InvitationLogo}>
                <img src={learnersLOgo}/>
            </div>
            {validation?<> 
            <div className={style.InvitationContent}>
              
              <div className={style.InvitationHead}><p>{username} Invite you to join</p></div>
              <div className={style.InvitationText}><p>The 1o1LearnersClub seems a good community platform to learn new topics, skills, train & mentor others. It also helps to connect similar people. The AI learning companion is of huge help in lessons, doubt clearance, assessments etc
        </p></div>
          </div>
          <div className={style.InvitationButton}>
              <button onClick={()=>{
                  navigate("/register")
              }}>Join With {username}</button>
          </div></>:
           <div className={style.InvitationText}><p style={{color:"red"}}>Something wen't wrong please try again latter...
        </p></div>}
           
        </div>
    </div>
  )
}

export default Testing