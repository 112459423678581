
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey:`${process.env.REACT_APP_FIRBASE_API_KEY}`,
  authDomain: "oneoone-learning.firebaseapp.com",
  projectId: "oneoone-learning",
  storageBucket: "oneoone-learning.appspot.com",
  messagingSenderId: "574793636669",
  appId: "1:574793636669:web:95fcf6bc4f5e86b5954354",
  measurementId: "G-WGWTLRMGVS"
};
      const app = initializeApp(firebaseConfig);
      export const db = getFirestore(app);