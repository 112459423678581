import React, { useEffect, useRef, useState } from "react";
import style from "./MobileAssessment.module.css";
import { baseurl } from "../Url";
import Lottie from "lottie-react";
import { AnimationData } from "../../Animation/AnimationData";
import { HiSpeakerWave } from "react-icons/hi2";
import { TextField } from "@mui/material";
import { MdClose } from "react-icons/md";

const MobileAssessment = () => {
  const path = window.location.pathname;
  const splitPath = path.split("/");
  const learningId = splitPath[3];
  const currentTopicId = splitPath[4];
  const userId = splitPath[5];
  const adminStatus = splitPath[6];
  const source = splitPath[7];

  console.log(path);
  let Timer;

  const [topicName, setTopicName] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [topicId, setTopicId] = useState("");
  const [mainTopicId, setMainTopicId] = useState("");
  const [array, setArray] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [timerExpired, setTimerExpired] = useState(false);
  const [isTextareaDisabled, setIsTextareaDisabled] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(1);
  const [timerStarted, setTimerStarted] = useState(false);
  const [answerDetails, setAnswerDetails] = useState([]);
  const [remainingTime, setRemainingTime] = useState(0); // 60 seconds = 1 minute
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [answerComment, setAnswerComment] = useState([]);
  const [validationSuccess, setValidationSuccess] = useState(false);
  const [validationFail, setValidationFail] = useState(false);
  const [validatClicked, setValidateClicked] = useState(false);
  const [valid, setValid] = useState(false);
  const [AiId, setAiID] = useState("");
  const [questionPalette, setQuestionPalette] = useState(false);
  const [assessmentHistory, setAssessmentHistory] = useState(false);

  //timer for assessment

  useEffect(() => {
    if (timerStarted && !timerExpired && remainingTime > 0) {
      Timer = setTimeout(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000); // 1 second
    } else if (remainingTime === 0) {
      setTimerExpired(true);
      setIsTimerRunning(false);
      // handleTimerExpiration();
    }

    return () => {
      clearTimeout(Timer);
    };
  }, [timerStarted, timerExpired, remainingTime]);

  useEffect(() => {
    if (currentItem && currentItem.time&&source!=="support") {
      const timeInSeconds = parseInt(currentItem.time) * 60; // Convert minutes to seconds
      setRemainingTime(timeInSeconds);
      setTimeout(() => {
        setTimerStarted(true);
      }, 1000);
    }
    setTimerExpired(false);
  }, [currentItem]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds} `;
  };

  //assigning currentitem

  useEffect(() => {
    if (array && array.length > 0) {
      // const currentItem = array[currentIndex];
      setCurrentItem(array[currentIndex]);
    }
  }, [array, currentIndex]);

  //drag and drop

  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (e, item) => {
    if (
      currentItem.type === "Match the following" ||
      currentItem.type === "Match the following with image"
    ) {
      setDraggedItem(item);
    }
  };
  const handleDrop = (e, rowIndex) => {
    e.preventDefault();

    // Ensure that the dragged item is not dropped on itself
    if (draggedItem) {
      // Update the table data with the dropped item
      const updatedTableData = [...answers];
      updatedTableData[rowIndex] = draggedItem;

      // Update the state
      setAnswers(updatedTableData);

      // Reset draggedItem state
      setDraggedItem(null);
    }
  };

  const handleCheckCircleClick = () => {
    assesmentAnswer();
    setIsTextareaDisabled(true);
    setIsTimerRunning(false);
    if (!timerExpired) {
      clearTimeout(Timer); // Stop the timer
    }
  };

  const handleSkip = async () => {
    clearTimeout(Timer);
    if (timerStarted && answerDetails.length === 0) {
      await assesmentAnswer();
      setTimeout(() => {
        clearTimeout(Timer);
        setTimerExpired(false);
        setTimerStarted(false);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % array.length);
        setSelectedNumber((prevIndex) => prevIndex + 1);
        setIsTextareaDisabled(false);
        setAnswerDetails([]);
        setAnswers([]);
      }, 2000);
    } else {
      clearTimeout(Timer);
      setTimerExpired(false);
      setTimerStarted(false);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % array.length);
      setSelectedNumber((prevIndex) => prevIndex + 1);
      setIsTextareaDisabled(false);
      setAnswerDetails([]);
      setAnswers([]);
      setValid(false);
    }
    setValidateClicked(false);
  };

  const handleNext = () => {
    if (
      answerDetails.length === 0 &&
      answers.length > 0 &&
      source !== "support"
    ) {
      handleNextValidation();
    } else {
      setAnswers([]);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % array.length);
      setSelectedNumber((prevIndex) => prevIndex + 1);
      clearTimeout(Timer);
      setTimerExpired(false);
      setTimerStarted(false);
      setIsTextareaDisabled(false);
      setAnswerDetails([]);
      setValid(false);
    }
    setValidateClicked(false);
  };

  const handleNextValidation = async () => {
    clearTimeout(Timer);
    await assesmentAnswer();
    setTimeout(() => {
      clearTimeout(Timer);
      setAnswers([]);
      setTimerExpired(false);
      setTimerStarted(false);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % array.length);
      setSelectedNumber((prevIndex) => prevIndex + 1);
      setIsTextareaDisabled(false);
      setAnswerDetails([]);
    }, 2000);
  };

  //dictation audiuo
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);

  const handleAudioClick = (audioUrl, index) => {
    if (!audioPlayer || audioPlayer.src !== audioUrl) {
      const newAudioPlayer = new Audio(audioUrl);
      newAudioPlayer.play();
      setAudioPlayer(newAudioPlayer);
      setCurrentPlayingIndex(index);
    } else {
      if (audioPlayer.paused) {
        audioPlayer.play();
      } else {
        audioPlayer.pause();
      }
    }
  };

  const numbers = Array.from({ length: array.length }, (_, index) => index + 1);

  useEffect(() => {
    if (validationSuccess) {
      const timeoutId = setTimeout(() => {
        setValidationSuccess(false);
      }, 3000);
      console.log(validationSuccess);
      return () => clearTimeout(timeoutId);
    }
  }, [validationSuccess]);

  useEffect(() => {
    if (validationFail) {
      const timeoutId = setTimeout(() => {
        setValidationFail(false);
      }, 3000);
      console.log(validationFail);
      return () => clearTimeout(timeoutId);
    }
  }, [validationFail]);

  async function userDetail() {
    await fetch(`${baseurl}/userlearning/details/individual`, {
      method: "POST",
      body: JSON.stringify({
        userlearning_id: learningId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setOrganizationId(data.data[0].Organization_ID);
        setMainTopicId(data.data[0].Topics[0].topic_id);
        const matchingTopic = data.data[0].topics.find(
          (topic) => topic.id === currentTopicId
        );
        console.log(data.data[0].topics);
        if (matchingTopic) {
          setTopicId(matchingTopic.id);
          setTopicName(matchingTopic.value);
          assessmentDetails(
            data.data[0].Organization_ID,
            data.data[0].Topics[0].topic_id,
            matchingTopic.id,
            matchingTopic.value
          );
          getAiId(
            data.data[0].Organization_ID,
            data.data[0].Topics[0].topic_id,
            matchingTopic.id,
            matchingTopic.value
          );
        }
      });
  }

  async function assessmentDetails(orgId, MainTopic_id, topicId, topic_name) {
    await fetch(`${baseurl}/ai/assignment/generation/for/user/new`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        topic_id: topicId,
        topic_name: topic_name,
        learning_id: learningId,
        main_topic_id: MainTopic_id,
        user_id: userId,
        admin_mapped: adminStatus,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        const assessments = data.data[0]?.assessments;
        if (assessments) {
          const publishedAssessments = assessments.filter(
            (assessment) =>
              !assessment.publish_status ||
              assessment.publish_status === "publish"
          );
          // Set the state array directly to the filtered assessments
          setArray(publishedAssessments);
        }
      });
  }

  async function getAiId(orgId, MainTopic_id, topicId, topic_name) {
    await fetch(`${baseurl}/ai/lesson/generation/for/user`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: orgId,
        topic_id: topicId,
        topic_name: topic_name,
        learning_id: learningId,
        main_topic_id: MainTopic_id,
        user_id: userId,
        admin_mapped: adminStatus,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAiID(data.data[0]?.ai_id);
      });
  }

  async function assesmentAnswer() {
    const questionId = currentItem.id ? currentItem.id.toString() : "";
    const calculatedTime = parseInt(currentItem.time) * 60 - remainingTime;
    await fetch(`${baseurl}/user/assignment/answers/validation`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: organizationId,
        topic_id: topicId,
        topic_name: topicName,
        question: currentItem.question,
        question_id: questionId,
        answer: answers,
        ai_id: AiId,
        main_topic_id: mainTopicId,
        user_id: userId,
        question_type: currentItem.type,
        options: currentItem.options ? currentItem.options : [],
        unique_id: currentItem.unique_id,
        generated_by: currentItem.generation === "admin" ? "admin" : "",
        left: currentItem.left ? currentItem.left : [],
        right: currentItem.right ? currentItem.right : [],
        time_taken: calculatedTime.toString(),
        markif_correct: currentItem.mark,
        markif_incorrect: currentItem.negative_mark,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAnswerDetails(data.data);
        answerGet();
        // assesmentSummaryDetails()
        // setValidateLoader(false);
        console.log(data.data.result);
        if (data.data[0].answer_is === "Correct") {
          setValidationSuccess(true);
        } else {
          setValidationFail(true);
        }
      });
  }

  async function assesmentMore() {
    await fetch(
      `${baseurl}/ai/assignment/generation/for/user/more/assignments`,
      {
        method: "POST",
        body: JSON.stringify({
          Organization_ID: organizationId,
          topic_id: topicId,
          topic_name: topicName,
          learning_id: learningId,
          main_topic_id: mainTopicId,
          user_id: userId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          const filteredAssessments = data.data[0].assessments.filter(
            (assessment) =>
              !assessment.publish_status ||
              assessment.publish_status === "publish"
          );
          const newArray = [...array, filteredAssessments];
          setArray(newArray);
        }
      });
  }
  async function answerGet() {
    await fetch(`${baseurl}/user/assignment/answers/each_question`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
        org_id: organizationId,
        ai_id: AiId,
        question: currentItem.question,
        question_id: currentItem.id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAnswerComment(data.data);
      });
  }

  useEffect(() => {
    if (AiId) {
      answerGet();
    }
    console.log(currentItem);
  }, [AiId]);

  useEffect(() => {
    if (learningId) {
      userDetail();
    }
  }, [learningId]);

  return (
    <div className={style.container}>
      <div className={style.assessmentPage}>
        {/* <div className={style.assessmentContainer}> */}
        {currentItem.id ? (
          <div className={style.assessmentSection}>
            {/* <div className={style.sectionTop}> */}
            <div className={style.assessmentCount}>
              <button>
                Assessment no -{currentItem.id}/{array.length}
              </button>
              <button
                onClick={() => {
                  setQuestionPalette(true);
                }}
              >
                Question Palette
              </button>
              <button
                onClick={() => {
                  setAssessmentHistory(true);
                }}
              >
                Assessment History
              </button>
            </div>
            <div className={style.assessmentQuestionSection}>
              <div className={style.assessmentQuestion}>
                <div className={style.questionName}>
                  <p>{currentItem.id} .&nbsp;</p>
                </div>

                {currentItem.type === "fill in the blanks" &&
                currentItem.generation === "admin" ? (
                  <div className={style.questionValue}>
                    {currentItem.question
                      .split(/\[blank \d+\]/)
                      .map((part, index) =>
                        index ===
                        currentItem.question.split(/\[blank \d+\]/).length -
                          1 ? (
                          <span
                            key={index}
                            dangerouslySetInnerHTML={{ __html: part }}
                          />
                        ) : (
                          <React.Fragment key={index}>
                            <span>{part}</span>
                            <input
                              type="text"
                              value={answers[index] || ""}
                              onChange={(e) => {
                                const newAnswers = [...answers];
                                newAnswers[index] = e.target.value;
                                setAnswers(newAnswers);
                              }}
                              disabled={
                                timerExpired ||
                                isTextareaDisabled ||
                                source === "support"
                              }
                            />
                          </React.Fragment>
                        )
                      )}
                  </div>
                ) : currentItem.type === "fill in the blanks" ? (
                  <div className={style.questionValue}>
                    {currentItem.question.split(/_{2,}/).map((part, index) =>
                      index ===
                      currentItem.question.split(/_{2,}/).length - 1 ? (
                        <span
                          key={index}
                          dangerouslySetInnerHTML={{ __html: part }}
                        />
                      ) : (
                        <React.Fragment key={index}>
                          <span>{part}</span>
                          <input
                            type="text"
                            value={answers[index] || ""}
                            onChange={(e) => {
                              const newAnswers = [...answers];
                              newAnswers[index] = e.target.value;
                              setAnswers(newAnswers);
                            }}
                            disabled={
                              timerExpired ||
                              isTextareaDisabled ||
                              source === "support"
                            }
                          />
                        </React.Fragment>
                      )
                    )}
                  </div>
                ) : currentItem.type === "Match the following" ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentItem.question,
                    }}
                  ></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentItem.question,
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  border:
                    currentItem.type === "multiple choices" ||
                    currentItem.type === "multiple choice" ||
                    currentItem.type === "Match the following" ||
                    (currentItem.type === "fill in the blanks" &&
                      currentItem.question.includes("__") &&
                      currentItem.options.length < 0)
                      ? "none"
                      : "",
                }}
                className={style.assesmentAnswer}
              >
                {currentItem.type === "multiple choices" ||
                currentItem.type === "multiple choice" ? (
                  currentItem.options.map((optionObj, index) => {
                    let optionValue;
                    if (typeof optionObj === "string") {
                      optionValue = optionObj; // Direct string value
                    } else if (optionObj.option) {
                      optionValue = optionObj.option; // Nested within an object with "option" key
                    } else {
                      // If options are nested within objects with unique keys
                      const optionKey = Object.keys(optionObj)[0];
                      optionValue = optionObj[optionKey];
                    }
                    return (
                      <div key={index} className={style.multipleQuestion}>
                        <input
                          type="radio"
                          id={`option_${index}`}
                          name="assessmentOptions"
                          value={optionValue}
                          checked={answers.includes(optionValue)}
                          onChange={(e) => {
                            setAnswers([e.target.value]);
                          }}
                          disabled={
                            timerExpired ||
                            isTextareaDisabled ||
                            source === "support"
                          }
                        />
                        <label htmlFor={`option_${index}`}>{optionValue}</label>
                      </div>
                    );
                  })
                ) : currentItem.type === "fill in the blanks" &&
                  currentItem.question.includes("_") ? (
                  <></>
                ) : currentItem.type === "fill in the blanks" &&
                  !currentItem.question.includes("_") &&
                  currentItem.generation === "admin" ? (
                  <></>
                ) : currentItem.type === "fill in the blanks" &&
                  !currentItem.question.includes("_") &&
                  currentItem.generation !== "admin" ? (
                  <div className={style.answerTextarea}>
                    <TextField
                      id="outlined-basic"
                      multiline
                      rows={4}
                      maxRows={5}
                      label="Topic Name"
                      variant="outlined"
                      onChange={(e) => {
                        setAnswers([e.target.value]);
                      }}
                      value={answers[0]}
                      disabled={
                        timerExpired ||
                        isTextareaDisabled ||
                        source === "support"
                      }
                      sx={{ width: "100%", fontSize: "1.5rem" }}
                    />
                  </div>
                ) : currentItem.type === "Match the following" ? (
                  <div className={style.DropContainer}>
                    <div className={style.DropQuestions}>
                      {currentItem.left
                        ? currentItem.left.map((item, index) => {
                            return (
                              <div className={style.matchCard} key={index}>
                                <div className={style.matchCardLeft}>
                                  <p>{item}</p>
                                </div>
                                <div
                                  className={style.matchCardRight}
                                  onDragOver={(e) => e.preventDefault()}
                                  onDrop={(e) => handleDrop(e, index)}
                                >
                                  {answers[index] ? (
                                    <div className={style.DraggableItem}>
                                      {answers[index]}
                                    </div>
                                  ) : (
                                    <div className={style.DraggablePlaceholder}>
                                      {/* {draggedItem === null &&
                                                  "Drag Answer Here"} */}
                                      {"Drag Answer Here"}
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                    <div className={style.DropAnswers}>
                      <h5>Answers</h5>
                      <div className={style.CardContainer}>
                        {currentItem.right
                          ? currentItem.right.map((item, index) => {
                              return (
                                <div
                                  disabled={
                                    timerExpired || source === "support"
                                  }
                                  key={index}
                                  draggable
                                  onDragStart={(e) => handleDragStart(e, item)}
                                  className={style.Card}
                                >
                                  <p>{item} </p>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                ) : currentItem.type === "dictation" ? (
                  <div className={style.answerGrid}>
                    {currentItem.left.map((url, index) => {
                      return (
                        <div className={style.dictationCard} key={index}>
                          <div className={style.dictationLeft}>
                            <HiSpeakerWave
                              style={{
                                color:
                                  currentPlayingIndex === index ? "green" : "",
                              }}
                              className={style.speakerIcon}
                              onClick={() => handleAudioClick(url, index)}
                            />
                          </div>
                          <div className={style.dictationRight}>
                            <input
                              type="text"
                              placeholder="Enter your answer"
                              value={answers[index] || ""}
                              onChange={(e) => {
                                const newAnswers = [...answers];
                                newAnswers[index] = e.target.value;
                                setAnswers(newAnswers);
                              }}
                              disabled={
                                timerExpired ||
                                isTextareaDisabled ||
                                source === "support"
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : currentItem.type === "Match the following with image" ? (
                  <div className={style.DropContainer}>
                    <div className={style.DropQuestions}>
                      {currentItem.left
                        ? currentItem.left.map((item, index) => {
                            return (
                              <div className={style.dropCard} key={index}>
                                <div className={style.dropCardLeft}>
                                  <div className={style.DropDownImage}>
                                    <img
                                      src={item}
                                      alt={`Option ${index + 1}`}
                                    />
                                  </div>
                                </div>
                                <div
                                  className={style.dropCardRight}
                                  onDragOver={(e) => e.preventDefault()}
                                  onDrop={(e) => handleDrop(e, index)}
                                >
                                  {answers[index] ? (
                                    <div className={style.DraggableItem}>
                                      {answers[index]}
                                    </div>
                                  ) : (
                                    <div className={style.DraggablePlaceholder}>
                                      {"Drag Answer Here"}
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                    <div className={style.DropAnswers}>
                      <h5>Answers</h5>
                      <div className={style.CardContainer}>
                        {currentItem.right
                          ? currentItem.right.map((item, index) => {
                              return (
                                <div
                                  disabled={
                                    timerExpired || source === "support"
                                  }
                                  key={index}
                                  draggable
                                  onDragStart={(e) => handleDragStart(e, item)}
                                  className={style.Card}
                                >
                                  <p>{item} </p>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={style.answerTextarea}>
                    <TextField
                      id="outlined-basic"
                      multiline
                      rows={4}
                      maxRows={5}
                      // label="Enter your answer"
                      variant="outlined"
                      onChange={(e) => {
                        setAnswers([e.target.value]);
                      }}
                      value={answers[0]}
                      disabled={
                        timerExpired ||
                        isTextareaDisabled ||
                        source === "support"
                      }
                      sx={{ width: "100%", fontSize: "1.5rem" }}
                    />
                  </div>
                )}
              </div>
              <div className={style.bottomIcons}>
                {timerStarted && !timerExpired && (
                  <p>Time Remaining: {formatTime(remainingTime)} seconds</p>
                )}
                {timerExpired && <p style={{ color: "red" }}>Timer Expired!</p>}
              </div>
            </div>

            <div className={style.answer}>
              {answerDetails.length > 0 &&
                answerDetails.map((validation) => {
                  const resultStyle = {
                    color: validation.answer_is === "Correct" ? "green" : "red",
                  };
                  return (
                    <p key={validation.question_id} style={resultStyle}>
                      {validation.result}
                    </p>
                  );
                })}
            </div>
            {valid ? (
              <p className={style.Validtext}>Please Enter Your Answer</p>
            ) : (
              ""
            )}
            {/* </div> */}
            <div className={style.addButton}>
              <>
                {source === "support" ? (
                  ""
                ) : (
                  <button
                    className={style.ValidateButton}
                    onClick={() => {
                      if (answers.length > 0) {
                        handleCheckCircleClick();
                        setValidateClicked(true);
                      } else {
                        setValid(true);
                      }
                    }}
                    disabled={validatClicked || timerExpired}
                  >
                    Validate
                  </button>
                )}
                {currentIndex === array.length - 1 ? (
                  ""
                ) : (
                  <>
                    <button
                      onClick={() => {
                        handleNext();
                      }}
                      disabled={currentIndex === array.length - 1}
                      className={style.FinishButton}
                    >
                      Next Question
                    </button>
                    {source === "support" ? (
                      ""
                    ) : (
                      <button
                        onClick={() => {
                          handleSkip();
                        }}
                        disabled={currentIndex === array.length - 1}
                        className={style.SkipButton}
                      >
                        Skip
                      </button>
                    )}
                  </>
                )}
              </>
              {currentIndex === array.length - 1 && source !== "support" ? (
                <>
                  <button onClick={() => {}} className={style.FinishButton}>
                    Finish My Assessment
                  </button>
                  <button
                    onClick={() => {
                      assesmentMore();
                      // setLoading(true);
                    }}
                    className={style.SkipButton}
                  >
                    Add More Assessment
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div>No Assessments in mapped yet </div>
        )}

        {validationSuccess ? (
          <div
            className={style.Overlay}
            onClick={() => {
              setValidationSuccess(false);
            }}
          >
            <div className={style.assessmentSuccessAnimation}>
              <Lottie
                animationData={AnimationData.success}
                loop={false}
                duration
                autoplay
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {validationFail ? (
          <div
            className={style.Overlay}
            onClick={() => {
              setValidationFail(false);
            }}
          >
            <div className={style.assessmentFailAnimation}>
              <Lottie
                animationData={AnimationData.fail}
                loop={false}
                duration
                autoplay
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {/* </div> */}
      </div>

      {questionPalette ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.questionPaletteSection}>
            <div className={style.paletteHeader}>
              <p>Question Palette</p>
              <MdClose
                onClick={() => {
                  setQuestionPalette(false);
                }}
              />
            </div>
            <div className={style.paletteContainer}>
              <div className={style.paletteGrid}>
                {numbers.map((number) => {
                  return (
                    <button
                      style={
                        number === selectedNumber // Apply selection styles
                          ? {
                              border: "2px solid #a0d77c",
                              color: "#57aa3f",
                              background: "#e0fbdb",
                            } // Emphasize selected button
                          : {}
                      }
                      key={number}
                      onClick={() => {
                        setAnswers([]);
                        setCurrentIndex(number - 1);
                        setSelectedNumber(number);
                        clearTimeout(Timer);
                        setTimerExpired(false);
                        setTimerStarted(false);
                        setIsTextareaDisabled(false);
                        setAnswerDetails([]);
                        setValidateClicked(false);
                        setQuestionPalette(false);
                      }}
                      disabled={isTimerRunning}
                    >
                      {number}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {assessmentHistory ? (
        <>
          <div className={style.Overlay}></div>
          <div className={style.previousAnswer}>
            <div className={style.answerHeader}>
              <h4>Assessment History</h4>
              <MdClose
                onClick={() => {
                  setAssessmentHistory(false);
                }}
              />
            </div>

            <div className={style.previousAnswerContent}>
              {answerComment && answerComment.length > 0
                ? answerComment.map((answer, index) => {
                    const date = new Date(answer.CreatedTimestamp?.$date);
                    const formattedDate = date.toLocaleString();
                    const answers =
                      answer.answer && answer.answer.length > 0
                        ? answer.answer.join(",")
                        : "";
                    return (
                      <div key={index} className={style.AnswerList}>
                        <div className={style.AnswerSend}>
                          <div className={style.AnswerIcon}>
                            <span className={style.IconText}>
                              {answer.user_name?.charAt(0)}
                            </span>
                          </div>
                          <div className={style.AnswerText}>
                            <p>
                              <strong>{answer.user_name}</strong>{" "}
                              <span>{formattedDate}</span>
                            </p>
                            <p>Your Answer : {answers}</p>
                          </div>
                        </div>
                        <div className={style.AnswerReplay}>
                          <div className={style.AnswerIcon}>
                            <span style={{ background: "#d7bb95" }}>AI</span>
                          </div>
                          <div className={style.replayText}>
                            <p>
                              <strong>{answer.valuated_by}</strong>{" "}
                            </p>
                            <p>{answer.result}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : "No Answer"}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default MobileAssessment;
