import React,{useEffect,useState} from 'react'
import style from './ExamSummary.module.css'
import { useLocation,useNavigate } from "react-router-dom";
import {baseurl} from '../Url'

const ExamSummary = () => {

    const USERID = localStorage.getItem("USERID");
  const key = JSON.parse(localStorage.getItem("access-token"));
  const location = useLocation();
  const state = location.state;
  const topicID=state.topicID
  const peerRole=state?.peerRole
  const examId=state?.examId
  const navigate=useNavigate()
  const[answer,setAnswer]=useState([])
  const[total,setTotal]=useState("")
  const[correct,setCorrect]=useState("")
  const[inCorrect,setIncorrect]=useState("")
  const[unAttended,setUnAttended]=useState("")
  const learneruserid=localStorage.getItem("learneruserid")
  const [userId, setUserID] = useState(learneruserid?learneruserid:USERID);
  const learnertoken=localStorage.getItem("learnertoken");
  const[token,setToken]=useState(learnertoken?learnertoken:key)
  
  async function answerDetails() {
    await fetch(`${baseurl}/exam/summary`, {
      method: "POST",
      body: JSON.stringify({
       
        user_id: userId,
        exam_id:examId
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAnswer(data.data)
        if(data.data.length>0){
          setTotal(data.Total_questions[0].Total_questions)
        setCorrect(data.Correct_ans[0].Correct_ans)
        setIncorrect(data.Incorrect_ans[0].Incorrect_ans)
        setUnAttended(data.Skipped_questions[0].Skipped_questions)
        }
        
      });
  }
  useEffect(()=>{
    answerDetails()
  },[examId])

  return (
    <div className={style.Container}>
        <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>Exam Summary</p>
        </div>
        {/* <div className={style.HeaderButton} onClick={()=>{
          const source = peerRole !== undefined ? "support" : '';
          navigate("/learnings",{state:{
            peerRole:peerRole,source
          }})
        }}><button>Back to Learning</button></div> */}
        
      </div>
      <div className={style.Mark}>
        <div className={style.Marks}>
          {answer&&answer.length>0?
          <>
            <div style={{background:"#007bff"}} className={style.Markdiv}> 
            <div className={style.FirstText}>Totalmark</div>:&nbsp;
            <div className={style.SecondText}>{correct}/{total}</div>
            </div>
            <div style={{background:"#007bff"}} className={style.Markdiv}>  <div className={style.FirstText}>Correct Answer</div>:&nbsp;
            <div className={style.SecondText}>{correct}</div>  </div>
           <div style={{background:"#e63720"}} className={style.Markdiv}><div className={style.FirstText}>Wrong Answer</div>:&nbsp;
            <div className={style.SecondText}>{inCorrect}</div>  </div>
           <div style={{background:"#c2af04"}} className={style.Markdiv}> <div className={style.FirstText}>Unattended</div>:&nbsp;
            <div className={style.SecondText}>{unAttended}</div></div>
          </>
          :''}
          
        </div>
      </div>
      <div className={style.Answer}>
        {answer&&answer.length>0?answer.map((item,index)=>{
          const answers = item.user_answer && item.user_answer.length > 0 ? item.user_answer.join(",") : "";

          let questionContent;
          let optionsList;
      if (item.question_type === "fill in the blanks") {
        questionContent = (
          <div
            className={style.QuestionContent}
            dangerouslySetInnerHTML={{
              __html: `${item.qstn_id}.${item.question.replace(
                /\[blank (\d+)\]/g,
                '<input style="border:none;border-bottom: 1px dashed; padding-bottom: 2px;" type="text" >'
              )}`,
            }}
          />
        );
      }else if (item.question_type === "multiple choices") {
        optionsList = (
          <ul className={style.Options}>
            {item.options.map((option, index) => {
              let optionValue;
              if (typeof option === 'string') {
                optionValue = option; // Direct string value
              } else if (option.option) {
                optionValue = option.option; // Nested within an object with "option" key
              } else {
                // If options are nested within objects with unique keys
                const optionKey = Object.keys(option)[0];
                optionValue = option[optionKey];
              }
              return(
              <li key={index}>
                
                <span htmlFor={`optionValue${index}`}>{optionValue}</span>
              </li>
        )})}
          </ul>
        );
        questionContent = (
          <div
            className={style.QuestionContent}
            dangerouslySetInnerHTML={{ __html: `${item.qstn_id}.${item.question}` }}
          />
        );
      } else if (item.question_type === "Match the following") {
        questionContent = (
          <div>
            <div
              className={style.QuestionContent}
              dangerouslySetInnerHTML={{ __html: `${item.qstn_id}.${item.question}` }}
            />
           <div className={style.DropContainer}>
                          <div className={style.DropQuestions}>
                            <table>
                              <tbody>
                                {item.left
                                  ? item.left.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{item}</td>
                                          <td
                                            
                                          >
                                            
                                              <div
                                                className={
                                                  style.DraggablePlaceholder
                                                }
                                              >
                                                Drag Answer Here
                                              </div>
                                          
                                          </td>
                                        </tr>
                                      );
                                    })
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                          <div className={style.DropAnswers}>
                            <h5>Answers</h5>
                            <div className={style.CardContainer}>
                              {item.right
                                ? item.right.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={style.Card}
                                      >
                                        <p>{item} </p>
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                        </div>
          </div>
        );
      }  else {
        questionContent = (
          <div className={style.QuestionContent} dangerouslySetInnerHTML={{ __html: `${item.qstn_id}.${item.question}` }} />
        );
      }
      return (
        <div key={index} className={style.AnswerCard}>
          <div className={style.QuestionCount}>
            <span>
              {item.qstn_id}/{total}
            </span>
          </div>
          <div className={style.Question}>{questionContent}</div>
          {optionsList && (
            <div className={style.Options}>
              {optionsList}
            </div>
          )}
          <div className={style.Enterd}>
            <p>Your Answer: {answers}</p>
          </div>
          <div style={{backgroundColor:item.answer_is==="Incorrect"?"#f54e42":''}} className={style.CorrectAnswer}>
            <span>{item.result}</span>
          </div>
        </div>
      );
    }):'No Answers '}
      </div>
    </div>
  )
}

export default ExamSummary