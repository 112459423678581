import React,{useState,useEffect} from 'react'
import style from './Quiz.module.css'
import { baseurl } from "../Url";
import { useNavigate } from 'react-router-dom';

const Quiz = () => {
  const navigate=useNavigate()

  const OrgID = localStorage.getItem("ORGID");

  const[quizListData,setQuizListData]=useState([])

  async function quizList(id) {
    await fetch(`${baseurl}/quiz/list`, {
      method: "POST",
      body: JSON.stringify({
        org_id: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        const publishedQuiz = data.data.filter(item => {
          // Check if settings array exists and has at least one element
          if (item.settings && item.settings.length > 0) {
            // Check if publish_status exists in the first settings object
            return item.settings[0].publish_status === "publish";
          }
        });
        setQuizListData(publishedQuiz);
      });
  }

  useEffect(()=>{
    if(OrgID!==undefined){
      quizList(OrgID)
    }

  },[OrgID])


  return (
    <div className={style.Container}>
      <div className={style.Header}>
      <p>Dive Into Knowledge: Quiz Library</p>
      </div>
      <div className={style.QuizList}>
        <div className={style.QuizGrid}>
          {quizListData&&quizListData.length>0?quizListData.map((quiz,index)=>{
            return(
              <div onClick={()=>{
                navigate("/quiz/questions",{
                  state:{quizID:quiz.quiz_id,
                    quizName:quiz.quiz_name}
                })
              }} key={index} className={style.Card}>
            <p>{quiz.quiz_name}</p>
          </div>
            )

          }):"No quiz"}
        </div>
      </div>
    </div>
  )
}

export default Quiz