import React, { useState,useRef,useEffect } from 'react'
import style from './InnerHeadBar.module.css'
import DunoLogo from "../../assests/duno_main_logo.png";
import NotificationICon from "../../assests/26. Notification.png"
import MessageICon from "../../assests/Inbox.png";
import { useNavigate,useLocation } from 'react-router-dom';
import ActiveCalendar from "../../assests/activeCalendar.png";
import ActiveWorkOut from "../../assests/ActiveWeightlifting-sport-exercise-gym (1).png";
import BillingIcon from '../../assests/Billing.png'


const InnerHeadBar = ({ finished, timeRunning, setFinishPopup,attended }) => {
  const navigate=useNavigate();
  const location=useLocation()
  const popupRef = useRef(null);

  const UserName = localStorage.getItem("username");

  const [notificationPopUp,setNotificationPopUp]=useState(false)

  const getInitials = (name) => {
    if (!name) return '';

    const nameParts = name.trim().split(' ');
    
    if (nameParts.length === 1) {
      return nameParts[0].charAt(0).toUpperCase();
    } else {
      // If two or more names, return the first letter of the first two names
      return nameParts[0].charAt(0).toUpperCase() + nameParts[1].charAt(0).toUpperCase();
    }
  };

  const userInitials = getInitials(UserName);

  const handleProfileNavigation = () => {
    if (location.pathname==="/daily/drill"&&!finished && timeRunning&&attended) {
      setFinishPopup(true);
    }else  if(location.pathname==="/weekly/workout"&&!finished&&timeRunning&&attended){
      setFinishPopup(true)
    } else {
      navigate("/profile");
    }
  };

  const handleNotificationClick = (e) => {
    e.stopPropagation();
    if (location.pathname==="/daily/drill"&&!finished && timeRunning&&attended) {
      setFinishPopup(true);
    }else  if(location.pathname==="/weekly/workout"&&!finished&&timeRunning&&attended){
      setFinishPopup(true)
    } else {
      setNotificationPopUp(!notificationPopUp);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target) ) {
        setNotificationPopUp(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={style.Container}>
    <div className={style.topBar}>
      <div className={style.topImg}>
        <img src={DunoLogo} alt="Logo" />
      </div>

      <div className={style.topBarMenu}>
      <div className={style.notificationIconImage}>
          <img src={MessageICon} alt='Inbox'/>
        </div>
        <div style={{position:"relative"}} className={style.iconImage}>
          <img onClick={handleNotificationClick} src={NotificationICon} alt='notification'/>
        </div>
        <div className={style.userIcon}>
            <span onClick={handleProfileNavigation}>{userInitials}</span>
        </div>
      </div>
    </div>
    {notificationPopUp&&(
      <div ref={popupRef} className={style.notificationPopUp}>
        <div className={style.notificationHeader}>
          <p>Notifications</p>
       </div>
       <div className={style.notificationContent}>
      <div className={style.notificationItemContainer}>
         <div className={style.notificationItem}>
         <div className={style.notificationItemLeft}>
          <div className={style.notificationLeftImage}><img src={ActiveCalendar} alt='drill'/></div>
         </div>
         <div className={style.notificationItemRight}>
          <div className={style.notificationRightHeader}>
            <p>Daily Drill</p>
          </div>
          <div className={style.notificationRightContent}>
            <p>Today's Daily Drill is Ready!</p>
            <span>Dive into today's exercises and see how much you've progressed!</span>
          </div>
          <div className={style.notificationRightBottom}>
            <p>1 hour ago</p>
          </div>
         </div>
         </div>
      </div>

       <div className={style.notificationItemContainer}>
         <div className={style.notificationItem}>
         <div className={style.notificationItemLeft}>
          <div className={style.notificationLeftImage}><img src={ActiveWorkOut} alt='workOut'/></div>
         </div>
         <div className={style.notificationItemRight}>
          <div className={style.notificationRightHeader}>
            <p>Weekly Workout</p>
          </div>
          <div className={style.notificationRightContent}>
            <p>This Week's Weekly Workout is Set!
            </p>
            <span>Challenge yourself with what you've learned and push your boundaries!</span>
          </div>
          <div className={style.notificationRightBottom}>
            <p>1 day ago</p>
          </div>
         </div>
         </div>
       </div>

      <div className={style.notificationItemContainer}>
         <div className={style.notificationItem}>
         <div className={style.notificationItemLeft}>
          <div className={style.notificationLeftImage}><img src={BillingIcon} alt='workOut'/></div>
         </div>
         <div className={style.notificationItemRight}>
          <div className={style.notificationRightHeader}>
            <p>Subscription</p>
          </div>
          <div className={style.notificationRightContent}>
            <p>Your Subscription is About to Expire!
            
            </p>
            <span>You have just 7 days left before your subscription expires. Click here to renew your subscription!</span>
          </div>
          <div className={style.notificationRightBottom}>
            <p>1 day ago</p>
          </div>
         </div>
         </div>
      </div>
       
       </div>
      </div>
    )}
  </div>
  )
}

export default InnerHeadBar