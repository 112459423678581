import React, { useState, useEffect, useCallback } from "react";
import style from "./MyLearning.module.css";
import { GoXCircle } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";
import { MdClear } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import Loader from "../Loder/Loader";
import { Doughnut } from "react-chartjs-2";
import { BsCheckCircle } from "react-icons/bs";
import useRazorpay from "react-razorpay";
import learnersLOgo from "../../assests/1o1LearnersClub-Logo1-100-×-100px-2-300x300.png";
import { googleLogout } from "@react-oauth/google";
import { encryptJsonData } from "../EncryptionUtils";
import { getCountryCode } from "../CountryDetails";
import { baseurl } from "../Url";
import { AnimationData } from "../../Animation/AnimationData";
import Lottie from "lottie-react";
import { IoPersonCircleOutline } from "react-icons/io5";
import goldMedal from "../../assests/goldnew-removebg-preview.png";
import silverMedal from "../../assests/silvercutnew-removebg-preview.png";
import brownsMedal from "../../assests/brownNew-removebg-preview.png";
import Whiteboard from "../WhiteBoard/WhiteBoard";
import { BiClipboard } from "react-icons/bi";
import { Snackbar, Alert } from "@mui/material";
import GoalDetail from "../GoalDetails/GoalDetails";

const MyLearning = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState([]);
  const [response, setResponse] = useState(null);
  const [cardList, setCardList] = useState(false);
  const [cardListDetail, setCardListDetail] = useState([]);
  const [allCategories, setAllCategories] = useState(false);
  const [aboutExp, setAboutExp] = useState("");
  const [yearExp, setYearExp] = useState("");
  const [profilePopup, setProfilePopup] = useState(false);
  const [sessionExpire, setSessionExpire] = useState(false);
  const [lodaing, setLoading] = useState(true);
  const [pricePopup, setPricePopup] = useState(false);
  const [priceDetails, setPriceDetails] = useState({});
  const [clickedLearningId, setClickedLearningId] = useState("");
  const [clickedCategoryID, setClickedCategoryID] = useState("");
  const UserID = localStorage.getItem("USERID");
  const ClubID = localStorage.getItem("ClubID");
  const OrgID = localStorage.getItem("ORGID");
  const key = JSON.parse(localStorage.getItem("access-token"));
  const [learningId, setLearningId] = useState("");
  const mentor = localStorage.getItem("mentor");
  const learnertoken = localStorage.getItem("learnertoken");
  const clickedItem = localStorage.getItem("clickedItem");
  const clickedItemDes = localStorage.getItem("clickeditemDes");
  const [token, setToken] = useState(learnertoken ? learnertoken : key);
  const location = useLocation();
  const username = location.state?.username;
  const userid = location.state?.userID;
  const peerRole = location.state?.peerRole;
  const learneruserid = localStorage.getItem("learneruserid");
  const [user_ID, setUser_ID] = useState(
    peerRole !== undefined ? learneruserid : UserID
  );
  const [search, setSearch] = useState("");
  const [searchArray, setSearchArray] = useState([]);
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [leaderBoardShow, setLeaderBoardShow] = useState(false);
  const [rewardValues, setRewardValues] = useState([]);
  // const [whiteBoradShow, setWhiteBoardShow] = useState(false);
  const [whiteBoradIconShow, setWhiteBoardIconShow] = useState(true);
  const CountryDetails = localStorage.getItem("countrydetails");
  const clubIdsArray = ClubID?.split(",");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isGoalsValid, setIsGoalsValid] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const totalSteps = 5;
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const openPopup = () => setIsOpen(true);
  const closePopup = () => setIsOpen(false);

  const handleButtonClick = () => {
    setIsPopupOpen(true); // Open the popup
  };

  const handleResetClick = () => {
    setConfirmationVisible(true); // Show the confirmation popup
  };

  const handleConfirmReset = () => {
    setConfirmationVisible(false);
    deleteUserGoals();
    // alert("Goals have been reset!"); // Replace with actual reset logic
    // Add your reset goals logic here
  };

  const handleCancelReset = () => {
    setConfirmationVisible(false); // Hide the confirmation popup
  };

  const closeGoalPopup = () => {
    setIsPopupOpen(false);
    setConfirmationVisible(false); // Close the popup
  };

  const [formData, setFormData] = useState({
    user_id: localStorage.getItem("USERID") || "",
    learning_id: learningId,
    learning_objective: [],
    learning_objective_goal: [],
    user_status: [],
    learning_preference: [],
    learning_constraints: [],
  });

  const handleInputChange = (stepKey, question, value) => {
    setFormData((prev) => {
      const updatedStepData = [...prev[stepKey]];
      const existingQuestion = updatedStepData.find(
        (item) => Object.keys(item)[0] === question
      );

      if (existingQuestion) {
        existingQuestion[question] = Array.isArray(value) ? value : [value];
      } else {
        updatedStepData.push({
          [question]: Array.isArray(value) ? value : [value],
        });
      }

      return { ...prev, [stepKey]: updatedStepData };
    });
  };

  const callUserGoalsAPI = async () => {
    try {
      // Ensure user_id and learning_id are dynamically set
      const userId = localStorage.getItem("USERID") || "";
      const updatedFormData = {
        ...formData,
        user_id: userId,
        learning_id: learningId,
      };

      const response = await fetch(`${baseurl}/api/usergoals`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedFormData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
        closePopup();
        setCurrentStep(1);
        setSnackbarMessage("Goals added successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        const errorData = await response.json();
        console.log(errorData.message);
        setSnackbarMessage(errorData.message || "Error adding Goals");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.log(error.message);
      setSnackbarMessage("An error occurred while adding Goals");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const nextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderMenu = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <div className={style.popupHead}>
              <h2>Your Goals & Objectives</h2>
            </div>
            <div className={style.mainboxCtn}>
              <div className={style.popPara}>
                <p>
                  What are your primary goals for this course? (Select all that
                  apply)
                </p>
              </div>
              <div className={style.ListBox}>
                <ul>
                  {[
                    "Gain foundational knowledge",
                    "Improve practical skills",
                    "Prepare for exams or certifications",
                    "Apply knowledge to real-world projects",
                    "Enhance career prospects",
                    "Explore personal interest",
                  ].map((option) => (
                    <li key={option}>
                      <label>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleInputChange(
                              "learning_objective",
                              "primary goals",
                              e.target.checked
                                ? [
                                    ...(formData.learning_objective.find(
                                      (item) =>
                                        Object.keys(item)[0] === "primary goals"
                                    )?.["primary goals"] || []),
                                    option,
                                  ]
                                : formData.learning_objective
                                    .find(
                                      (item) =>
                                        Object.keys(item)[0] === "primary goals"
                                    )
                                    ?.["primary goals"]?.filter(
                                      (v) => v !== option
                                    )
                            )
                          }
                        />
                        {option}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={style.listOptBox}>
                <div className={style.listOpt}>
                  <p>What specific outcomes do you expect from this course?</p>
                  <select
                    onChange={(e) =>
                      handleInputChange(
                        "learning_objective",
                        "expected outcomes",
                        e.target.value
                      )
                    }
                  >
                    <option value="" disabled selected>
                      Choose your learning objective
                    </option>
                    <option>Mastering key concepts</option>
                    <option>Completing a specific project</option>
                    <option>Passing a test</option>
                    <option>Building a portfolio</option>
                    <option>Other</option>
                  </select>
                </div>
                <div className={style.listOpt}>
                  <p>
                    Are there any specific topics or skills you want to focus
                    on?
                  </p>

                  <div className={style.textBox}>
                    <input
                      type="text"
                      placeholder="E.g., Algebra, Calculus, etc."
                      onChange={(e) =>
                        handleInputChange(
                          "learning_objective",
                          "focus area topics and skills ",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div className={style.listOpt}>
                  <p>
                    Do you have a project or problem in mind that you want to
                    work on??
                  </p>
                  <div className={style.textBox}>
                    <input
                      type="text"
                      placeholder="E.g., Solving optimization problems, analyzing data"
                      onChange={(e) =>
                        handleInputChange(
                          "learning_objective",
                          "project or problem to work on",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <div className={style.popupHead}>
              <h2>Your Career or Application-Oriented Goals</h2>
            </div>

            <div className={style.mainboxCtn}>
              <div className={style.optionSet}>
                <p>Is this course related to your career goals?</p>
                <select
                  onChange={(e) =>
                    handleInputChange(
                      "learning_objective_goal",
                      "Is this course related to your career goals",
                      e.target.value
                    )
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                  </option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
              </div>

              <div className={style.optionSet}>
                <p>What industry or field are you aiming to work in?</p>
                <select
                  onChange={(e) =>
                    handleInputChange(
                      "learning_objective_goal",
                      "industry or field aiming",
                      e.target.value
                    )
                  }
                >
                  <option value="" disabled selected>
                    Choose your industry or field
                  </option>
                  <option>Technology</option>
                  <option>Education</option>
                  <option>Finance</option>
                  <option>Engineering</option>
                  <option>Research</option>
                </select>
              </div>

              <div className={style.optionSet}>
                <p>
                  Are you preparing for any specific exams, certifications, or
                  competitions ?
                </p>
                <select
                  onChange={(e) =>
                    handleInputChange(
                      "learning_objective_goal",
                      "preparing for",
                      e.target.value
                    )
                  }
                >
                  <option value="" disabled selected>
                    Choose the focus area
                  </option>
                  <option>Teaching</option>
                  <option>Research</option>
                  <option>Job-specific</option>
                  <option>Skills</option>
                  <option>Entrepreneurship</option>
                </select>
              </div>

              <div className={style.optionSet}>
                <p>Do you have a preferred application for your learning ?</p>
                <div className={style.textBox}>
                  <input
                    type="text"
                    placeholder="E.g., SAT, GRE, etc."
                    onChange={(e) =>
                      handleInputChange(
                        "learning_objective_goal",
                        "application for learning",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <div className={style.popupHead}>
              <h2>Your Background</h2>
            </div>

            <div className={style.mainboxCtn}>
              <div className={style.optionSet}>
                <p>What is your current level of knowledge in this subject?</p>
                <select
                  onChange={(e) =>
                    handleInputChange(
                      "user_status",
                      "level of knowledge",
                      e.target.value
                    )
                  }
                >
                  <option value="" disabled selected>
                    Choose your knowledge level
                  </option>
                  <option>Beginner</option>
                  <option>Intermediate</option>
                  <option>Advanced</option>
                </select>
              </div>
              <div className={style.optionSet}>
                <p>What is your educational background or current role?</p>
                <select
                  onChange={(e) =>
                    handleInputChange(
                      "user_status",
                      "educational background or current role",
                      e.target.value
                    )
                  }
                >
                  <option value="" disabled selected>
                    Choose your educational background or current role
                  </option>
                  <option>School student</option>
                  <option>College student</option>
                  <option>Working professional</option>
                  <option>Other</option>
                </select>
              </div>
              <div className={style.optionSet}>
                <p>What is your preferred learning style?</p>
                <select
                  onChange={(e) =>
                    handleInputChange(
                      "user_status",
                      "learning style",
                      e.target.value
                    )
                  }
                >
                  <option value="" disabled selected>
                    Choose your learning style
                  </option>
                  <option>Visual</option>
                  <option>Interactive</option>
                  <option>Reading</option>
                  <option>Listening</option>
                </select>
              </div>

              <div className={style.optionSet}>
                <p>Have you studied or worked on similar topics before?</p>
                <select
                  onChange={(e) =>
                    handleInputChange(
                      "user_status",
                      "experienced",
                      e.target.value
                    )
                  }
                >
                  <option value="" disabled selected>
                    Choose your answer
                  </option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
              </div>

              <div className={style.optionSet}>
                <p>How much time can you dedicate to learning each week?</p>
                <select
                  onChange={(e) =>
                    handleInputChange(
                      "user_status",
                      "learning time scheduled ",
                      e.target.value
                    )
                  }
                >
                  <option value="" disabled selected>
                    Choose your answer
                  </option>
                  <option>Daily (1-2 hours)</option>
                  <option>Weekly (5-10 hours)</option>
                  <option>Flexible</option>
                </select>
              </div>

              <div className={style.optionSet}>
                <p>
                  What challenges have you faced in learning this subject in the
                  past, if any?
                </p>
                <div className={style.textBox}>
                  <input
                    type="text"
                    placeholder="Mention if any"
                    onChange={(e) =>
                      handleInputChange(
                        "user_status",
                        "challenges faced",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <div className={style.popupHead}>
              <h2>Your Preferences</h2>
            </div>

            <div className={style.mainboxCtn}>
              <div className={style.optionSet}>
                <p>How do you prefer the course content to be delivered?</p>
                <div className={style.rad}>
                  <div>
                    <input
                      type="radio"
                      name="delivery"
                      value="Sequential"
                      onChange={(e) =>
                        handleInputChange(
                          "learning_preference",
                          "content preference",
                          e.target.value
                        )
                      }
                    />
                    Sequential (Start with basics and progressively advance)
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="delivery"
                      value="Modular"
                      onChange={(e) =>
                        handleInputChange(
                          "learning_preference",
                          "content preference",
                          e.target.value
                        )
                      }
                    />
                    Modular (Jump into specific topics of interest)
                  </div>
                </div>
              </div>
              <div className={style.optionSet}>
                <p>Do you prefer a fast-paced or relaxed learning approach?</p>
                <select
                  onChange={(e) =>
                    handleInputChange(
                      "learning_preference",
                      "learning approach",
                      e.target.value
                    )
                  }
                >
                  <option value="" disabled selected>
                    Choose a learning approach
                  </option>
                  <option>Fast-paced</option>
                  <option>Relaxed</option>
                  <option>Custom</option>
                </select>
              </div>

              <div className={style.optionSet}>
                <p>What type of assessment do you find most effective?</p>
                <select
                  onChange={(e) =>
                    handleInputChange(
                      "learning_preference",
                      "effective assessment type",
                      e.target.value
                    )
                  }
                >
                  <option value="" disabled selected>
                    Choose one
                  </option>
                  <option>Quizzes</option>
                  <option>Interactive simulations</option>
                  <option>Real-world projects</option>
                  <option>Case studies</option>
                </select>
              </div>

              <div className={style.optionSet}>
                <p>
                  Do you prefer individual learning/tasks or group
                  learning/tasks?
                </p>
                <select
                  onChange={(e) =>
                    handleInputChange(
                      "learning_preference",
                      "learning tasks type preference",
                      e.target.value
                    )
                  }
                >
                  <option value="" disabled selected>
                    select one
                  </option>
                  <option>Individual</option>
                  <option>Collaboration</option>
                </select>
              </div>

              <div className={style.optionSet}>
                <p>How would you like feedback on your progress?</p>
                <select
                  onChange={(e) =>
                    handleInputChange(
                      "learning_preference",
                      "feedback and progress preference",
                      e.target.value
                    )
                  }
                >
                  <option value="" disabled selected>
                    select
                  </option>
                  <option>Weekly summary</option>
                  <option>Instant feedback</option>
                  <option>Mentorship support</option>
                  <option>No feedback</option>
                </select>
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div>
            <div className={style.popupHead}>
              <h2>Your Constraints</h2>
            </div>

            <div className={style.mainboxCtn}>
              <div className={style.optionSet}>
                <p>
                  Do you have any deadlines or timelines for achieving your
                  goals?
                </p>
                <div className={style.textBox}>
                  <input
                    type="text"
                    placeholder="E.g., Complete in 2 months"
                    onChange={(e) =>
                      handleInputChange(
                        "learning_constraints",
                        "deadlines or timeline ",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
              <div className={style.optionSet}>
                <p>
                  Are there any specific days or times when you prefer learning?
                </p>
                <div className={style.textBox}>
                  <input
                    type="text"
                    placeholder="E.g., Busy on weekends"
                    onChange={(e) =>
                      handleInputChange(
                        "learning_constraints",
                        "availability limitations",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
              <div className={style.optionSet}>
                <p>
                  Do you face any limitations in accessing certain types of
                  content?
                </p>
                <div className={style.textBox}>
                  <input
                    type="text"
                    placeholder="E.g., Limited internet access"
                    onChange={(e) =>
                      handleInputChange(
                        "learning_constraints",
                        "accessibilty limitations of content",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const [currency, setCurrency] = useState(localStorage.getItem("currency"));
  const sessionHandle = () => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("ClubID");
    localStorage.removeItem("ORGID");
    localStorage.removeItem("USERID");
    localStorage.removeItem("mentor");
    localStorage.removeItem("learnertoken");
    localStorage.removeItem("learneruserid");
    localStorage.removeItem("username");
    localStorage.removeItem("newLearningId");
    localStorage.removeItem("MainLevelId");
    localStorage.removeItem("SubLevelId");
    localStorage.removeItem("clickedItem");
    localStorage.removeItem("clickeditemDes");
    localStorage.removeItem("functionCalled");
    localStorage.removeItem("rzp_checkout_anon_id");
    localStorage.removeItem("rzp_device_id");
    googleLogout();
    navigate("/login");
    window.location.reload();
  };
  useEffect(() => {
    if (CountryDetails === undefined || CountryDetails === null) {
      getCountryCode()
        .then((countryData) => {
          localStorage.setItem("countrydetails", true);
          localStorage.setItem("timezone", countryData.timezone);
          localStorage.setItem("currency", countryData.currency);
          setCurrency(countryData.currency);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [CountryDetails]);

  async function rewardPoint(id) {
    console.log(id);
    await fetch(`${baseurl}/reward/dashboard`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        club_id: clubIdsArray,
        main_topic_id: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setRewardValues(data.data);
      });
  }

  async function saveCardList() {
    await fetch(`${baseurl}/userlearning/details`, {
      method: "POST",
      body: JSON.stringify({ user_id: user_ID }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (
          data.status_code === 401 ||
          data.error === "Signature has expired"
        ) {
          setSessionExpire(true);
        } else {
          setCardListDetail(data.data);
        }
      });
  }
  async function searchModules() {
    const body = {
      org_id: OrgID,
      search: search,
      user_id: UserID,
    };
    console.log(body);
    const newdata = JSON.stringify(body);
    const encryptstring = encryptJsonData(newdata);
    await fetch(`${baseurl}/main/search`, {
      method: "POST",
      body: JSON.stringify({
        keys: encryptstring,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSearchArray(data.data);
        setLoading(false);
        setSearchCompleted(true);
      });
  }
  async function learningGenerate(clickedname, clickeddes) {
    await fetch(`${baseurl}/new/search/learning/mapping`, {
      method: "POST",
      body: JSON.stringify({
        user_id: UserID,
        club_id: clubIdsArray,
        org_id: OrgID,
        name: clickedname,
        des: clickeddes,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          saveCardList();
          localStorage.removeItem("clickedItem");
          localStorage.removeItem("clickeditemDes");
        }
      });
  }
  async function priceGet() {
    await fetch(`${baseurl}/fetch/price_list`, {
      method: "Post",
      body: JSON.stringify({
        code: currency,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setPriceDetails(data);
        }
      });
  }

  async function deleteUserGoals() {
    const userId = localStorage.getItem("USERID");
    const LearningIdLoc = localStorage.getItem("newLearningId"); // Retrieve user_id from localStorage

    if (!userId || !LearningIdLoc) {
      alert("User ID or Learning ID is missing.");
      return;
    }

    try {
      const response = await fetch(`${baseurl}/api/usergoals-delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          learning_id: LearningIdLoc,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // alert(data.message);
        setSnackbarMessage(
          "Goals reset successfully ! You Can Set new goals now."
        );
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        closeGoalPopup();
        console.log("Goal deleted successfully:", data);
      } else {
        console.error("Error:", data);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  }

  async function createOrder(
    amount,
    plan,
    currency,
    clickedcategoryID,
    clickedlearningId
  ) {
    const response = await fetch(`${baseurl}/create-order`, {
      method: "POST",
      body: JSON.stringify({
        user_id: UserID,
        amount: amount,
        learning_id: clickedlearningId,
        category_id: clickedcategoryID,
        plan: plan,
        currency: currency,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    });
    const data = await response.json();
    console.log(data);
    return data.data[0];
  }
  const [Razorpay] = useRazorpay();
  const handlePayment = useCallback(
    async (amount, plan, currency, clickedcategoryID, clickedlearningId) => {
      const order = await createOrder(
        amount,
        plan,
        currency,
        clickedcategoryID,
        clickedlearningId
      );
      // const prefill={
      //   ...prefilldata
      // }

      const options = {
        key: `${process.env.REACT_APP_RAZORPAY_TEST_KEY}`,
        amount: order.amount,
        currency: order.currency,
        name: "1o1 Learners Club",
        description: "Test Transaction",
        image: learnersLOgo,
        order_id: order.razorpay_order_id,
        handler: async function (response) {
          const data = {
            razorpay_signature: response.razorpay_signature,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
          };

          const result = await fetch(`${baseurl}/verify-payment`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          });
          const responseData = await result.json();
          console.log(responseData);
          if (responseData.status === true) {
            setPricePopup(false);
            saveCardList();
          }
        },
        prefill: {
          name: "",
          email: "",
          contact: "9999999999",
        },
        notes: {
          address: "1o1 Learners Club",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.on("payment.failed", function (response) {
        console.log(response.error.code);
        console.log(response.error.description);
        console.log(response.error.source);
        console.log(response.error.step);
        console.log(response.error.reason);
        console.log(response.error.metadata.order_id);
        console.log(response.error.metadata.payment_id);
      });
      rzpay.open();
    },
    [Razorpay]
  );

  useEffect(() => {
    if (search === "") {
      setSearchCompleted(false);
    }
  }, [search]);

  useEffect(() => {
    console.log(details);
  }, [details]);

  useEffect(() => {
    // Update user_ID when peerRole changes
    setUser_ID(peerRole !== undefined ? learneruserid : UserID);
  }, [peerRole]);

  useEffect(() => {
    const hasFunctionBeenCalled = localStorage.getItem("functionCalled");
    if (
      clickedItem !== undefined &&
      clickedItem !== null &&
      hasFunctionBeenCalled !== "true"
    ) {
      learningGenerate(clickedItem, clickedItemDes);
      localStorage.setItem("functionCalled", "true");
    } else {
      saveCardList();
    }
    setCardList(false);
    // priceGet()
  }, [user_ID]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (search !== "") {
        searchModules();
        setLoading(true);
      }
    }
  };
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        setClosing(false);
      }, 1000);
    }
  }, [closing]);

  return (
    <div className={style.Container}>
      {lodaing ? <Loader /> : ""}
      {profilePopup ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setProfilePopup(false);
            }}
          ></div>
          <div className={style.AddressSection}>
            <form action="">
              <div className={style.Formtext}>
                <p>Please enter experience details</p>
              </div>
              <input
                type="text"
                name=""
                id=""
                value={yearExp}
                required
                onChange={(e) => {
                  setYearExp(e.target.value);
                }}
                placeholder="Year Of experience"
              />
              <input
                type="text"
                name=""
                id=""
                value={aboutExp}
                required
                onChange={(e) => {
                  setAboutExp(e.target.value);
                }}
                placeholder="About experience"
              />

              <button type="submit">CONFIRM</button>
            </form>
          </div>
        </>
      ) : (
        ""
      )}
      {/* {whiteBoradIconShow ? (
        <div className={style.chatboticon}>
          <div
            onClick={() => {
              setWhiteBoardShow(true);
              setWhiteBoardIconShow(false);
            }}
            className={style.icontext}
          >
            <BiClipboard className={style.WhiteboardFloatingIcon} />
            <p> Whiteboard</p>
          </div>
        </div>
      ) : (
        ""
      )} */}

      {/* {whiteBoradShow ? (
        <div
          className={`${style.WhiteBoradContainer} ${
            closing ? style.closing : ""
          }`}
        >
          <div className={style.WhiteBoradHead}>
            <div className={style.HeadText}>White Board</div>
            <div
              className={style.WhiteBoradIcon}
              onClick={() => {
                setTimeout(() => {
                  setWhiteBoardIconShow(true);
                  setWhiteBoardShow(false);
                }, 500);
                setClosing(true);
              }}
            >
              <AiOutlineClose />
            </div>
          </div>
          <div className={style.WhiteBoradContent}>
            <Whiteboard />
          </div>
        </div>
      ) : (
        ""
      )} */}
      {pricePopup ? (
        <>
          <div
            onClick={() => {
              setPricePopup(false);
              setClickedCategoryID("");
              setClickedLearningId("");
            }}
            className={style.PriceDivOverlay}
          ></div>
          <div className={style.PriceDiv}>
            {priceDetails.data && priceDetails.data_show ? (
              <div className={style.PriceTable}>
                <table>
                  <thead>
                    <th style={{ backgroundColor: "#c7c6c5" }}>
                      <p className={style.TableHeadTextfirst}> PRICING </p>
                      <p className={style.TableHeadTextsecond}>TABLE</p>
                    </th>
                    {priceDetails.data.map((packageData) => {
                      function getHeaderBackgroundColor(packageData) {
                        if (packageData.PackageName === "Free") {
                          return "#34b7eb";
                        } else if (packageData.PackageName === "Basic") {
                          return "green";
                        } else if (packageData.PackageName === "Professional") {
                          return "#ebc230";
                        } else if (packageData.PackageName === "Advanced") {
                          return "red";
                        } else {
                          return "#c7c6c5";
                        }
                      }
                      function CurrencySymbolDisplay({ currencySymbol }) {
                        return (
                          <span
                            style={{ fontSize: "1rem" }}
                            className={style.PriceAmount}
                          >
                            {currencySymbol}
                          </span>
                        );
                      }
                      return (
                        <th
                          key={packageData.PackageCode}
                          style={{
                            backgroundColor:
                              getHeaderBackgroundColor(packageData),
                          }}
                        >
                          <p className={style.PriceText}>
                            {packageData.PackageName}
                          </p>
                          <p className={style.PriceAmount}>
                            {" "}
                            <CurrencySymbolDisplay
                              currencySymbol={packageData.FEE[0].CurrencySymbol}
                            />{" "}
                            {packageData.FEE[0].Amount}
                          </p>
                          <span className={style.PriceMonth}>Per Month</span>
                        </th>
                      );
                    })}
                  </thead>
                  <tbody>
                    {priceDetails.data_show.map((feature) => (
                      <tr key={feature.FeatureCode}>
                        <td>{feature.Name}</td>
                        {priceDetails.data.map((packageData) => (
                          <td key={packageData.PackageCode}>
                            {packageData.Features.some(
                              (f) => f.FeatureCode === feature.FeatureCode
                            ) ? (
                              <span style={{ color: "green" }}>
                                <BsCheckCircle />
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                <GoXCircle />
                              </span>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                    <tr
                      style={{ backgroundColor: "#c7c6c5" }}
                      className={style.ButtonRow}
                    >
                      <td></td>
                      {priceDetails.data.map((packageData) => {
                        function getButtonBackgroundColor(packageData) {
                          if (packageData.PackageName === "Free") {
                            return "#34b7eb";
                          } else if (packageData.PackageName === "Basic") {
                            return "green";
                          } else if (
                            packageData.PackageName === "Professional"
                          ) {
                            return "#ebc230";
                          } else if (packageData.PackageName === "Advanced") {
                            return "red";
                          } else {
                            return "#c7c6c5";
                          }
                        }
                        return (
                          <td
                            key={packageData.PackageCode}
                            style={{
                              backgroundColor:
                                getButtonBackgroundColor(packageData),
                            }}
                          >
                            <button
                              onClick={() => {
                                handlePayment(
                                  packageData.Fee[0].Amount,
                                  packageData.PackageName,
                                  packageData.FEE[0].mode,
                                  clickedCategoryID,
                                  clickedLearningId
                                );
                              }}
                              disabled={packageData.PackageName === "Free"}
                              className={style.TableButton}
                            >
                              Buy Now
                            </button>
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <p>Loading data...</p>
            )}
          </div>
        </>
      ) : (
        ""
      )}
      <div className={style.LearningDiv}>
        <div className={style.Head}>
          <div className={style.Input}>
            <BiSearch className={style.Icons} />
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setSearchArray([]);
              }}
              onFocus={(e) => {
                e.target.setAttribute("data-placeholder", e.target.placeholder);
                e.target.placeholder = "";
              }}
              onBlur={(e) => {
                e.target.placeholder =
                  e.target.getAttribute("data-placeholder");
              }}
              onKeyDown={handleKeyPress}
              placeholder="what do you want to learn?"
            />
            {search !== "" ? (
              <span
                onClick={() => {
                  setSearch("");
                  setSearchCompleted(false);
                  setSearchArray([]);
                }}
              >
                <MdClear className={style.Icons} />
              </span>
            ) : (
              ""
            )}
          </div>
          {mentor !== "true" ? (
            <div className={style.learningButton}>
              <button
                disabled={search === ""}
                onClick={() => {
                  searchModules();
                  setLoading(true);
                }}
              >
                Search Topics
              </button>
              {peerRole === "Intern" ? (
                ""
              ) : (
                <button
                  onClick={() => {
                    navigate("/category");
                  }}
                >
                  Course Gallary
                </button>
              )}
            </div>
          ) : (
            <div className={style.learningButton}>
              <button
                onClick={() => {
                  searchModules();
                  setLoading(true);
                }}
              >
                Search Topics
              </button>
            </div>
          )}
        </div>

        {!search ? (
          <div className={style.Grid}>
            {cardListDetail && cardListDetail.length > 0 ? (
              cardListDetail.map((item, index) => (
                <div key={index} className={style.Card}>
                  <div className={style.Cardhead}>
                    <div className={style.CardheadText}>
                      <p>{item.category_details}</p>
                    </div>
                  </div>
                  <div
                    // onClick={() => {
                    //   const source = peerRole !== undefined ? "support" : "";
                    //   navigate("/lerning/detail", {
                    //     state: {
                    //       learning_detail: item,
                    //       peerRole: peerRole,
                    //       source,
                    //     },
                    //   });
                    //   localStorage.setItem("newLearningId", item.learning_id);
                    //   localStorage.setItem(
                    //     "adminmapped",
                    //     item.admin_mapped_lessons
                    //   );
                    // }}
                    className={style.cardData}
                  >
                    <div className={style.mainContent}>
                      <p>{item.category_details}</p>
                      <div className={style.Lessondiv}>
                        <div className={style.FirstContent}>Goal</div>
                        <div className={style.SecondContent}>: {item.goal}</div>
                      </div>
                      <div className={style.Lessondiv}>
                        <div className={style.FirstContent}>Timeline</div>
                        <div className={style.SecondContent}>
                          : {item.timeline} Month
                        </div>
                      </div>
                      <div className={style.Lessondiv}>
                        <div className={style.FirstContent}>Status</div>
                        <div className={style.SecondContent}>
                          :{" "}
                          <span
                            style={{
                              color:
                                item.Status === "Not Started"
                                  ? "red"
                                  : item.Status === "In Progress"
                                  ? "#f2781b"
                                  : item.Status === "Completed"
                                  ? "green"
                                  : "",
                            }}
                          >
                            {item.Status}
                          </span>
                        </div>
                      </div>
                      {/* <div className={style.lesson}> */}
                      <div className={style.Lessondiv}>
                        <div className={style.FirstContent}>
                          No.of Modules :
                        </div>
                        <div className={style.SecondText}>
                          <span style={{ color: "#902ded" }}>
                            {item.Topics.length}
                          </span>
                        </div>
                      </div>
                      <div className={style.Lessondiv}>
                        <div className={style.FirstText}>Assessment Done</div>
                        &nbsp;
                        <div className={style.SecondText}>
                          :{" "}
                          <span style={{ color: "#902ded" }}>
                            {item.assessment_done}/{item.total_assessment}
                          </span>
                        </div>
                      </div>

                      {/* </div> */}
                    </div>

                    <div className={style.roundGraph}>
                      <Doughnut
                        data={{
                          labels: ["Completed", "Remaining"],
                          datasets: [
                            {
                              data: [
                                item.assessment_done,
                                item.total_assessment - item.assessment_done,
                              ],
                              backgroundColor: ["#5cab27", "#e0e0e0"],
                            },
                          ],
                        }}
                      />
                      <p>
                        Completion:{" "}
                        {item.total_assessment !== 0
                          ? `${(
                              (item.assessment_done / item.total_assessment) *
                              100
                            ).toFixed(2)}%`
                          : "0%"}
                      </p>

                      <div className={style.flashingButton}>
                        <button
                          className={style.flashing}
                          onClick={async () => {
                            const userId = localStorage.getItem("USERID");
                            const learningIds = item.learning_id;

                            if (userId && learningIds) {
                              try {
                                localStorage.setItem(
                                  "newLearningId",
                                  learningIds
                                );

                                const res = await fetch(
                                  `${baseurl}/list/usergoals-check`,
                                  {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                      user_id: userId,
                                      learning_id: learningIds,
                                    }),
                                  }
                                );

                                const data = await res.json();

                                if (!data.status) {
                                  openPopup();
                                  setLearningId(data.learning_id);
                                  console.log(
                                    "datakkaaaaaaa",
                                    data.learning_id
                                  );
                                } else {
                                  setLearningId(data.learning_id);

                                  const source =
                                    peerRole !== undefined ? "support" : "";

                                  navigate("/lerning/detail", {
                                    state: {
                                      learning_detail: item,
                                      peerRole: peerRole,
                                      source,
                                      learningId: data.learning_id,
                                    },
                                  });

                                  localStorage.setItem(
                                    "adminmapped",
                                    item.admin_mapped_lessons
                                  );
                                }
                              } catch (err) {
                                console.log(
                                  "Error occurred during the API call",
                                  err
                                );
                              }
                            }
                          }}
                        >
                          View Course
                        </button>

                        <button
                          className={style.flashing}
                          onClick={(event) => {
                            event.stopPropagation();
                            setLeaderBoardShow(true);
                            rewardPoint(item.Topics[0].topic_id);
                          }}
                        >
                          Leader Board
                        </button>

                        <button
                          className={style.flashing}
                          onClick={async () => {
                            const userId = localStorage.getItem("USERID");
                            const learningIds = item.learning_id;

                            if (userId && learningIds) {
                              try {
                                localStorage.setItem(
                                  "newLearningId",
                                  learningIds
                                );

                                const res = await fetch(
                                  `${baseurl}/list/usergoals-check`,
                                  {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                      user_id: userId,
                                      learning_id: learningIds,
                                    }),
                                  }
                                );

                                const data = await res.json();

                                if (!data.status) {
                                  openPopup();
                                  setLearningId(data.learning_id);
                                  console.log(
                                    "datakkaaaaaaa",
                                    data.learning_id
                                  );
                                } else {
                                  setLearningId(data.learning_id);

                                  localStorage.setItem(
                                    "newLearningId",
                                    item.learning_id
                                  );
                                  handleButtonClick();

                                  localStorage.setItem(
                                    "adminmapped",
                                    item.admin_mapped_lessons
                                  );
                                }
                              } catch (err) {
                                console.log(
                                  "Error occurred during the API call",
                                  err
                                );
                              }
                            }
                          }}
                        >
                          goal
                        </button>

                        {/* <button
                          className={style.flashing}
                          onClick={(event) => {
                            localStorage.setItem(
                              "newLearningId",
                              item.learning_id
                            );
                            handleButtonClick();
                          }}
                        >
                          Goals
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>{!lodaing ? "No Learning" : ""}</div>
            )}
          </div>
        ) : (
          <div className={style.SearchGrid}>
            {searchArray && searchArray.length > 0 ? (
              searchArray.map((searchitem, index) => {
                return (
                  <div className={style.SearchCardMain}>
                    {searchitem.mapped_lessons &&
                    searchitem.mapped_lessons.length > 0
                      ? searchitem.mapped_lessons.map((mappeditem) => {
                          return (
                            <div key={index} className={style.SearchCard}>
                              <div className={style.SearchCardHeading}>
                                <p>{mappeditem.name}</p>
                              </div>
                              <div className={style.SearchCardDes}>
                                <p>{mappeditem.des}</p>
                              </div>
                              <div className={style.SearchCardButton}>
                                <button
                                  onClick={() => {
                                    setSearch("");
                                    setSearchArray([]);
                                    setSearchCompleted(false);
                                  }}
                                >
                                  Already in your list
                                </button>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                    {searchitem.search_lessons &&
                    searchitem.search_lessons.length > 0
                      ? searchitem.search_lessons.map((search) => {
                          return (
                            <div className={style.SearchCard}>
                              <div className={style.SearchCardHeading}>
                                <p>{search.name}</p>
                              </div>
                              <div className={style.SearchCardDes}>
                                <p>{search.des}</p>
                              </div>
                              <div className={style.SearchCardButton}>
                                <button
                                  onClick={() => {
                                    learningGenerate(search.name, search.des);
                                    setLoading(true);
                                    setSearch("");
                                    setSearchCompleted(false);
                                  }}
                                >
                                  Add to My Learning
                                </button>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                );
              })
            ) : search && searchCompleted ? (
              <div className={style.ErrorMessage}>
                <p>Something went wrong, Please try again.</p>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      {leaderBoardShow ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setLeaderBoardShow(false);
            }}
          ></div>
          <div className={style.leaderBoard}>
            <div className={style.boardHeader}>
              <h3>LEADER BOARD</h3>
            </div>
            <div className={style.boardSection}>
              {rewardValues &&
                rewardValues.length > 0 &&
                rewardValues.map((value, i) => {
                  return (
                    <div className={style.boardValues} key={i}>
                      <div className={style.valueBadge}>
                        {i === 0 ? (
                          <img src={goldMedal} alt="" />
                        ) : i === 1 ? (
                          <img src={silverMedal} alt="" />
                        ) : i === 2 ? (
                          <img src={brownsMedal} alt="" />
                        ) : (
                          <p>{i + 1}</p>
                        )}
                      </div>
                      <div className={style.valueAvatar}>
                        <IoPersonCircleOutline />
                      </div>
                      <div className={style.playerNameStar}>
                        <p>{value.name}</p>
                        <p>{value.point}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {sessionExpire ? (
        <div className={style.session}>
          <div className={style.session_close}>
            <AiOutlineClose onClick={() => setSessionExpire(false)} />
          </div>
          <div className={style.session_content}>
            <p>Session is Expired.Please Log In !</p>
          </div>
          <div className={style.session_button}>
            <button
              onClick={() => {
                sessionHandle();
              }}
            >
              Log In
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      {isPopupOpen && (
        <div className={style.popupoverlay} onClick={closeGoalPopup}>
          <div
            className={style.popupcontent}
            onClick={(e) => e.stopPropagation()}
          >
            <button className={style.closeButton} onClick={closeGoalPopup}>
              &times;
            </button>
            <GoalDetail onClose={closeGoalPopup} />

            <button className={style.resetBtn} onClick={handleResetClick}>
              Reset Goals
            </button>

            {isConfirmationVisible && (
              <div className={style.confirmationOverlay}>
                <div className={style.confirmationContent}>
                  <p>Are you sure you want to reset your goals?</p>
                  <div className={style.btnGrp}>
                    <button
                      className={style.cancelButton}
                      onClick={handleCancelReset}
                    >
                      Cancel
                    </button>

                    <button
                      className={style.confirmButton}
                      onClick={handleConfirmReset}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {isOpen && (
        <div className={style.popup}>
          <div className={style.popupContent}>
            <button
              className={style.closeButton}
              onClick={() => {
                closePopup();
                setCurrentStep(1);
              }}
              aria-label="Close"
            >
              &times;
            </button>

            <div className={style.progressBox}>
              <div className={style.progressBar}>
                {Array.from({ length: totalSteps }, (_, index) => (
                  <div
                    key={index + 1}
                    className={`${style.stepNumber} ${
                      currentStep === index + 1 ? style.activeStep : ""
                    }`}
                    onClick={() => setCurrentStep(index + 1)} // Change step on click
                  >
                    {index + 1}
                  </div>
                ))}
                <div
                  className={style.progress}
                  style={{
                    width: `${((currentStep - 1) / (totalSteps - 1)) * 100}%`,
                  }}
                />

                <div className={style.progress2} />
              </div>
            </div>
            <div className={style.goalbox}>
              {renderMenu()}

              <div className={style.navigationButtons}>
                <button onClick={prevStep} disabled={currentStep === 1}>
                  Previous
                </button>
                {currentStep < totalSteps ? (
                  <button onClick={nextStep}>Next</button>
                ) : (
                  <button onClick={callUserGoalsAPI}>Finish</button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default MyLearning;
