import React from 'react'
import './App.css';
import { Outlet } from 'react-router-dom';
import HeadBar from './Components/HeadBar/HeadBar';


function Layer() {
  return (
    <div className='layer' >

    <HeadBar/>
    <Outlet />

    </div>
  )
}

export default Layer