import React, { useState, useEffect } from "react";
import style from "./NewLearningDetail.module.css";
import LeftArrow from "../../assests/LeftArrow.png";
import { useLocation, useNavigate } from "react-router-dom";
import { baseurl } from "../Url";
import Loader from "../Loder/Loader";
import { useSelector } from "react-redux";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import ChatPopup from "../ChatPopup/ChatPopup";
import { BsRobot } from "react-icons/bs";

const NewLearningDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state;
  const Topic = state?.singleTopic;
  const learning_id = state?.learning;
  const admin_Mapped_Lessons = state?.mapped;
  const main_topic_id = state?.mainId;
  const topicNo = state?.topic_no;
  const [isChatPopupVisible, setChatPopupVisible] = useState(false);
  const key = JSON.parse(localStorage.getItem("access-token"));
  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");

  // Function to toggle ChatPopup visibility
  const openChatPopup = () => setChatPopupVisible(true);
  const closeChatPopup = () => setChatPopupVisible(false);

  const {
    userDetails,
    status: userStatus,
    error: userError,
  } = useSelector((state) => state.user);

  const [AiId, setAiID] = useState("");
  const [topicData, setTopicData] = useState({});
  const [assessmentData, setAssessmentData] = useState({});
  const [loading, setLoading] = useState(true);
  const [trialUser, setTrailUser] = useState(false);
  const [assessmentExist, setAssessmentExist] = useState(false);
  const [uniqueId, setUniqueId] = useState(false);
  const [current, setCurrent] = useState(1);
  const [noofpages, setNoOfPages] = useState(0);
  const [type, setType] = useState("");
  const [totalNoOfPages, setTotalNoOfPages] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [roomID, setRoomID] = useState("");

  const buttons = [
    { label: "All", type: "" },
    { label: "Notes", type: "uploaded lesson" },
    { label: "Images", type: "image" },
    { label: "Videos", type: "video_url" },
    { label: "PDF", type: "pdf" },
    { label: "AI Lessons", type: "ai_generated_text" },
    { label: "Questions", type: "questions" },
    { label: "Use-Case", type: "use case" },
    { label: "Summary", type: "summary" },
  ];

  const visibleButtons = buttons.slice(startIndex, startIndex + 8);

  const handleNext = () => {
    if (startIndex + 8 < buttons.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const decrement = () => {
    if (current > 1) {
      const updatedCurrent = current - 1;
      setCurrent(updatedCurrent);
      dataGet(updatedCurrent); // Call dataGet with the updated page number
    }
  };

  const increment = () => {
    if (current < totalNoOfPages) {
      const updatedCurrent = current + 1;
      setCurrent(updatedCurrent);
      dataGet(updatedCurrent); // Call dataGet with the updated page number
    }
  };

  async function Topic_Single() {
    await fetch(`${baseurl}/ai/lesson/generation/for/user`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        topic_name: Topic.value,
        learning_id: learning_id,
        main_topic_id: main_topic_id,
        user_id: USERID,
        admin_mapped: admin_Mapped_Lessons,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "initial data");
        setAiID(data.data[0]?.ai_id);
        setRoomID(data.data[0]?.room_id);
        setNoOfPages(data.data[0]?.no_of_pages);
        if (data.data[0]?.assessments?.length > 0) {
          setAssessmentExist(true);
        } else {
          setAssessmentExist(false);
        }
      });
  }

  async function dataGet(page) {
    await fetch(`${baseurl}/pagination/filter-pages`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        ai_id: AiId,
        user_id: USERID,
        type: type,
        current_page_index: page,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          const unique_Id = data.data?.[0]?.unique_id || null;
          setTopicData(data.data?.[0] || {});
          setUniqueId(unique_Id);
          setTotalNoOfPages(data.total_pages);
        } else {
          setTopicData({});
        }
        setLoading(false); // Move loading state update here
      });
  }

  async function previousDetails() {
    await fetch(`${baseurl}/summary/previous-attended/`, {
      method: "POST",
      body: JSON.stringify({
        user_id: USERID,
        topic_id: Topic.id,
        main_topic_id: main_topic_id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setAssessmentData(data.summary || {});
        } else {
          setAssessmentData({});
        }
      });
  }

  useEffect(() => {
    console.log(current);

    if (AiId !== "") {
      dataGet();
    } else {
      dataGet();
    }
  }, [AiId]);

  useEffect(() => {
    Topic_Single();
  }, [location.state]);

  useEffect(() => {
    if (AiId !== undefined || AiId !== "") {
      dataGet();
      previousDetails();
    }
  }, [AiId]);

  useEffect(() => {
    const userType =
      userStatus === "succeeded" && userDetails && userDetails.user_type;
    console.log(userType);

    if (userType === "Trail") {
      setTrailUser(true);
    } else {
      setTrailUser(false);
    }
  }, []);

  useEffect(() => {
    dataGet(current);
  }, [type, current]);

  return (
    <div className={style.container}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={style.learningBody}>
            <div
              className={style.header}
              style={{ background: trialUser ? "#FF8800" : "" }}
            >
              <div className={style.headerIcon}>
                <img
                 onClick={() => {
                  navigate(-1);
                }}
                  src={LeftArrow}
                  alt="Left"
                />
              </div>
              <div className={style.headerText}>
                <span>Topic {topicNo}</span>
                <h4>{Topic.value}</h4>
              </div>

              <div className={style.Pagination}>
                <button
                  disabled={current === 1}
                  onClick={() => {
                    decrement();
                  }}
                >
                  <GrFormPrevious className={style.arrows} />
                </button>
                <button>
                  {current}/{totalNoOfPages}
                </button>
                <button
                  disabled={current === totalNoOfPages}
                  onClick={() => {
                    increment();
                    // setLoading(true);
                  }}
                >
                  <GrFormNext className={style.arrows} />
                </button>
              </div>
            </div>

            <div className={style.filters}>
              <div className={style.filterItem}>
                <div className={style.scrollContainer}>
                  <div className={style.scrollBtnBox}>
                    <button
                      className={`${style.scrollButton} ${
                        startIndex === 0 ? style.disabledScrollButton : ""
                      }`}
                      onClick={handlePrev}
                      disabled={startIndex === 0}
                    >
                      <GrFormPrevious />
                    </button>
                  </div>

                  <div className={style.buttonGroup}>
                    {visibleButtons.map((button, index) => (
                      <button
                        key={index}
                        className={`${style.filterButton} ${
                          type === button.type ? style.active : ""
                        }`}
                        onClick={() => {
                          setType(button.type);
                          setCurrent(1);
                        }}
                      >
                        {button.label}
                      </button>
                    ))}
                  </div>

                  <div className={style.scrollBtnBox}>
                    <button
                      className={`${style.scrollButton} ${
                        startIndex + 8 >= buttons.length
                          ? style.disabledScrollButton
                          : ""
                      }`}
                      onClick={handleNext}
                      disabled={startIndex + 8 >= buttons.length}
                    >
                      <GrFormNext />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {Object.keys(topicData).length === 0 && !loading ? (
              <div className={style.info}>
                <p>No data found</p>
              </div>
            ) : (
              <div className={style.learningSection}>
                <div className={style.learningContent}>
                  <div className={style.contentxBox}>
                    {topicData?.type === "image" ? (
                      <div>
                        <span>{topicData.title ? topicData.title : ""}</span>
                        <img
                          src={topicData.text}
                          alt="Topic"
                          className={style.imageStyle} // Optional: Add a CSS class for styling the image
                        />
                      </div>
                    ) : topicData?.type === "video_url" ? (
                      <div>
                        <span>{topicData.title ? topicData.title : ""}</span>

                        <video
                          controls
                          className={style.videoStyle} // Optional: Add a CSS class for styling the video
                        >
                          <source src={topicData.text} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    ) : topicData?.type === "pdf" ? (
                      <div>
                        <span>{topicData.title ? topicData.title : ""}</span>

                        <iframe
                          src={topicData.text}
                          className={style.pdfStyle} // Optional: Add a CSS class for styling the iframe
                          frameBorder="0"
                          title="PDF Viewer"
                          width="100%"
                          height="560px"
                        ></iframe>
                      </div>
                    ) : (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: topicData?.text ? topicData.text : "",
                        }}
                      ></p>
                    )}
                  </div>
                </div>
                <div className={style.assessmentFloatingSection}>
                  <div className={style.floatingSectionLeft}>
                    <>
                      <span>Previous Score:</span>
                      <p>
                        {assessmentData && assessmentData.correct_answers
                          ? assessmentData.correct_answers
                          : 0}
                        /
                        {assessmentData && assessmentData.total_questions
                          ? assessmentData.total_questions
                          : 0}
                      </p>
                    </>
                  </div>

                  <div className={style.floatingSectionRight}>
                    <button
                      className={style.PracticalsBtn}
                      disabled={!assessmentExist}
                      onClick={() => {
                        navigate("/activity", {
                          state: {
                            aiID: AiId,
                            singleTopic: Topic,
                            mapped: admin_Mapped_Lessons,
                            learning: learning_id,
                            mainID: main_topic_id,
                            topicNo: topicNo,
                            uniqueId: uniqueId,
                            roomID:roomID,
                          },
                        });
                      }}
                    >
                      Activity
                    </button>

                    {!trialUser ? (
                      assessmentData && assessmentData.is_attended ? (
                        <button
                          disabled={!assessmentExist}
                          style={{ backgroundColor: "#FFBE00" }}
                          onClick={() => {
                            navigate("/assessments", {
                              state: {
                                aiID: AiId,
                                singleTopic: Topic,
                                mapped: admin_Mapped_Lessons,
                                learning: learning_id,
                                mainID: main_topic_id,
                                topicNo: topicNo,
                              },
                            });
                          }}
                        >
                          Assessments
                        </button>
                      ) : (
                        <button
                          disabled={!assessmentExist}
                          onClick={() => {
                            navigate("/assessments", {
                              state: {
                                aiID: AiId,
                                singleTopic: Topic,
                                mapped: admin_Mapped_Lessons,
                                learning: learning_id,
                                mainID: main_topic_id,
                                topicNo: topicNo,
                              },
                            });
                          }}
                        >
                          Assessments
                        </button>
                      )
                    ) : (
                      !assessmentData.is_attended && (
                        <button
                          disabled={!assessmentExist}
                          onClick={() => {
                            navigate("/assessments", {
                              state: {
                                aiID: AiId,
                                singleTopic: Topic,
                                mapped: admin_Mapped_Lessons,
                                learning: learning_id,
                                mainID: main_topic_id,
                                topicNo: topicNo,
                              },
                            });
                          }}
                        >
                          Assessments
                        </button>
                      )
                    )}

                    <button
                      className={style.AnalyticsBtn}
                      onClick={() => {
                        navigate("/insights", {
                          state: {
                            aiID: AiId,
                            singleTopic: Topic,
                            mapped: admin_Mapped_Lessons,
                            learning: learning_id,
                            mainID: main_topic_id,
                            topicNo: topicNo,
                            roomID:roomID,
                          },
                        });
                      }}
                    >
                      Analytics
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {isChatPopupVisible && (
            <ChatPopup
              token={key}
              UserName={userDetails?.name}
              setChatIcon={closeChatPopup}
              aiID={AiId}
              singleTopic={Topic}
              mapped={admin_Mapped_Lessons}
              learning={learning_id}
              mainID={main_topic_id}
              topicNo={topicNo}
              roomID={roomID}
            />
          )}
        </>
      )}

      <div className={style.chatIconWrapper}>
        <div className={style.chatBox}>
          <BsRobot
            className={style.chatIcon} // You can style this icon as needed
            onClick={openChatPopup} // Open ChatPopup when clicked
          />
        </div>
      </div>

    </div>
  );
};

export default NewLearningDetail;
