import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseurl } from "../Components/Url";

export const fetchUserDetails = createAsyncThunk('user/fetchUserDetails', async (userid,token) => {
    const response = await fetch(`${baseurl}/user/detail/get`,{
        method:"POST",
        body: JSON.stringify({
           user_id:userid
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
    });
    
    if (!response.ok) {
      throw new Error('Failed to fetch user details');
    }
  
    const data = await response.json();
    return data.data[0];
  });
  
  const userSlice = createSlice({
    name: 'user',
    initialState: {
      userDetails: {},
      status: 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchUserDetails.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchUserDetails.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.userDetails = action.payload;
        })
        .addCase(fetchUserDetails.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });
  
  export default userSlice.reducer;