import React ,{useEffect, useState} from 'react'
import style from './Search.module.css'
import learnersLOgo from "../../assests/duno club.png";
import { BiSearch } from 'react-icons/bi';
import { MdOutlineClear } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loder/Loader'
import {baseurl} from '../Url'

const Search = () => {
    const[search,setSearch]=useState("")
    const[searchedItems,setSearchedItems]=useState([])
    const key = JSON.parse(localStorage.getItem("access-token"));
    const navigate=useNavigate()
    const[loading,setloading]=useState(false);
    const[searchCompleted,setsearchCompleted]=useState(false);
    async function searchModules() {
        await fetch(`${baseurl}/modules/generation`, {
          method: "POST",
          body: JSON.stringify({
            search_term:search
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
           setSearchedItems(data.data)
           setloading(false)
           setsearchCompleted(true)
          });
      }

      const handleKeyPress = (event) => {
        if (event.key === "Enter") {
          if(search!==""){
            searchModules()
            setloading(true)
          }
        }
      };
useEffect(()=>{
  if (search.length===0) {
    setsearchCompleted(false)
  }
},[search])

  return (
    <div className={style.Container}>
      {loading?<Loader/>:''}
            <div className={style.MainContent}>
                {/* <div className={style.left}>
            <div className={style.LeftContent}>
              <div className={style.LogoImage}>
              <img src={learnersLOgo} />
              </div>
              <div className={style.heading}>
                <p>1 on 1 Training <br/>
                to reach goals</p>
              </div>
              <div className={style.HeadingText}>
                <p>As eLearning progressed, the ability to integrate elements such as images, videos, audio and graphics</p>
              </div>
            </div>
    
          </div> */}
          {/* <div className={style.right}> */}
            <div className={style.SearchContent}>
            <div className={style.LogoImage}>
              <img src={learnersLOgo} />
              </div>
                <div className={style.SearchText}><p>Learn Anything</p></div>
                <div className={style.SearchInput}>
                    <BiSearch className={style.SearchIcon}/>
                    <input value={search} onChange={(e)=>{
                        setSearch(e.target.value)
                    }} onFocus={(e) => {
                      e.target.setAttribute("data-placeholder", e.target.placeholder);
                      e.target.placeholder = "";
                    }}
                    onBlur={(e) => {
                      e.target.placeholder = e.target.getAttribute("data-placeholder");
                    }} onKeyDown={handleKeyPress} placeholder='Search here...'/>
                    {search?<MdOutlineClear onClick={()=>{
                        setSearch("")
                        setsearchCompleted(false)
                        setSearchedItems([])
                    }} className={style.SearchIcon}/>:''}
                </div>
                <div className={style.SearchButton}>
                    <button disabled={search===""} onClick={()=>{
                        searchModules()
                        setloading(true)
                    }}>Start Learning</button>
                </div>
            </div>
          {/* </div> */}
            </div>
            <div className={style.CardDiv}>
                <div className={style.Grid}>
                    {searchedItems&&searchedItems.length>0?(searchedItems.map((search)=>{
                        return(
                            <div className={style.Cards}>
                        <div className={style.Cardheading}><p>{search.name}</p></div>
                        <div className={style.CardDescription}><p>{search.des}</p></div>
                        <div className={style.cardButton}><button disabled={search.name===""} onClick={()=>{
                          setsearchCompleted(false)
                          localStorage.setItem("clickedItem",search.name)
                          localStorage.setItem("clickeditemDes",search.des)
                          localStorage.setItem("functionCalled", "false");
                          if(key!==null&&key!==undefined){
                            navigate("/learnings")
                          }
                          else{
                            navigate("/register")
                          }
                        }}>Add to Learning</button></div>
                    </div>
                        )
                    })):
                    (search&&searchCompleted?<div className={style.ErrorMessage}>
                      <p>Something went wrong, Please try again.</p>
                    </div>:"")}
                </div>
            </div>
    </div>
  )
}

export default Search