 export const getCountryCode = async () => {
    try {
        const response = await fetch(`https://ipapi.co/json/`);
        const data = await response.json();
    
        return {
          timezone: data.timezone,
          currency: data.currency,
          callingCode: data.country_calling_code,
        };
    } catch (error) {
      console.log(error);
    }
  };