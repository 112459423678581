import style from "./insight.module.css";
import React, { useEffect, useState } from "react";
import { baseurl } from "../Url";
import { useLocation, useNavigate } from "react-router-dom";
import LeftArrow from "../../assests/LeftArrow.png";
import Loader from "../Loder/Loader"; // Ensure this path is correct
import { PiExam } from "react-icons/pi";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { Bar } from "react-chartjs-2";
import ChatPopup from "../ChatPopup/ChatPopup";
import { BsRobot } from "react-icons/bs";


function Insights() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const singleTopic = state?.singleTopic;
  const mainTopicId = state?.mainID;
  const aiID = state?.aiID;
  const topicNo = state?.topic_no;
  const roomID = state?.roomID;

  const key = JSON.parse(localStorage.getItem("access-token"));
  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");
  const ClubID = localStorage.getItem("ClubID");

  const clubIdsArray = ClubID.split(",");

  const [analyticsData, setAnalyticsData] = useState([]);
  const [total, setTotal] = useState("");
  const [correct, setCorrect] = useState("");
  const [inCorrect, setIncorrect] = useState("");
  const [unAttended, setUnAttended] = useState("");
  const [totalMark, setTotalMark] = useState("");
  const [obtainedMark, setObtainedmark] = useState("");
  const [peerRole, setPeerRole] = useState("Intern");
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true); // Initialize loading state

  const [isChatPopupVisible, setChatPopupVisible] = useState(false);
  const openChatPopup = () => setChatPopupVisible(true);
  const closeChatPopup = () => setChatPopupVisible(false);

  const data = {
    labels: ["Topic Completion", "Assessment Completion"],
    datasets: [
      {
        label: "Percentage",
        backgroundColor: ["#007bff", "#007bff"],
        data: graphData.map((item) => {
          if (item.topic_completion_percentage) {
            return item.topic_completion_percentage.y;
          } else if (item.assesments_completion_percentage) {
            return item.assesments_completion_percentage.y;
          }
        }),
        barThickness: 70,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        min: 0,
        stepSize: 10,
        ticks: {
          callback: function (value) {
            return value + "%";
          },
        },
      },
    },
  };

  async function analyticsGet() {
    await fetch(`${baseurl}/topic/dashboard`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        user_id: USERID,
        club_ids: clubIdsArray,
        main_topic_id: mainTopicId,
        topic_id: singleTopic.id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAnalyticsData(data.data[0].Learner_Engagement);
      });
  }

  async function assesmentSummaryDetails() {
    await fetch(`${baseurl}/ai/assignment/summary`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: singleTopic.id,
        user_id: USERID,
        ai_id: aiID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.data.length > 0) {
          setTotal(data.Total_questions[0].Total_questions);
          setCorrect(data.Correct_ans[0].Correct_ans);
          setIncorrect(data.Incorrect_ans[0].Incorrect_ans);
          setUnAttended(data.Skipped_questions[0].Skipped_questions);
          setTotalMark(data.Total_mark[0].Total_mark);
          setObtainedmark(data.Obtained_mark[0].User_Secured_mark);
        }
      });
  }

  async function getLearningLevel() {
    await fetch(`${baseurl}/topic/dashboard/learning_level`, {
      method: "POST",
      body: JSON.stringify({
        ai_id: aiID,
        user_id: USERID,
        main_topic_id: mainTopicId,
        topic_id: singleTopic.id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setGraphData(data.data);
      });
  }

  useEffect(() => {
    async function fetchData() {
      await getLearningLevel();
      await assesmentSummaryDetails();
      await analyticsGet();
      setLoading(false); // Stop loading once all data is fetched
    }

    fetchData();
  }, []);

  if (loading) return <Loader />; // Show loader while loading

  return (
    <div className={style.container}>
      <div className={style.InsightBox}>
          <div className={style.header}>
            <div className={style.headerIcon}>
              <img
                onClick={() => {
                  navigate(-1);
                }}
                src={LeftArrow}
                alt="Left"
              />
            </div>
            <div className={style.headerText}>
              <span> Insights </span>
              <h4>{singleTopic.value}</h4>
            </div>
          </div>
    
          <div className={style.insightsContainer}>
            <div className={style.analyticsMain}>
              <div className={style.Graph}>
                <Bar data={data} options={options} />
              </div>
              <div className={style.analytics}>
                <div className={style.assessmentCount}>
                  <div className={style.assessmentIcon}>
                    <PiExam />
                  </div>
                  <div className={style.assessmentText}>
                    <p>Assessment Attended</p>
                    <span style={{ color: "#3d0a24" }}>:</span>
                    <span>{analyticsData.Assesmnet_attend_count}</span>
                  </div>
                </div>
                <div className={style.ongoingTopic}>
                  <div className={style.topicLeft}>
                    <p>Ongoing Topic</p>
                  </div>
                  <span>:</span>
                  <div className={style.topicRight}>
                    <p>{analyticsData.Ongoing_Topic}</p>
                  </div>
                </div>
                <div className={style.QuriesAsked}>
                  <div className={style.assessmentIcon}>
                    <BsFillQuestionCircleFill />
                  </div>
                  <div className={style.topicText}>
                    <p>Queries Asked</p>
                    <span style={{ color: "#3d0a24" }}>:</span>
                    <span>{analyticsData.query_asked_count}</span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    const source = peerRole !== undefined ? "support" : "";
                    navigate("/answers", {
                      state: {
                        topicID: singleTopic.id,
                        peerRole: peerRole,
                        source,
                        aiId: aiID,
                      },
                    });
                  }}
                  className={style.assessmentSummaryCount}
                >
                  <h4>Assessment Summary</h4>
                  <div className={style.TextContainer}>
                    <div className={style.LeftText}>
                      <p>Total Mark</p>
                    </div>
                    <span>:</span>
                    <div className={style.RightText}>
                      <p>
                        {obtainedMark || 0}/{totalMark || 0}
                      </p>
                    </div>
                  </div>
                  <div className={style.TextContainer}>
                    <div className={style.LeftText}>
                      <p>Correct Answer</p>
                    </div>
                    <span>:</span>
                    <div className={style.RightText}>
                      <p>{correct || 0}</p>
                    </div>
                  </div>
                  <div className={style.TextContainer}>
                    <div className={style.LeftText}>
                      <p>Wrong Answer</p>
                    </div>
                    <span>:</span>
                    <div className={style.RightText}>
                      <p>{inCorrect || 0}</p>
                    </div>
                  </div>
                  <div className={style.TextContainer}>
                    <div className={style.LeftText}>
                      <p>Unattended</p>
                    </div>
                    <span>:</span>
                    <div className={style.RightText}>
                      <p>{unAttended || 0}</p>
                    </div>
                  </div>
                </div>

                {isChatPopupVisible && (
                <ChatPopup
                  token={key}
                  setChatIcon={closeChatPopup}
                  aiID={aiID}
                  singleTopic={singleTopic}
                  topicNo={topicNo}
                  roomID={roomID}
                />
              )}

              </div>
            </div>
          </div>
          
      </div>

      <div className={style.chatIconWrapper}>
        <div className={style.chatBox}>
          <BsRobot
            className={style.chatIcon} // You can style this icon as needed
            onClick={openChatPopup} // Open ChatPopup when clicked
          />
        </div>
      </div>
    </div>
  );
}

export default Insights;
