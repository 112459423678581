import React from 'react'
import style from './Message.module.css'
import { BsSearch } from "react-icons/bs";
import { BiFilter } from "react-icons/bi";

const Message = () => {
  return (
    <div className={style.Container}>
        <div className={style.Header}>
          <div className={style.Search}>
            <BsSearch/>
            <input placeholder='Search'/>
          </div>
          <div className={style.Filter}>
            <BiFilter/>
            <p>Filter</p>
          </div>
        </div>
        <div className={style.Message}>
          <div className={style.First}>
          <div className={style.Card}>
          <div className={style.name}><span className={style.NameText}>AD</span></div>
          <div className={style.Details}>
            <p>Apple Developer<br/>
           <span className={style.Sub}> New Apple Vision Pro developer</span>

            </p>
          </div>
          <div className={style.Time}>
           fri 12-17 
          </div>
          </div>
          <div className={style.Card}>
          <div className={style.name}><span className={style.NameText}>AD</span></div>
          <div className={style.Details}>
            <p>Apple Developer<br/>
            <span className={style.Sub}> New Apple Vision Pro developer</span>
            </p>
          </div>
          <div className={style.Time}>
           fri 12-17 
          </div>
          </div>

          </div>
          <div className={style.Second}>
            <div className={style.Heading}>
              <div className={style.NameIcon}>
                <span className={style.NameText}>AD</span>
              </div>
              <div className={style.NameDetails}>
                <p>Apple Developer</p>
                To : Arun 
              </div>
            </div>
            <div className={style.Content}>
              <p>Hai<br/>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Message