import React, { useState, useRef, useEffect } from "react";
import style from "./Forum.module.css";
import Badge from "@mui/material/Badge";
import ReactPlayer from "react-player";
import { BsFilterRight } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { TbMessageQuestion, TbBulb } from "react-icons/tb";
import { baseurl } from "../Url";
import { IoMdMicOff, IoMdMic, IoIosCloseCircleOutline } from "react-icons/io";

import {
  FaRegThumbsUp,
  FaThumbsUp,
  FaFileImage,
  FaRegFileVideo,
} from "react-icons/fa";
import {
  MdDeleteForever,
  MdDelete,
  MdEdit,
  MdOutlineInsertComment,
  MdMoreHoriz,
} from "react-icons/md";
function Forum() {
  const [showCommentBox, setShowCommentBox] = useState({});
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedAnswerIndex, setEditedAnswerIndex] = useState(null);
  const [files, setFiles] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [questionText, setQuestionText] = useState("");
  const [questions, setQuestions] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [editedCommentText, setEditedCommentText] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [showMoreText, setShowMoreText] = useState({});
  const [questionsCount, setQuestionsCount] = useState(0);
  const [showDeleteCommentModal, setShowDeleteCommentModal] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const userId = localStorage.getItem("USERID");
  const userName = localStorage.getItem("username");
  const audioRecorder = useRef(null);

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    setQuestionsCount(questions.length);
  }, [questions]);

  const toggleCommentBox = (forumId) => {
    setShowCommentBox((prevState) => ({
      ...prevState,
      [forumId]: !prevState[forumId],
    }));
  };

  const toggleDropdown = (index) => {
    setIsDropdownVisible(isDropdownVisible === index ? null : index);
  };

  const toggleShowMore = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].showMore = !updatedQuestions[index].showMore;
    setQuestions(updatedQuestions);
    setShowMoreText((prevState) => ({
      ...prevState,
      [index]: prevState[index] === "Show less" ? "Show more" : "Show less",
    }));
  };

  const toggleAddQuestionModal = () => {
    setShowAddQuestionModal(!showAddQuestionModal);
  };

  const toggleEditModal = (index, forumId, messageId, commentText) => {
    localStorage.setItem("forumId", forumId);
    localStorage.setItem("messageId", messageId);
    setShowEditModal(!showEditModal);
    if (index !== undefined) {
      setEditedAnswerIndex(index);
    }
    setEditedCommentText(commentText);
  };

  const handleEditCommentChange = (e) => {
    setEditedCommentText(e.target.value);
  };

  const canEditDeleteComment = (commentUserId, commentUserName) => {
    return commentUserId === userId && commentUserName === userName;
  };

  const reorderComments = (comments) => {
    const currentUser = {
      userId: localStorage.getItem("USERID"),
      userName: localStorage.getItem("username"),
    };
    const userComments = [];
    const otherComments = [];
    comments.forEach((comment) => {
      if (
        comment.user_id === currentUser.userId &&
        comment.user_name === currentUser.userName
      ) {
        userComments.unshift(comment);
      } else {
        otherComments.push(comment);
      }
    });
    return [...userComments, ...otherComments];
  };

  const handleFileUpload = (e) => {
    const filesArray = Array.from(e.target.files);
    const updatedFiles = filesArray.map((file) => ({
      url: URL.createObjectURL(file),
      type: file.type.startsWith("image")
        ? "image"
        : file.type.startsWith("video")
        ? "video"
        : "audio",
    }));
    setFiles([...files, ...updatedFiles]);
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        recorder.ondataavailable = (e) => {
          const audioUrl = URL.createObjectURL(e.data);
          setFiles([...files, { url: audioUrl, type: "audio" }]);
        };
        audioRecorder.current = recorder;
        setIsRecording(true);
        recorder.start();
      })
      .catch((err) => {
        console.error("Error accessing microphone:", err);
      });
  };

  const stopRecording = () => {
    if (audioRecorder.current && isRecording) {
      audioRecorder.current.stop();
      setIsRecording(false);
    }
  };

  const handleSubmitQuestion = async () => {
    const user_name = localStorage.getItem("username");
    const user_id = localStorage.getItem("USERID");
    const questionData = {
      user_name: user_name,
      user_id: user_id,
      questions: [
        {
          text: questionText,
          images: files
            .filter((file) => file.type === "image")
            .map((image) => image.url),
          videos: files
            .filter((file) => file.type === "video")
            .map((video) => video.url),
          audios: files
            .filter((file) => file.type === "audio")
            .map((audio) => audio.url),
        },
      ],
    };

    await fetch(`${baseurl}/add/forum/question`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(questionData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from server:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    toggleAddQuestionModal();
    fetchQuestions();
  };

  const fetchQuestions = async () => {
    try {
      const response = await fetch(`${baseurl}/forum/questions/list`);
      if (!response.ok) {
        throw new Error("Failed to fetch questions");
      }
      const data = await response.json();
      const updatedQuestions = data.data.map((question) => {
        const existingQuestion = questions.find(
          (q) => q.forum_id === question.forum_id
        );
        if (existingQuestion) {
          return { ...existingQuestion, ...question };
        } else {
          return question;
        }
      });
      updatedQuestions.sort(
        (a, b) =>
          new Date(b.created_date.$date) - new Date(a.created_date.$date)
      );
      setQuestions(updatedQuestions);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleAddComment = async (forumId) => {
    const user_name = localStorage.getItem("username");
    const user_id = localStorage.getItem("USERID");
    const commentData = {
      forum_id: forumId,
      comments: [
        {
          user_id: user_id,
          user_name: user_name,
          comment_text: commentText,
        },
      ],
    };
    await fetch(`${baseurl}/forum/add/comment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(commentData),
    })
      .then((response) => response.json())
      .then((data) => {
        setCommentText("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    fetchQuestions();
  };

  const handleCommentEditSubmit = async () => {
    const forumId = localStorage.getItem("forumId");
    const userId = localStorage.getItem("USERID");
    const commentId = localStorage.getItem("messageId");
    const editData = {
      forum_id: forumId,
      user_id: userId,
      comment_id: commentId,
      comment_text: editedCommentText,
    };
    try {
      const response = await fetch(`${baseurl}/forum/edit/comment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editData),
      });
      if (!response.ok) {
        throw new Error("Failed to edit comment");
      }
      setEditedCommentText("");
      toggleEditModal();
      fetchQuestions();
    } catch (error) {
      console.error("Error editing comment:", error);
    }
  };

  const handleCommentDelete = async () => {
    try {
      const forumId = localStorage.getItem("forum_id");
      const userId = localStorage.getItem("USERID");
      const comment_id = localStorage.getItem("comment_id");
      if (!forumId || !userName || !userId) {
        console.error("Missing data in local storage");
        return;
      }
      const requestBody = {
        forum_id: forumId,
        user_id: userId,
        comment_id: comment_id,
      };
      const response = await fetch(`${baseurl}/forum/delete/comment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error("Failed to vote for the question");
      }
      const data = await response.json();
      console.log("Vote response:", data);
      fetchQuestions();
      setShowDeleteCommentModal(false);
    } catch (error) {
      console.error("Error voting for the question:", error);
    }
  };

  const handleDeleteForumQuestion = async () => {
    try {
      const response = await fetch(`${baseurl}/forum/question/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          forum_id: questionToDelete.forum_id,
          user_id: userId,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to delete question");
      }
      const updatedQuestions = questions.filter(
        (q) => q.forum_id !== questionToDelete.forum_id
      );
      setQuestions(updatedQuestions);
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  const handleQuestionVote = async () => {
    try {
      const forumId = localStorage.getItem("forum_id");
      const userName = localStorage.getItem("username");
      const userId = localStorage.getItem("USERID");
      if (!forumId || !userName || !userId) {
        console.error("Missing data in local storage");
        return;
      }
      const requestBody = {
        forum_id: forumId,
        user_name: userName,
        user_id: userId,
      };
      const response = await fetch(`${baseurl}/forum/question/vote`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error("Failed to vote for the question");
      }
      const data = await response.json();
      console.log("Vote response:", data);
      fetchQuestions();
    } catch (error) {
      console.error("Error voting for the question:", error);
    }
  };

  const handleCommentLike = async () => {
    try {
      const forumId = localStorage.getItem("forum_id");
      const userName = localStorage.getItem("username");
      const userId = localStorage.getItem("USERID");
      const comment_id = localStorage.getItem("comment_id");
      if (!forumId || !userName || !userId) {
        console.error("Missing data in local storage");
        return;
      }
      const requestBody = {
        forum_id: forumId,
        user_name: userName,
        user_id: userId,
        comment_id: comment_id,
      };
      const response = await fetch(`${baseurl}/forum/comment/like`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to vote for the question");
      }
      const data = await response.json();
      console.log("Vote response:", data);
      fetchQuestions();
    } catch (error) {
      console.error("Error voting for the question:", error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await fetch(`${baseurl}/forum/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ term: searchTerm }),
      });
      if (!response.ok) {
        throw new Error("Failed to search");
      }
      const data = await response.json();
      console.log("Search results:", data);
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  return (
    <div className={style.Container}>
      <div className={style.ForumContent}>
        <div className={style.HeaderMain}>
          <div className={style.Header}>
            <div className={style.content}>
              <div className={style.heading}>
                <h1>Forum</h1>
                <TbBulb className={style.forumIcon} />
              </div>
              <div className={style.add}>
                <button
                  className={style.AddButton}
                  onClick={toggleAddQuestionModal}
                >
                  Ask Questions
                  <TbMessageQuestion className={style.AddIcon} />
                </button>
              </div>
            </div>
          </div>
          {/* <hr /> */}
        </div>

        {showAddQuestionModal && (
          <div className={style.modal}>
            <div className={style.modalContent}>
              <div className={style.AddmodalClose}>
                <button
                  className={style.closeButton}
                  onClick={toggleAddQuestionModal}
                >
                  <IoIosCloseCircleOutline
                    title="close"
                    className={style.closeIcon}
                  />
                </button>
              </div>
              <h2 className={style.QuestionHeading}>Add Your Question</h2>
              <div className={style.AddQuestionBox}>
                <div className={style.addtxt}>
                  <textarea
                    value={questionText}
                    onChange={(e) => setQuestionText(e.target.value)}
                    placeholder="Ask your Questions here..."
                  ></textarea>

                  <div className={style.PreviewBody}>
                    {files.map((file, index) => (
                      <div key={index} className={style.filePreview}>
                        <div className={style.previewItem}>
                          <div className={style.ImagePreview}>
                            {file.type === "image" && (
                              <>
                                <img src={file.url} alt="Preview" />
                                <MdDeleteForever
                                  className={style.DeletePreview}
                                  onClick={() => handleRemoveFile(index)}
                                />
                              </>
                            )}
                          </div>
                          <div className={style.VideoPreview}>
                            {file.type === "video" && (
                              <>
                                <ReactPlayer
                                  controls
                                  className={style.player}
                                  url={file.url}
                                />
                                <MdDeleteForever
                                  className={style.DeletePreview}
                                  onClick={() => handleRemoveFile(index)}
                                />
                              </>
                            )}
                          </div>
                          <div className={style.AudioPreview}>
                            {file.type === "audio" && (
                              <>
                                <audio controls>
                                  <source src={file.url} type="audio/webm" />
                                </audio>
                                <MdDeleteForever
                                  className={style.DeletePreview}
                                  onClick={() => handleRemoveFile(index)}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={style.attachments}>
                    {/* <div className={style.InputIcons}>
                    <div className={style.AudioControls}>
                      {isRecording ? (
                        <IoMdMic
                          title="Stop Recording"
                          onClick={toggleRecording}
                          className={style.fileicon}
                        />
                      ) : (
                        <IoMdMicOff
                          title="Start Recording"
                          onClick={toggleRecording}
                          className={style.fileicon}
                        />
                      )}
                    </div>
                    <div className={style.imagefile}>
                      <label htmlFor="imageUpload">
                        <FaFileImage
                          title="Attach image"
                          className={style.fileicon}
                        />
                      </label>
                      <input
                        type="file"
                        id="imageUpload"
                        accept="image/*"
                        onChange={handleFileUpload}
                        style={{ display: "none" }}
                      />
                    </div>
                    <div className={style.videoFile}>
                      <label htmlFor="VideoUpload">
                        <FaRegFileVideo
                          title="Attach Video"
                          className={style.fileicon}
                        />
                      </label>
                      <input
                        type="file"
                        id="VideoUpload"
                        accept="video/*"
                        onChange={handleFileUpload}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div> */}
                  </div>
                </div>
                <button onClick={handleSubmitQuestion}>Submit</button>
              </div>
            </div>
          </div>
        )}
        {showDeleteModal && (
          <div className={style.modal}>
            <div className={style.QuesDelmodal}>
              <div className={style.DeletemodalClose}>
                <button
                  className={style.closeButton}
                  onClick={() => setShowDeleteModal(false)}
                >
                  <IoIosCloseCircleOutline
                    title="close"
                    className={style.closeIcon}
                  />
                </button>
              </div>
              <h2 className={style.QuestionHeading}>Confirm Deletion</h2>
              <div className={style.DeleteQuestionModalBody}>
                <p>Are you sure you want to delete this question?</p>
                <div className={style.DeleteQuestionModalButtons}>
                  <button
                    className={style.DelAnsBtnConfirm}
                    onClick={handleDeleteForumQuestion}
                  >
                    Confirm
                  </button>
                  <button
                    className={style.DelAnsBtnCancel}
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showDeleteCommentModal && (
          <div className={style.modal}>
            <div className={style.QuesDelmodal}>
              <div className={style.DeletemodalClose}>
                <button
                  className={style.closeButton}
                  onClick={() => setShowDeleteCommentModal(false)}
                >
                  <IoIosCloseCircleOutline
                    title="close"
                    className={style.closeIcon}
                  />
                </button>
              </div>
              <h2 className={style.QuestionHeading}>Confirm Deletion</h2>
              <div className={style.DeleteQuestionModalBody}>
                <p>Are you sure you want to Remove this Comment ?</p>
                <div className={style.DeleteQuestionModalButtons}>
                  <button
                    className={style.DelAnsBtnConfirm}
                    onClick={handleCommentDelete}
                  >
                    Confirm
                  </button>
                  <button
                    className={style.DelAnsBtnCancel}
                    onClick={() => setShowDeleteCommentModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showEditModal && (
          <div className={style.modal}>
            <div className={style.modalContent}>
              <div className={style.AddmodalClose}>
                <button className={style.closeButton} onClick={toggleEditModal}>
                  <IoIosCloseCircleOutline
                    title="close"
                    className={style.closeIcon}
                  />
                </button>
              </div>
              <h2 className={style.QuestionHeading}>Edit Your Answer</h2>
              <div className={style.AddQuestionBox}>
                <div className={style.addtxt}>
                  <textarea
                    value={editedCommentText}
                    onChange={handleEditCommentChange}
                  ></textarea>
                  <div className={style.attachments}></div>
                </div>
                <button onClick={handleCommentEditSubmit}>Save</button>
              </div>
            </div>
          </div>
        )}
        <div className={style.InfoActions}>
          <div className={style.InfoActionsHead}>
            <div className={style.Query}>
              <p>Total Queries : {questionsCount}</p>
            </div>
            <div className={style.Search}>
              <BiSearch className={style.SearchIcon} />
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              ></input>
            </div>
            <button onClick={handleSearch}>Search</button>
            <button className={style.filter}>
              <BsFilterRight className={style.filterIcon} />
              Filter
            </button>
          </div>
        </div>
        <div className={style.QueryBody}>
          {/* <hr /> */}
          {questions.map((question, index) => (
            <div key={index} className={style.Faq}>
              <div className={style.QuerryinfoBox}>
                <div className={style.Querryinfo}>
                  <p className={style.user}>{question.user_name}</p>
                  <p className={style.date}>
                    {new Date(question.created_date.$date).toLocaleString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      }
                    )}
                  </p>
                </div>

                <div className={style.dropdown}>
                  <MdMoreHoriz
                    title="More"
                    onClick={() => toggleDropdown(index)}
                    className={style.More}
                    style={{
                      display: userId === question.user_id ? "block" : "none",
                    }}
                  />
                  {isDropdownVisible === index && (
                    <div className={style.dropdownContent}>
                      <div className={style.listEdit}>
                        <MdEdit
                          className={style.EditQuestion}
                          title="Edit Your Question"
                          style={{
                            display:
                              userId === question.user_id ? "block" : "none",
                          }}
                        />
                      </div>
                      <div className={style.listDelete}>
                        <MdDeleteForever
                          className={style.DeleteQuestion}
                          title="Delete Your Question"
                          onClick={() => {
                            setShowDeleteModal(true);
                            setQuestionToDelete(question);
                          }}
                          style={{
                            display:
                              userId === question.user_id ? "block" : "none",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={style.QuerryList}>
                <div className={style.QuestionBody}>
                  <div className={style.Questionlisting}>
                    <p className={style.question}>
                      {question.questions[0].text}
                    </p>
                  </div>
                  <div className={style.Imagelisting}>
                    {question.questions[0].images.map((imageUrl, index) => (
                      <img
                        key={index}
                        src={imageUrl}
                        alt={`Image ${index + 1}`}
                      />
                    ))}
                  </div>
                  <div className={style.Videolisting}>
                    {question.questions[0].videos.map((videoUrl, index) => (
                      <ReactPlayer
                        key={index}
                        controls
                        className={style.player}
                        url={videoUrl}
                      />
                    ))}
                  </div>
                  {question.questions[0].audios.length > 0 && (
                    <div className={style.Audiolisting}>
                      <audio controls>
                        {question.questions[0].audios.map((audioUrl, index) => (
                          <source
                            key={index}
                            src={audioUrl}
                            type="audio/webm"
                          />
                        ))}
                      </audio>
                    </div>
                  )}
                  <div className={style.QuestionAction}>
                    <Badge
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      color="secondary"
                      badgeContent={question.up_vote}
                      max={999}
                    >
                      {question.up_vote_by.some(
                        (vote) =>
                          vote.user_id === userId && vote.user_name === userName
                      ) ? (
                        <FaThumbsUp
                          className={style.QaLike}
                          title="like"
                          onClick={() => {
                            localStorage.setItem("forum_id", question.forum_id);
                            handleQuestionVote();
                          }}
                        />
                      ) : (
                        <FaRegThumbsUp
                          className={style.QaLike}
                          title="like"
                          onClick={() => {
                            localStorage.setItem("forum_id", question.forum_id);
                            handleQuestionVote();
                          }}
                        />
                      )}
                    </Badge>
                    <Badge
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      color="primary"
                      badgeContent={question.comments.length}
                      max={999}
                    >
                      <MdOutlineInsertComment
                        className={style.CommentQuestion}
                        title="Add Comments"
                        onClick={() => {
                          toggleCommentBox(question.forum_id);
                          toggleShowMore(index);
                        }}
                      />
                    </Badge>
                  </div>
                  {showCommentBox[question.forum_id] && (
                    <div className={style.CommentBox}>
                      <textarea
                        value={commentText}
                        onChange={(e) => setCommentText(e.target.value)}
                        placeholder="Write your comment here..."
                      ></textarea>
                      <button
                        onClick={() => handleAddComment(question.forum_id)}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
                <div className={style.AnswerBody}>
                  {question.showMore
                    ? reorderComments(question.comments).map(
                        (comment, index) => (
                          <div
                            key={comment.comment_id}
                            className={style.AnswerList}
                            id={comment.comment_id}
                          >
                            <div
                              id={comment.user_id}
                              className={style.Answersinfo}
                            >
                              <p className={style.user}>{comment.user_name}</p>
                              <p className={style.date}>
                                {new Date(
                                  comment.date_time.$date
                                ).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                })}
                              </p>
                            </div>
                            <p className={style.answers}>
                              {comment.comment_text}
                            </p>
                            <div classNam={style.answerActionbox}>
                              <Badge
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                color="secondary"
                                badgeContent={comment.like}
                                max={999}
                              >
                                {comment.like_by.some(
                                  (like) =>
                                    like.user_id === userId &&
                                    like.user_name === userName
                                ) ? (
                                  <FaThumbsUp
                                    className={style.AnsLike}
                                    title="like"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "forum_id",
                                        question.forum_id
                                      );
                                      localStorage.setItem(
                                        "comment_id",
                                        comment.comment_id
                                      );
                                      handleCommentLike();
                                    }}
                                  />
                                ) : (
                                  <FaRegThumbsUp
                                    className={style.AnsLike}
                                    title="like"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "forum_id",
                                        question.forum_id
                                      );
                                      localStorage.setItem(
                                        "comment_id",
                                        comment.comment_id
                                      );
                                      handleCommentLike();
                                    }}
                                  />
                                )}
                              </Badge>
                              {canEditDeleteComment(
                                comment.user_id,
                                comment.user_name
                              ) && (
                                <div className={style.answersActions}>
                                  <div className={style.AnswerEditIcon}>
                                    <MdEdit
                                      className={style.EditAns}
                                      onClick={() =>
                                        toggleEditModal(
                                          index,
                                          question.forum_id,
                                          comment.comment_id,
                                          comment.comment_text
                                        )
                                      }
                                    />
                                  </div>
                                  <div className={style.AnswerDeleteIcon}>
                                    <MdDelete
                                      className={style.DeleteAns}
                                      title="Delete"
                                      onClick={() => {
                                        setShowDeleteCommentModal(true);
                                        localStorage.setItem(
                                          "forum_id",
                                          question.forum_id
                                        );
                                        localStorage.setItem(
                                          "comment_id",
                                          comment.comment_id
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      )
                    : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default Forum;
