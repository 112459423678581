import React,{ useState, useEffect} from 'react'
import style from './ConnectionProfile.module.css'
import { useNavigate,useLocation } from "react-router-dom";
import Learningimg from "../../assests/learnings.png"
import Events from "../../assests/events.png"
import Oppertunity from "../../assests/opportunity.png"
import Mentors from "../../assests/mentor-experts.png"
import ProfileImage from '../../assests/Profile-PNG-File.png'
import { MdGroup } from "react-icons/md";
import { MdEventAvailable } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import {baseurl} from '../Url'
import Whiteboard from '../WhiteBoard/WhiteBoard';
import { BiClipboard } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

import Loader from '../Loder/Loader';

const ConnectionProfile = () => {
    const OrgID=localStorage.getItem("ORGID") 
    const key = JSON.parse(localStorage.getItem("access-token"));
    const ClubID=localStorage.getItem("ClubID")

     const clubIdsArray = ClubID.split(',');
    
  const location=useLocation()
  const UserId=location.state?.userID
  const Role=location.state?.Role
  const[details,setDetails]=useState({})
  const[whiteBoradShow,setWhiteBoardShow]=useState(false)
  const[whiteBoradIconShow,setWhiteBoardIconShow]=useState(true)

  const [closing, setClosing] = useState(false);

useEffect(()=>{
if(closing){
  setTimeout(() => {
    setClosing(false)
  }, 1000);
}
},[closing])


  async function userDetails() {
    await fetch(`${baseurl}/main/dashboard/all`, {
      method: "POST",
      body: JSON.stringify({
        user_id:UserId,
        org_id:OrgID,
        club_id:clubIdsArray
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setDetails(data.data[0])
        
      });
  }
  useEffect(()=>{
    userDetails()
  },[])
  return (
    <div className={style.Container}>
      {whiteBoradIconShow  ? (
        <div className={style.chatboticon}>
          <div onClick={()=>{
        setWhiteBoardShow(true)
        setWhiteBoardIconShow(false)
      }}  className={style.icontext}>
       <BiClipboard className={style.WhiteboardFloatingIcon} /><p> Whiteboard</p>
      </div>
         
        </div>
      ) : (
        ""
      )}

{whiteBoradShow?
      <div  className={`${style.WhiteBoradContainer} ${closing ? style.closing : ''}`}>
        <div className={style.WhiteBoradHead}>
          <div className={style.HeadText}>
            White Board
          </div>
          <div
            className={style.WhiteBoradIcon}
            onClick={() => {
              setTimeout(() => {
                setWhiteBoardIconShow(true)
              setWhiteBoardShow(false)
              }, 500);
              setClosing(true);
            }}
          >
            <AiOutlineClose />
          </div>
        </div>
        <div className={style.WhiteBoradContent} >
        <Whiteboard/>
        
        </div>

      </div>
      :''}
        <div className={style.Content}>
        <>
        <div className={style.profile}>
        {details.profile?
              <>
              <div className={style.color}>
          <div className={style.profileimage}>
                <div className={style.ProfileImageDiv}>
                {details.profile.image ? (
      <img src={details.profile.image} />
    ) : (
      <img src={ProfileImage} />
    )}
                  </div>
              </div>
       </div>
            <div className={style.ProfileDetails}>
              <div className={style.Edit}>
              <h2>{details.profile.Name}</h2>
              </div>
              
              <p>{details.profile.About}</p>
              <div className={style.MainText}>
                <div className={style.FirstText}>Full Name</div>
                <div className={style.SecondText}>: {details.profile.Fullname} </div>
              </div>
              <div className={style.MainText}>
                <div className={style.FirstText}>Phone</div>
                <div className={style.SecondText}>: {details.profile.Contact}</div>
              </div>
              <div className={style.MainText}>
                <div className={style.FirstText}>Experience</div>
                <div className={style.SecondText}>: {details.profile.Expirence}</div>
              </div>
              <div className={style.MainText}>
                <div className={style.FirstText}>About Experience</div>
                <div className={style.SecondText}>: {details.profile.About_exp}</div>
              </div>
              <div className={style.MainText}>
                <div className={style.FirstText}>Email</div>
                <div className={style.SecondText}>: {details.profile.Email}</div>
              </div>
            </div>
              </>
              :''
            }
       
        </div>
            {Role==="Mentor"?
            <div className={style.Analytics}>
          <div className={style.AnalyticsHead}>
            <p>Analytics</p>
          </div>
          <div className={style.analyticContent}>
           <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                 
                  <MdGroup style={{fontSize:"1.75rem"}}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>{details.Total_leaners_mapped}</span> Assigned members</p>
            </div>
            </div>
            <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                 
                  <MdEventAvailable style={{fontSize:"1.75rem"}}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>{details.sessions}</span> Session Conducted</p>
            </div>
            </div>
            <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                  <img src={Oppertunity}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>25</span>Member Goals Achieved </p>
            </div>
            </div>
            <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                  
                  <GrMoney style={{fontSize:"1.75rem"}}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>25</span> Total Earnings /Hr</p>
            </div>
            </div>

          </div>
        </div>
        :
        <div className={style.Analytics}>
          <div className={style.AnalyticsHead}>
            <p>Analytics</p>
          </div>
          <div className={style.analyticContent}>
           <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                  <img src={Learningimg}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>{details.Total_Learnings_Attended}</span> learning Attended</p>
            </div>
            </div>
            <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                  <img src={Mentors}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>{details.Total_Mentor_Assigned}</span> Mentors & Experts Assigned</p>
            </div>
            </div>
            <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                  <img src={Oppertunity}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>{details.Opportunities_Used}</span> Opportunity used  </p>
            </div>
            </div>
            <div className={style.AnalyticsCard}>
            <div className={style.CardIcons}>
                  <img src={Events}/>
            </div>
            <div className={style.AnalyticsCardcontent}>
            <p><span className={style.boldnumber}>{details.sessions}</span> Session Conducted</p>
            </div>
            </div>

          </div>

        </div>
         }
        </> 
      </div>
    </div>
  )
}

export default ConnectionProfile