import React from 'react'
import learnersLOgo from "../../assests/logo-white.png";
import style from './RegistrationSuccess.module.css'
const RegistrationSuccess = () => {
  return (
    <div className={style.Container}>
        <div className={style.Content}>
        <div className={style.Logo}>
                <img src={learnersLOgo}/>
            </div>
            <div className={style.SearchText}><p>Learn Anything</p></div>
            <div className={style.ValidationContent}>
              
                <div className={style.ValidationText}><p>Registration Successfull<br/>Please open your mail and verify 
          </p></div>
            </div>
        </div>
    </div>
  )
}

export default RegistrationSuccess