import React, { useEffect, useState } from "react";
import style from "./MobileLearning.module.css";
import { baseurl } from "../Url";
import { FcNext } from "react-icons/fc";
import { FcPrevious } from "react-icons/fc";

const MobileLearning = () => {
  const path = window.location.pathname;
  const splitPath = path.split("/");
  const learningId = splitPath[3];
  const currentTopicId = splitPath[4];
  const userId = splitPath[5];
  const adminStatus = splitPath[6];

  console.log(learningId);
  console.log(currentTopicId);
  console.log(adminStatus);
  console.log(userId);

  const [topicName, setTopicName] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [topicId, setTopicId] = useState("");
  const [mainTopicId, setMainTopicId] = useState("");
  const [noOfPages, setNoOfPages] = useState(0);
  const [AiId, setAiId] = useState("");
  const [type, setType] = useState("");
  const [imageUrl, setImageUrl] = useState({});
  const [videoUrl, setVideoUrl] = useState({});
  const [pdfUrl, setPdfUrl] = useState("");
  const [topicDetail, setTopicDetail] = useState({});
  const [current, setCurrent] = useState(1);

  const decrement = () => {
    if (current !== 1) {
      const updatedCurrent = current - 1;
      setCurrent(updatedCurrent);
      Pagination(updatedCurrent);
    }
  };
  const increment = () => {
    if (current !== noOfPages) {
      const updatedCurrent = current + 1;
      setCurrent(updatedCurrent);
      Pagination(updatedCurrent);
    }
  };

  async function userDetail() {
    await fetch(`${baseurl}/userlearning/details/individual`, {
      method: "POST",
      body: JSON.stringify({
        userlearning_id: learningId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setOrganizationId(data.data[0].Organization_ID);
        setMainTopicId(data.data[0].Topics[0].topic_id);
        const matchingTopic = data.data[0].topics.find(
          (topic) => topic.id === currentTopicId
        );
        console.log(data.data[0].topics);
        if (matchingTopic) {
          setTopicId(matchingTopic.id);
          setTopicName(matchingTopic.value);
          lessonDetail(
            data.data[0].Organization_ID,
            data.data[0].Topics[0].topic_id,
            matchingTopic.id,
            matchingTopic.value
          );
        }
      });
  }

  async function lessonDetail(org_id, main_id, topic_id, topic_name) {
    console.log(org_id, main_id, topic_id, topic_name);
    await fetch(`${baseurl}/ai/lesson/generation/for/user`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: org_id,
        topic_id: topic_id,
        topic_name: topic_name,
        learning_id: learningId,
        main_topic_id: main_id,
        user_id: userId,
        admin_mapped: adminStatus,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        setNoOfPages(data.data[0]?.no_of_pages);
        setAiId(data.data[0]?.ai_id);
      });
  }

  async function Pagination(page) {
    await fetch(`${baseurl}/lesson/pagination`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: organizationId,
        ai_id: AiId,
        user_id: userId,
        page_num: page,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setType(data.data[0]?.type);
        if (data.data[0]?.type === "image") {
          setImageUrl(data.data[0]);
        } else if (data.data[0]?.type === "video_url") {
          setVideoUrl(data.data[0]);
        } else if (data.data[0]?.type === "pdf") {
          setPdfUrl(data.data[0]);
        } else if (data.data[0]?.type === "lesson") {
          // Check if text is a URL
          const urlPattern = /^https?:\/\/.*\.(?:png|jpg|jpeg|gif)$/i;
          if (urlPattern.test(data.data[0]?.text)) {
            // It's an image URL
            setImageUrl({ text: data.data[0]?.text });
            setType("image");
          } else {
            const Text = data.data[0]?.text.split("\n");
            setTopicDetail(data.data[0]);
          }
        } else {
          const Text = data.data[0]?.text.split("\n");
          setTopicDetail(data.data[0]);
        }
      });
  }
  useEffect(() => {
    if (AiId !== "") {
      Pagination(1);
    }
  }, [AiId]);

  useEffect(() => {
    if (learningId) {
      userDetail();
    }
  }, [learningId]);

  return (
    <div className={style.container}>
      <div className={style.lessonPage}>
        {/* <div className={style.header}>
          <p>{topicName}</p>
        </div> */}
        <div className={style.lessonDetail}>
          {type === "image" ? (
            <>
              {imageUrl ? (
                <div div className={style.TopicDetailText}>
                  <span>{imageUrl.title ? imageUrl.title : ""}</span>
                  <div className={style.imageView}>
                    <img src={imageUrl.text} alt="" />
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : type === "video_url" ? (
            <>
              {videoUrl ? (
                <div className={style.TopicDetailText}>
                  <span>{videoUrl.title ? videoUrl.title : ""}</span>
                  <div
          dangerouslySetInnerHTML={{
            __html: `
              <video key="${videoUrl.text}" playsinline
              preload="metadata" controls width="100%" height="auto">
                <source src="${videoUrl.text}" type="video/mp4" />
               
              </video>
            `,
          }}
        />
                </div>
              ) : (
                ""
              )}
            </>
          ) : type === "pdf" ? (
            <>
              {pdfUrl ? (
                <div div className={style.TopicDetailText}>
                  {/* <span>{pdfUrl.title ? pdfUrl.title : ""}</span> */}

                  <iframe
                    title="PDF Viewer"
                    src={pdfUrl.text}
                    width="100%"
                    height="560px"
                  />
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              {topicDetail ? (
                <div className={style.TopicDetailText}>
                  <span>{topicDetail.title ? topicDetail.title : ""}</span>
                  {topicDetail.text && (
                    <>
                      {topicDetail.text.split("\n").map((text, index) => (
                        <p
                          key={index}
                          className={text ? style.TextContent : style.Blank}
                        >
                          {text ? (
                            <span dangerouslySetInnerHTML={{ __html: text }} />
                          ) : (
                            "Blank"
                          )}
                        </p>
                      ))}
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
        <div className={style.Pagination}>
          <div className={style.pageLeft}>
            <FcPrevious
              onClick={() => {
                decrement();
              }}
            />
          </div>
          <div className={style.pageCentre}>
            <button>{current}</button>
          </div>
          <div className={style.pageRight}>
            <FcNext
              onClick={() => {
                increment();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileLearning;
