import React from "react";
import style from "./Start.module.css";
import lessons from "../../assests/online lesson book.png";
import milestone from "../../assests/Group Badge 10.png";
import assessment from "../../assests/Clipboard,Survey.png";
import learning from "../../assests/Time Stamp.png";
import CommentImg from "../../assests/start comment -removebg.png";
import { useNavigate } from "react-router-dom";

function Start() {
  const navigate=useNavigate();
  const UserName=localStorage.getItem("username")
  return (
    <div className={style.Conatiner}>
      <div className={style.MainBody}>
        <div className={style.bodyBox}>
          <div className={style.containerbox}>
            <div className={style.header}>
              <p>
                Hello <span> {UserName}! </span>
              </p>
              <h3> Let’s explore more about Duno Club </h3>
            </div>

            <div className={style.cards}>
              <div className={style.cardHeader}>
                <p>Unlock Your Full potential</p>
              </div>

              <div className={style.cardBody}>
                <div className={style.colOne}>
                  <div className={style.lessons}>
                    <div className={style.lessonsImg}>
                      <img src={lessons} alt="lessons img " />
                    </div>
                    <p> Comprehensive Lessons </p>
                  </div>

                  <div className={style.assessment}>
                    <div className={style.assessmentImg}>
                      <img src={assessment} alt="assessment Img " />
                    </div>
                    <p> Assessment Questionnaires </p>
                  </div>
                </div>

                <div className={style.colTwo}>
                  <div className={style.milestone}>
                    <div className={style.milestoneImg}>
                      <img src={milestone} alt=" milestoneI mg " />
                    </div>
                    <p> Motivational Milestones </p>
                  </div>

                  <div className={style.learning}>
                    <div className={style.learningImg}>
                      <img src={learning} alt=" learning Img " />
                    </div>
                    <p> Flexible Learning </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={style.commentBox}>
            <div className={style.boxDialogue}>
              <div className={style.content}>
                <p>
                  <span>With Duno club</span> get access to courses, trainers, and mentors from industries{" "}
                  Experience personalized and gamified learning with experts. </p>
              </div>
            </div>

            <div className={style.cmntImage}>
              <img src={CommentImg} alt=" Comment Img " />
            </div>
          </div>
        </div>
      </div>

      <div className={style.startButton}>
        <button onClick={()=>{
          navigate("/")
        }}>I am ready to start!</button>
      </div>
    </div>
  );
}

export default Start;
