import React from "react";
import style from "./Ready.module.css";
import ready from "../../assests/ready.png";
import { useNavigate } from "react-router-dom";

function Ready() {
  const navigate = useNavigate();
  const UserName = localStorage.getItem("username");

  return (
    <div className={style.Container}>
      <div className={style.ReadyCard}>
        <div className={style.cardBody}>
          <div className={style.Cardheading}>
            <h3>
              Welcome<span> {UserName}!</span>
            </h3>

            <p> Start your journey ! </p>
          </div>

          <div className={style.ReadyImg}>
            <img src={ready} alt="Success Img " />
          </div>

          <div className={style.Readymsg}>
            <p>
              With Dunoclub, get access to courses, trainers, and mentors from
              industries.
            </p>
            <p>Experience personalized and gamified learning with experts.</p>
          </div>

          <div className={style.start}>
            <button onClick={() => navigate("/start")}>Let’s start!</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ready;
