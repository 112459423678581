import React, { useRef, useEffect, useState } from "react";
import style from "./Learnings.module.css";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import AreaChart from "../../assests/areaChart.png";
import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import LeaderImage from "../../assests/wgferg2 5.png";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { baseurl } from "../Url";
import { useNavigate } from "react-router-dom";
import Loader from "../Loder/Loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Learnings = () => {
  const navigate = useNavigate();

  const UserID = localStorage.getItem("USERID");
  const ClubID = localStorage.getItem("ClubID");
  const OrgID = localStorage.getItem("ORGID");
  const key = JSON.parse(localStorage.getItem("access-token"));

  const [selectedModule, setSelectedModule] = useState(0);
  const [learningData, setLearningData] = useState({});
  const [loading, setLoading] = useState(true);

  const data = {
    datasets: [
      {
        data: [40, 60],
        backgroundColor: ["#00CBF4", "#D9D9D9"],
        borderWidth: 0,
        radius: "90%",
      },
      {
        data: [50, 50],
        backgroundColor: ["#FF8800", "#D9D9D9"],
        borderWidth: 0,
        radius: "80%",
      },
    ],
  };

  const options = {
    cutout: "85%",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom", // Adjust position as needed
      },
    },
  };

  const toggleExpand = (index) => {
    setLearningData((prevData) => {
      const updatedTopics = prevData.Topics.map((topic, i) => {
        if (i === index) {
          return { ...topic, isExpand: !topic.isExpand };
        }
        return topic;
      });
      return { ...prevData, Topics: updatedTopics };
    });
  };

  async function learningList() {
    await fetch(`${baseurl}/userlearning/details`, {
      method: "POST",
      body: JSON.stringify({ user_id: UserID }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          const modifiedData = data.data.map((item) => {
            const topicsWithNoModuleName = [];
            const topicsWithModuleName = [];

            item.Topics.forEach((topic) => {
              if (!topic.module_name) {
                topic.module_name = "General topics";
                topicsWithNoModuleName.push(topic);
              } else {
                topicsWithModuleName.push(topic);
              }
            });

            item.Topics = [
              ...topicsWithModuleName,
              ...topicsWithNoModuleName,
            ].map((topic) => {
              topic.isExpand = false;
              return topic;
            });

            return item;
          });
          setTimeout(() => {
            setLoading(false);
          }, 1500);
          setLearningData(modifiedData[0]);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1500);
          setLearningData({});
        }
      });
  }
  

  useEffect(() => {
    learningList();
  }, [UserID]);

  return (
    <div className={style.container}>
      
      {loading ? (
        <Loader />
      ) : (
        <>
          {Object.keys(learningData).length === 0 && !loading ? (
            <div className={style.info}>
              <p>No data found</p>
            </div>
          ) : (
            <div className={style.learningSection}>
              <div className={style.learningHeader}>
                <h3>{learningData?.category_details}</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                  <br />
                  Lorem Ipsum has a been the industry's standard dummy text ever
                  since the 1500s,
                  <br />
                  when an unknown printer took a galley of type and scrambled it{" "}
                  <br />
                  to make a type specimen book. 
                </p>
              </div>

              <button
                onClick={() => {
                  navigate("/category");
                }}
              >
                Course Gallary
              </button> 

              <div className={style.graphSection}>
                <div className={style.graphSectionLeft}>
                  <div className={style.graphSectionLeftTopContent}>
                    <div className={style.areaGraphSection}>
                      <div className={style.areaGraphSectionHeader}>
                        <p>Module 1</p>
                        <h4>Performance</h4>
                      </div>
                      <div className={style.areaGraph}>
                        <img src={AreaChart} alt="chart" />
                        {/* <Line data={data} options={options} height={400} /> */}
                      </div>
                    </div>
                    <div className={style.roundGraphSection}>
                      <div className={style.roundGraphHeader}>
                        <div className={style.roundGraphHeaderCard}>
                          <span style={{ background: "#00CBF4" }}></span>
                          <p>Progress</p>
                        </div>
                        <div className={style.roundGraphHeaderCard}>
                          <span style={{ background: "#FF8800" }}></span>
                          <p>Effort</p>
                        </div>
                      </div>
                      <div className={style.roundGraph}>
                        <Doughnut data={data} options={options} />
                      </div>
                      <div className={style.exploreButton}>
                        <button>Explore More</button>
                      </div>
                    </div>
                  </div>
                  <div className={style.graphSectionLeftBottomContent}>
                    <div className={style.bottomHeader}>
                      <p>Modules</p>
                    </div>
                    <div className={style.progressBarSection}>
                      <div className={style.progressBar}>
                        {[...Array(7)].map((_, index) => (
                          <div
                            key={index}
                            className={
                              selectedModule === index
                                ? style.selectedNumber
                                : style.number
                            }
                          >
                            {index + 1}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style.graphSectionRight}>
                  <div className={style.leaderBoardHeader}>
                    <p>Leaderboard</p>
                  </div>
                  <div className={style.leaderBoardBody}>
                    <div
                      style={{ background: "#FFBE00B2" }}
                      className={style.leaderBoardCard}
                    >
                      <div className={style.leaderCardLeft}>
                        <div className={style.leaderCardLeftTop}>
                          <div className={style.leaderName}>
                            <p>Eliza</p>
                          </div>
                          <div className={style.leaderPoint}>
                            <p>212 / 300</p>
                          </div>
                        </div>
                        <div className={style.leaderCardLeftBottom}>
                          <div className={style.leaderProgressBAr}>
                            <div
                              className={style.progressBar}
                              style={{
                                width: "95%",
                                backgroundColor: "#fff",
                                borderRadius: "2rem",
                              }}
                            >
                              <div
                                style={{
                                  width: `${70}%`,
                                  backgroundColor: "#DBA300",
                                  height: "12px",
                                  transition: "width 0.5s ease-in-out",
                                  borderRadius: "1rem",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className={style.leaderAssessment}>
                            <p>12Assessment</p>
                          </div>
                        </div>
                      </div>
                      <div className={style.leaderCardRight}>
                        <img src={LeaderImage} alt="Leader" />
                      </div>
                    </div>
                    <div className={style.leaderBoardCard}>
                      <div className={style.leaderCardLeft}>
                        <div className={style.leaderCardLeftTop}>
                          <div className={style.leaderName}>
                            <p>John</p>
                          </div>
                          <div className={style.leaderPoint}>
                            <p>125 / 300</p>
                          </div>
                        </div>
                        <div className={style.leaderCardLeftBottom}>
                          <div className={style.leaderProgressBAr}>
                            <div
                              className={style.progressBar}
                              style={{
                                width: "95%",
                                backgroundColor: "#fff",
                                borderRadius: "2rem",
                              }}
                            >
                              <div
                                style={{
                                  width: `${45}%`,
                                  backgroundColor: "#595BDB",
                                  height: "12px",
                                  transition: "width 0.5s ease-in-out",
                                  borderRadius: "1rem",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className={style.leaderAssessment}>
                            <p>10Assessment</p>
                          </div>
                        </div>
                      </div>
                      <div className={style.leaderCardRight}>
                        <img src={LeaderImage} alt="Leader" />
                      </div>
                    </div>
                    <div className={style.leaderBoardCard}>
                      <div className={style.leaderCardLeft}>
                        <div className={style.leaderCardLeftTop}>
                          <div className={style.leaderName}>
                            <p>Sheetal</p>
                          </div>
                          <div className={style.leaderPoint}>
                            <p>69 / 300</p>
                          </div>
                        </div>
                        <div className={style.leaderCardLeftBottom}>
                          <div className={style.leaderProgressBAr}>
                            <div
                              className={style.progressBar}
                              style={{
                                width: "95%",
                                backgroundColor: "#fff",
                                borderRadius: "2rem",
                              }}
                            >
                              <div
                                style={{
                                  width: `${20}%`,
                                  backgroundColor: "#595BDB",
                                  height: "12px",
                                  transition: "width 0.5s ease-in-out",
                                  borderRadius: "1rem",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className={style.leaderAssessment}>
                            <p>7Assessment</p>
                          </div>
                        </div>
                      </div>
                      <div className={style.leaderCardRight}>
                        <img src={LeaderImage} alt="Leader" />
                      </div>
                    </div>
                    <div className={style.leaderBoardCard}>
                      <div className={style.leaderCardLeft}>
                        <div className={style.leaderCardLeftTop}>
                          <div className={style.leaderName}>
                            <p>Eliza</p>
                          </div>
                          <div className={style.leaderPoint}>
                            <p>212 / 300</p>
                          </div>
                        </div>
                        <div className={style.leaderCardLeftBottom}>
                          <div className={style.leaderProgressBAr}>
                            <div
                              className={style.progressBar}
                              style={{
                                width: "95%",
                                backgroundColor: "#fff",
                                borderRadius: "2rem",
                              }}
                            >
                              <div
                                style={{
                                  width: `${10}%`,
                                  backgroundColor: "#595BDB",
                                  height: "12px",
                                  transition: "width 0.5s ease-in-out",
                                  borderRadius: "1rem",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className={style.leaderAssessment}>
                            <p>12Assessment</p>
                          </div>
                        </div>
                      </div>
                      <div className={style.leaderCardRight}>
                        <img src={LeaderImage} alt="Leader" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.moduleSection}>
                <div className={style.moduleSectionHeader}>
                  <p>Modules</p>
                </div>
                <div className={style.moduleSectionBody}>
                  {learningData?.Topics?.length > 0 &&
                    learningData.Topics.map((mod, i) => (
                      <div className={`${style.moduleCard}`} key={i}>
                        <div className={style.moduleCardTop}>
                          <div className={style.moduleCardName}>
                            <p>Module {i + 1}</p>
                          </div>
                          <div className={style.moduleCount}>
                            {mod.topics.map((topic, j) => (
                              <div
                                key={j}
                                style={{
                                  background:
                                    topic.status === "complete"
                                      ? "#00DFC7"
                                      : topic.status === "In Progress"
                                      ? "#FFB563"
                                      : "#D9D9D9",
                                }}
                                className={style.moduleCountColor}
                              ></div>
                            ))}
                          </div>
                        </div>
                        <div className={style.moduleCardBottom}>
                          <div className={style.moduleCardBottomLeft}>
                            <p>
                              {mod.module_name.replace(/Module \d+\s*:\s*/, "")}
                            </p>
                          </div>
                          <div className={style.moduleCardBottomRight}>
                            {mod.isExpand ? (
                              <BsChevronUp onClick={() => toggleExpand(i)} />
                            ) : (
                              <BsChevronDown onClick={() => toggleExpand(i)} />
                            )}
                          </div>
                        </div>
                        {mod.isExpand && (
                          <div className={style.topicContainer}>
                            {mod.topics.map((topic, j) => (
                              <div
                                onClick={() => {
                                  navigate("/new/learnings/detail", {
                                    state: {
                                      singleTopic: topic,
                                      learning: learningData.learning_id,
                                      mainId: mod.topic_id,
                                      mapped: learningData.admin_mapped_lessons,
                                      topic_no: j + 1,
                                    },
                                  });
                                }}
                                key={j}
                                style={{
                                  background:
                                    topic.status === "complete"
                                      ? "#00DFC7"
                                      : topic.status === "In Progress"
                                      ? "#FFB563"
                                      : "#D9D9D9",
                                }}
                                className={`${style.topicCard} ${
                                  mod.module_type !== "free"
                                    ? style.disabledCard
                                    : ""
                                }`}
                              >
                                <p> {topic.value}</p>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Learnings;
