import {
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,


  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,

    PROFILE_FAIL,
    PROFILE_REQUEST,
    PROFILE_SUCCESS,

} from "../constants/user";
import {baseurl} from '../Components/Url'

export const loginAction=(data)=> async (dispatch)=>{
    try{
        dispatch({
            type:LOGIN_REQUEST,
        });
        await fetch(`${baseurl}/account/login`,{
            method:"POST",
            body: JSON.stringify({
                Username: data.username,
                Password: data.password,
              }),
              headers: {
                "Content-Type":"application/json",
              }
        }).then((res)=>res.json()).then((data)=>{
            console.log(data);
            dispatch({
                type:LOGIN_SUCCESS,
                payload:data,
            })
            localStorage.setItem("access-token", JSON.stringify(data.data.access_token));
            // console.log(data.data.access_token);
            localStorage.setItem("ClubID", data.data.club_id);
            localStorage.setItem("ORGID", data.data.org_id);
            localStorage.setItem("USERID", data.data.user_id);
            localStorage.setItem("mentor", data.data.is_mentor);
            localStorage.setItem("username", data.data.username);
        });
    }
    catch (error) {
        dispatch({
            type:LOGIN_FAIL,
            payload:error
        })
    }
}
export const registerAction=(data)=>async (dispatch)=>{
    // console.log(data);
    try{
        dispatch({
            type:REGISTER_REQUEST,
        });
        await fetch(`${baseurl}/account/signup`,{
            method:"POST",
            body: JSON.stringify({
                keys:data
              }),
              headers: {
                "Content-Type":"application/json",
              }
        }).then((res)=>res.json()).then((data)=>{
            dispatch({
                type:REGISTER_SUCCESS,
                payload:data,
            })
            // localStorage.setItem("access-token", JSON.stringify(data.data.access_token));
            // localStorage.setItem("ClubID", data.data.club_id);
            // localStorage.setItem("ORGID", data.data.org_id);
            localStorage.setItem("USERID", data.data.user_id);
            // localStorage.setItem("mentor", data.data.is_mentor);
            // localStorage.setItem("username", data.data.username);
        });
    }
    catch(error){
        dispatch({
            type:REGISTER_FAIL,
            payload:error
        })
    }
}

export const getCountryAction=()=>async (dispatch)=>{
    // console.log(data);
    try{
        dispatch({
            type:REGISTER_REQUEST,
        });
        await fetch("",{
            method:"GET",
           
              headers: {
                "Content-Type":"application/json",
              }
        }).then((res)=>res.json()).then((data)=>{
            dispatch({
                type:REGISTER_SUCCESS,
                payload:data,
            })
        });
    }
    catch(error){
        dispatch({
            type:REGISTER_FAIL,
            payload:error
        })
    }
}

//profile details

export const UserProfile = () => async (dispatch) => {
    try {
        dispatch({
            type: PROFILE_REQUEST,
        });
        const userId = localStorage.getItem("USERID")
        await fetch(`${baseurl}/user/detail/get`, {
            method: "POST",
            body: JSON.stringify({
                user_id: userId
            }),
            headers: {
                "Content-Type": "application/json",
            }
        }).then((res) => res.json()).then((data) => {
            dispatch({
                type: PROFILE_SUCCESS,
                payload: data,
            })

            console.log(data);
        });
    }
    catch (error) {
        dispatch({
            type: PROFILE_FAIL,
            payload: error
        })
    }
}