import React,{useEffect,useState} from "react";
import style from "./Fail.module.css";
import FailImg from "../../assests/fail.png";
import { useNavigate,useLocation } from "react-router-dom";
import { baseurl } from "../Url";

function Fail() {
  const navigate = useNavigate();
  const location=useLocation()
  const searchParams = new URLSearchParams(location.search);
  const sessionid = searchParams.get('session_id');
  const [userName,setUserName]=useState("")
  

 
 
   async function verifyPayment() {
     await fetch(`${baseurl}/verify-payment/stripe`, {
       method: "POST",
       body: JSON.stringify({
        checkout_session_id:sessionid
       }),
       headers: {
         "Content-Type": "application/json",
       },
     })
       .then((res) => res.json())
       .then((data) => {
         console.log(data);
         setUserName(data.data[0]?.Username)
       });
   }

   useEffect(()=>{
    verifyPayment()
   },[sessionid])

  return (
    <div className={style.Container}>
      <div className={style.FailCard}>
        <div className={style.cardBody}>
          <div className={style.Cardheading}>
            <h3>
              Oh no <span>{userName}!</span>{" "}
            </h3>

            <p> Your payment has failed! </p>
          </div>

          <div className={style.FailImg}>
            <img src={FailImg} alt="Success Img " />
          </div>

          <div className={style.Failmsg}>
            <p> Please review your payment details and try again. </p>
            <p>
              If you continue to experience issues, our customer support team is
              here to assist you.
            </p>
          </div>

          <div className={style.try}>
            <button onClick={()=>navigate("/subscribe")}>Try again</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fail;
