import React, { useEffect, useState, useCallback } from "react";
import style from "./Billing.module.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineViewGrid } from "react-icons/hi";
import { baseurl } from "../Url";
import useRazorpay from "react-razorpay";
import learnersLOgo from "../../assests/1o1LearnersClub-Logo1-100-×-100px-2-300x300.png";
import { getCountryCode } from "../CountryDetails";
import Whiteboard from "../WhiteBoard/WhiteBoard";
import { BiClipboard } from "react-icons/bi";

const Billing = () => {
  const USERID = localStorage.getItem("USERID");
  const [selectedTab, setSelectedTab] = useState(0);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceView, setInvoiceView] = useState(false);
  const [receiptView, setReceiptView] = useState(false);
  const [invoiceIndividual, setInvoiceIndividual] = useState({});
  const [receiptList, setReceiptList] = useState([]);
  const [receiptIndividual, setReceiptIndividual] = useState([]);
  const CountryDetails = localStorage.getItem("countrydetails");
  const [currency, setCurrency] = useState(localStorage.getItem("currency"));
  const [whiteBoradShow, setWhiteBoardShow] = useState(false);
  const [whiteBoradIconShow, setWhiteBoardIconShow] = useState(true);

  useEffect(() => {
    if (CountryDetails === undefined || CountryDetails === null) {
      getCountryCode()
        .then((countryData) => {
          localStorage.setItem("countrydetails", true);
          localStorage.setItem("timezone", countryData.timezone);
          localStorage.setItem("currency", countryData.currency);
          setCurrency(countryData.currency);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [CountryDetails]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  async function getInvoices() {
    await fetch(`${baseurl}/fetch/user/invoices`, {
      method: "POST",
      body: JSON.stringify({
        user_id: USERID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setInvoiceList(data.data);
      });
  }
  async function getReceipt() {
    await fetch(`${baseurl}/fetch/user/receipt`, {
      method: "POST",
      body: JSON.stringify({
        user_id: USERID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setReceiptList(data.data);
      });
  }

  useEffect(() => {
    getInvoices();
  }, [USERID]);

  async function createOrder(amount, plan, currency) {
    const response = await fetch(`${baseurl}/create-order`, {
      method: "POST",
      body: JSON.stringify({
        user_id: USERID,
        amount: amount,
        plan: plan,
        currency: currency,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    console.log(data);
    return data.data[0];
  }
  const [Razorpay] = useRazorpay();
  const handlePayment = useCallback(
    async (amount, plan, currency) => {
      const order = await createOrder(amount, plan, currency);
      // const prefill={
      //   ...prefilldata
      // }

      const options = {
        key: `${process.env.REACT_APP_RAZORPAY_TEST_KEY}`,
        amount: order.amount,
        currency: order.currency,
        name: "1o1 Learners Club",
        description: "Test Transaction",
        image: learnersLOgo,
        order_id: order.razorpay_order_id,
        handler: async function (response) {
          const data = {
            razorpay_signature: response.razorpay_signature,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
          };

          const result = await fetch(`${baseurl}/verify-payment`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          });
          const responseData = await result.json();
          console.log(responseData);
          if (responseData.status === true) {
          }
        },
        prefill: {
          name: "",
          email: "",
          contact: "9999999999",
        },
        notes: {
          address: "1o1 Learners Club",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.on("payment.failed", function (response) {
        console.log(response.error.code);
        console.log(response.error.description);
        console.log(response.error.source);
        console.log(response.error.step);
        console.log(response.error.reason);
        console.log(response.error.metadata.order_id);
        console.log(response.error.metadata.payment_id);
      });
      rzpay.open();
    },
    [Razorpay]
  );

  useEffect(() => {
    console.log(receiptIndividual);
  }, [receiptIndividual]);

  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        setClosing(false);
      }, 1000);
    }
  }, [closing]);

  return (
    <div className={style.Container}>
      {whiteBoradIconShow ? (
        <div className={style.chatboticon}>
          <div
            onClick={() => {
              setWhiteBoardShow(true);
              setWhiteBoardIconShow(false);
            }}
            className={style.icontext}
          >
            <BiClipboard className={style.WhiteboardFloatingIcon} />
            <p> Whiteboard</p>
          </div>
        </div>
      ) : (
        ""
      )}

      {whiteBoradShow ? (
        <div
          className={`${style.WhiteBoradContainer} ${
            closing ? style.closing : ""
          }`}
        >
          <div className={style.WhiteBoradHead}>
            <div className={style.HeadText}>White Board</div>
            <div
              className={style.WhiteBoradIcon}
              onClick={() => {
                setTimeout(() => {
                  setWhiteBoardIconShow(true);
                  setWhiteBoardShow(false);
                }, 500);
                setClosing(true);
              }}
            >
              <AiOutlineClose />
            </div>
          </div>
          <div className={style.WhiteBoradContent}>
            <Whiteboard />
          </div>
        </div>
      ) : (
        ""
      )}
      {invoiceView ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setInvoiceView(false);
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setInvoiceView(false);
                }}
              />
            </div>
            <div className={style.membersection}>
              <div className={style.InputContainer}>
                <div className={style.LabelContainer}>
                  <label>Pincode</label>
                  <input
                    type="text"
                    name="Pincode"
                    id="Pincode"
                    placeholder="Pincode"
                    readOnly
                    value={invoiceIndividual.billingaddress?.[0]?.pin || ""}
                  />
                </div>
                <div className={style.LabelContainer}>
                  <label>Country</label>
                  <input
                    type="text"
                    name="Country"
                    id="Country"
                    placeholder="Country"
                    readOnly
                    value={invoiceIndividual.billingaddress?.[0]?.country || ""}
                  />
                </div>
              </div>
              <div className={style.InputContainer}>
                <div className={style.LabelContainer}>
                  <label>Address</label>
                  <input
                    type="text"
                    name="Address"
                    id="Address"
                    placeholder="Address"
                    readOnly
                    value={invoiceIndividual.billingaddress?.[0]?.address || ""}
                  />
                </div>
                <div className={style.LabelContainer}>
                  <label>taxable Amount</label>
                  <input
                    type="text"
                    name="TaxableAmount"
                    id="TaxableAmount"
                    placeholder="TaxableAmount"
                    readOnly
                    value={invoiceIndividual.taxableamount || ""}
                  />
                </div>
              </div>
              <div className={style.InputContainer}>
                <div className={style.LabelContainer}>
                  <label>net Amount</label>
                  <input
                    type="text"
                    name="NetAmount"
                    id="NetAmount"
                    placeholder="NetAmount"
                    readOnly
                    value={invoiceIndividual.net_amount || ""}
                  />
                </div>
                <div className={style.LabelContainer}>
                  <label>Discount</label>
                  <input
                    type="text"
                    name="Discount"
                    id="Discount"
                    placeholder="Discount"
                    readOnly
                    value={invoiceIndividual.discount || ""}
                  />
                </div>
              </div>
              <p style={{ margin: "4px", textAlign: "center" }}>
                Details related Items like Mentor,Course,Batch....
              </p>
              <div>
                <table className={style.InputTable}>
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Rate</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Discount</th>
                      <th>Net Total</th>
                      <th>UOM</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceIndividual.items &&
                      invoiceIndividual.items.map((item, index) => (
                        <tr key={index}>
                          <td>{item.itemname}</td>
                          <td>{item.rate}</td>
                          <td>{item.qty}</td>
                          <td>{item.total}</td>
                          <td>{item.discount}</td>
                          <td>{item.net_total}</td>
                          <td>{item.uom}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {receiptView ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setReceiptView(false);
            }}
          ></div>

          <div className={style.Receipt}>
            <div className={style.ReceiptContent}>
              <div className={style.ReceiptHead}>
                <p>One on One LearnersClub</p>
              </div>
              <div className={style.ReceiptDate}>
                Date:&nbsp;
                <p>
                  {new Date(
                    receiptIndividual?.CreatedTimestamp.$date
                  ).toLocaleString()}
                </p>
              </div>
              <div className={style.ReceiptBody}>
                {receiptIndividual && (
                  <>
                    <div className={style.bodyText}>
                      <div className={style.FirstText}>Name</div>:{" "}
                      <div className={style.SecondText}>
                        {" "}
                        {receiptIndividual.user_name}
                      </div>
                    </div>
                    <div className={style.bodyText}>
                      <div className={style.FirstText}>Amount</div>:{" "}
                      <div className={style.SecondText}>
                        {" "}
                        {receiptIndividual.net_amount}
                      </div>
                    </div>
                    <div className={style.bodyText}>
                      <div className={style.FirstText}>Discount</div>:{" "}
                      <div className={style.SecondText}>
                        {" "}
                        {receiptIndividual.discount}
                      </div>
                    </div>
                    <div className={style.bodyText}>
                      <div className={style.FirstText}>Payment mode</div>:
                      <div className={style.SecondText}>
                        {receiptIndividual.mode}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div className={style.tabSection}>
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: "#83b516" } }}
          value={selectedTab}
          onChange={handleTabChange}
        >
          <Tab
            label="Invoice"
            sx={{
              "&.Mui-selected": {
                color: "#3e61a3",
                backgroundColor: "",
              },
              fontSize: "1.25rem",
              color: "#1a1e5d",
              backgroundColor: "",
              width: "500px",
              margin: "0 .5rem",
              borderRadius: "10px",
            }}
          />
          <Tab
            label="Receipt"
            sx={{
              "&.Mui-selected": {
                color: "#3e61a3",
                backgroundColor: "",
              },
              fontSize: "1.25rem",
              color: "#1a1e5d",
              backgroundColor: "",
              width: "500px",
              margin: "0 .5rem",
              borderRadius: "10px",
            }}
            onClick={() => {
              getReceipt();
            }}
          />
        </Tabs>
      </div>
      <Typography>
        {selectedTab === 0 && (
          <div>
            <div className={style.Header}>
              <div className={style.HeaderText}>
                <p>Invoice List</p>
              </div>
              <div className={style.HeadButton}></div>
            </div>
            <div className={style.Table}>
              <table className={style.table}>
                <tr>
                  <th>Username</th>
                  <th>Taxable Amount</th>
                  <th>Discount</th>
                  <th>Net Amount</th>
                  <th>Actions</th>
                </tr>
                {invoiceList && invoiceList.length > 0
                  ? invoiceList.map((invoice, index) => {
                      return (
                        <tr key={index}>
                          <td>{invoice.user_name}</td>
                          <td>{invoice.taxableamount}</td>
                          <td>{invoice.discount}</td>
                          <td>{invoice.net_amount}</td>
                          <td>
                            {" "}
                            <div className={style.Buttons}>
                              <div className={style.iconContainer}>
                                <HiOutlineViewGrid
                                  className={style.calanderSchedule}
                                  onClick={() => {
                                    setInvoiceIndividual(invoiceList[0]);
                                    setInvoiceView(true);
                                  }}
                                />
                                <span className={style.tooltipText}>View</span>
                              </div>
                              <button
                                onClick={() => {
                                  handlePayment(
                                    parseInt(invoice.net_amount),
                                    "Basic",
                                    currency
                                  );
                                }}
                              >
                                Pay Now
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </table>
            </div>
          </div>
        )}
        {selectedTab === 1 && (
          <div>
            <div className={style.Header}>
              <div className={style.HeaderText}>
                <p>Receipt List</p>
              </div>
              <div className={style.HeadButton}></div>
            </div>
            <div className={style.Table}>
              <table className={style.table}>
                <tr>
                  <th>Username</th>
                  <th>Net Amount</th>
                  <th>Discount</th>
                  <th>Mode</th>
                  <th>Actions</th>
                </tr>
                {receiptList && receiptList.length > 0
                  ? receiptList.map((receipt, index) => {
                      return (
                        <tr key={index}>
                          <td>{receipt.user_name}</td>
                          <td>{receipt.net_amount}</td>
                          <td>{receipt.discount}</td>
                          <td>{receipt.mode}</td>
                          <td>
                            {" "}
                            <div className={style.Buttons}>
                              <div className={style.iconContainer}>
                                <HiOutlineViewGrid
                                  className={style.calanderSchedule}
                                  onClick={() => {
                                    setReceiptView(true);
                                    setReceiptIndividual(receipt);
                                  }}
                                />
                                <span className={style.tooltipText}>View</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </table>
            </div>
          </div>
        )}
      </Typography>
    </div>
  );
};

export default Billing;
