import React from "react";
import style from "./Lesson.module.css";
import {GrRefresh} from 'react-icons/gr'
const Lesson = () => {
  return (
    <div className={style.Container}>
      <div className={style.Lessons}>
        <div className={style.Head}>
          <p>Lessons</p>
        </div>
        <div className={style.Content}>
          <p>
            AI can support novices in acquiring the necessary skills and
            knowledge to perform skilled jobs more effectively. Here are a few
            ways in which AI can provide support: Training and Tutorials: AI can
            provide interactive training modules and tutorials that guide
            novices through step-by-step instructions, demonstrations, and
            simulation.
          </p>
        </div>
        <div className={style.Revission}>
          <div className={style.Head}>
            <p>Revission</p>
          </div>
          <div className={style.Refresh}>
            <GrRefresh></GrRefresh>
          </div>
        </div>
        <div className={style.Content}>
          <ol type="1"><li><p>Which are considered as  programming languages?</p></li></ol>
          <div className={style.Checkbox}>
            <input type="checkbox" name="answer"/><label>English</label><br/>
            <input type="checkbox" name="answer"/><label>Python</label><br/>
            <input type="checkbox" name="answer"/><label>Sql</label>
          </div>
        </div>
        <div className={style.Head}>
          <p>Assignment</p>
        </div>
        <div className={style.Content}>
            <div className={style.Task}>
                <p>Develop a web application for managing customer management (Create, Read, Edit, Delete) </p>
            </div>
            <div className={style.Options}>
                <ul>
                    <li className={style.Progrctlists}><p>Progect Design</p></li>
                    <li className={style.Progrctlists}><p>Progect workflow</p></li>
                    <li className={style.Progrctlists}><p>Progect code</p></li>
                </ul>
            </div>
            <button className={style.Addbutton}>Add Attachment</button>
        </div>
       
      </div>
    </div>
  );
};

export default Lesson;
