
import CryptoJS from 'crypto-js';

// export const encrypt = (message, key) => {
//  // Convert the message and key to WordArray objects
//  const messageBytes = CryptoJS.enc.Utf8.parse(message);
//  const keyBytes = CryptoJS.enc.Utf8.parse(key);

//  // Perform AES encryption
//  const encrypted = CryptoJS.AES.encrypt(messageBytes, keyBytes, {
//    mode: CryptoJS.mode.ECB,
//    padding: CryptoJS.pad.Pkcs7,
//  });

//  // Get the encrypted message as a base64-encoded string
//  return encrypted.toString();
// };

export const encryptJsonData = (jsonString) => {
  // const jsonArrayString = JSON.stringify(jsonArray);
  // const keyBytes =  CryptoJS.enc.Utf8.parse(key);

  // const encrypted = CryptoJS.AES.encrypt( jsonArrayString,key);

  // return encrypted.toString();
  // const jsonString = JSON.stringify(jsonArray);
 
  const base64String = btoa(jsonString);
  return base64String
};
// export const encryptedData=(data,key)=>{
//   const encrypted = CryptoJS.AES.encrypt( data,key);
//   return encrypted.toString();

// }
