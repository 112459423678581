import React,{useEffect,useState,useRef} from 'react'
import style from './Support.module.css'
import ProfileImage from '../../assests/Profile-PNG-File.png'
import { BsSearch } from "react-icons/bs";
import { BiFilter } from "react-icons/bi";
import { BsChatDots } from 'react-icons/bs';
import { MdCalendarMonth } from 'react-icons/md';
import { SiBookstack } from 'react-icons/si';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loder/Loader'
import { IoSend } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import { collection, getDocs,doc } from "firebase/firestore";
import {db} from '../Firebase';
import { MdClear } from "react-icons/md";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import {
  query,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import {baseurl} from '../Url'
import Whiteboard from '../WhiteBoard/WhiteBoard';
import { BiClipboard } from "react-icons/bi";
import { SiGooglemeet } from "react-icons/si";
import { generateMeetingUrl } from '../GoogleMeet';
import { useSelector,useDispatch } from 'react-redux';
import { UserProfile } from "../../actions/user";

const Support = () => {
  const key = JSON.parse(localStorage.getItem("access-token"));
  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");
  const ClubID=localStorage.getItem("ClubID")
  const mentor=localStorage.getItem("mentor")
  const UserName=localStorage.getItem("username")
  const[details,setDetails]=useState([]);
  const[search,setSearch]=useState("")
  const navigate=useNavigate()
  const[lodaing,setLoading]=useState(true)
  const[popup,setPopup]=useState(false)
  const[message,setMessage]=useState("")
  const[recever,setRecever]=useState("")
  const[receverid,setReceverID]=useState("")
  const[firestoreid,setFirestoreID]=useState("")
  const[mongoid,setMongoId]=useState("")
  const[chat,setChat]=useState([])
  const[chatLoader,setChatLoader]=useState(false)
  const [selectedTab, setSelectedTab] = useState(0);
  const[searcheditem,setSearchedItem]=useState([])
  const[invite,setInvite]=useState(false)
  const[allsearch,setAllsearch]=useState("")
  const[inviteEmail,setInviteEmail]=useState("")
  const[requestList,setRequestList]=useState([])
  const[generateLink,setGenerateLink]=useState("")
  const [trigger, setTrigger] = useState(0);
  const [buttonState,setButtonstate]=useState({})
  const[whiteBoradShow,setWhiteBoardShow]=useState(false)
  const[whiteBoradIconShow,setWhiteBoardIconShow]=useState(true)
  const[meetingButton,setMeetingButton]=useState(true)
  const[userEmail,setUserEmail]=useState("")
  const [userEmailError, setUserEmailError] = useState("");

  const clubIdsArray = ClubID.split(',');
  // const { profile } = useSelector((state) => state.profileData);
  const { profile = {} } = useSelector((state) => state.profileData || {});


  const dispatch=useDispatch()
  
  async function mentorDetails() {
    await fetch(`${baseurl}/get/mentor/mapped_to/user`, {
      method: "POST",
      body: JSON.stringify({
        org_id:OrgID,
        user_id:USERID
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        // setDetails(data.data)
      });
  }
  async function mentorSearch() {
    await fetch(`${baseurl}/mentor/search`, {
      method: "POST",
      body: JSON.stringify({
        org_id:OrgID,
        user_id:USERID,
        search:search
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setDetails(data.data)
      });
  }
  async function learnerSearch() {
    await fetch(`${baseurl}/learner/search`, {
      method: "POST",
      body: JSON.stringify({
        org_id:OrgID,
        user_id:USERID,
        search:search
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setDetails(data.data)
      });
  }
  // async function userDetails() {
  //   await fetch(`${baseurl}/mapped/mentor_or_user`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       org_id:OrgID,
  //       user_id:USERID,
  //       is_mentor:mentor
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": `Bearer ${key}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data);
  //       setDetails(data.data)
  //       setLoading(false)
  //     });
  // }
  async function userDetails() {
    await fetch(`${baseurl}/user/supportnetwork`, {
      method: "POST",
      body: JSON.stringify({
        org_id:OrgID,
        user_id:USERID,
        club_id:clubIdsArray
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setDetails(data.data)
        setLoading(false)
      });
  }


  async function roomCreate(rname,rid) {
    await fetch(`${baseurl}/roomcreation`, {
      method: "POST",
      body: JSON.stringify({
        org_id:OrgID,
        sender_id:USERID,
        sender_name:UserName,
        receiver_id:rid,
        receiver_name:rname
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data);
        setFirestoreID(data.data[0].firestore_roomid);
        setMongoId(data.data[0].mongoroom_id)
        setTrigger((prevTrigger) => prevTrigger + 1);
        setChatLoader(false)
      });
  }
  async function chatCreate() {
    await fetch(`${baseurl}/chat/create`, {
      method: "POST",
      body: JSON.stringify({
        org_id:OrgID,
        sender_id:USERID,
        sender_name:UserName,
        receiver_id:receverid,
        receiver_name:recever,
        content_type:"text",
        content:message,
        firestore_roomid:firestoreid,
        mongoroom_id:mongoid
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMessage("")
        setTrigger((prevTrigger) => prevTrigger + 1);
        setChatLoader(false)
      });
  }
  // async function anyoneSearch() {
  //   await fetch(`${baseurl}/supportnetwork/search/anyone`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       org_id:OrgID,
  //       user_id:USERID,
  //       search:allsearch
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": `Bearer ${key}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data);
  //       setSearchedItem(data.data)
  //     });
  // }

  async function anyoneSearch() {
    await fetch(`${baseurl}/supportnetwork/search/anyone/new`, {
      method: "POST",
      body: JSON.stringify({
        org_id:OrgID,
        user_id:USERID,
        search:allsearch,
        club_id:clubIdsArray
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSearchedItem(data.data)
      });
  }

  async function requestSend(recipientid) {
    await fetch(`${baseurl}/connection_request/create`, {
      method: "POST",
      body: JSON.stringify({
        org_id:OrgID,
        club_id:ClubID,
        sender_id:USERID,
        recipient_id:recipientid
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          const updatedButtonState = { ...buttonState };
        updatedButtonState[recipientid] = true; 
        setButtonstate(updatedButtonState);
        }
      });
  }
  async function requestGet() {
    await fetch(`${baseurl}/connection_request/get`, {
      method: "POST",
      body: JSON.stringify({
        org_id:OrgID,
        club_id:ClubID,
        user_id:USERID,
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          setRequestList(data.data)
          setLoading(false)
        }
      });
  }
  async function requestAction(senderid,connectionid,action) {
    await fetch(`${baseurl}/connection_request/accept_or_decline`, {
      method: "POST",
      body: JSON.stringify({
        recipient_id:USERID,
        sender_id:senderid,
        connection_request_id:connectionid,
        status:action
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
         requestGet()
         userDetails()
        }
      });
  }
  async function inviteGenerate() {
    await fetch(`${baseurl}/generate_invite`, {
      method: "POST",
      body: JSON.stringify({
        name:UserName
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          setGenerateLink(data.data[0].token)
        }
      });
  }
  async function emailInvite() {
    await fetch(`${baseurl}/email/invite`, {
      method: "POST",
      body: JSON.stringify({
        name:UserName,
        mail_id:inviteEmail,
        url:generateLink
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          setInvite(false)
        }
      });
  }
  
  const textareaRef = useRef(null);
  const copyToClipboard = () => {
    if (textareaRef.current) {
      textareaRef.current.select();
      document.execCommand('copy');
      setInvite(false)
    }
  };
  

  useEffect(()=>{
    if(allsearch!==""){
      anyoneSearch()
    }
    else{
      setSearchedItem([])
    }
  },[allsearch])


  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if(message!==""){
        chatCreate()
      }
    }
  };

  useEffect(() => {
    function handleKeyPress(event) {
      if (event.key === 'Escape') {
        setPopup(false);
        setChat([])
      }
    }
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const startMeeting = () => {
    if (validateEmail(userEmail)) {
      // Email is valid, proceed to start the meeting
      try {
        const meetingUrl = generateMeetingUrl();
        window.open(meetingUrl, '_blank');
      } catch (error) {
        console.error('Error starting meeting:', error);
      }
    } else {
      // Email is not valid, set error message
      setUserEmailError("Please enter a valid email address.");
    }
  };
  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Check if the email matches the basic format
    if (!regex.test(email)) {
      return false; // Invalid format
    }
  
    // Check if the email domain is gmail.com
    const domain = email.split('@')[1];
    return domain.toLowerCase() === 'gmail.com';
  };
  

  useEffect(() => {
    console.log("chat called");
    if (firestoreid) { 
      const roomRef = doc(db, 'chats', 'chatrooms', 'rooms', firestoreid);
      const messagesCollectionRef = collection(roomRef, 'Messages');
      const q = query(messagesCollectionRef,orderBy("server_time", "desc"),);
  
      const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
        const fetchedMessages = [];
        QuerySnapshot.forEach((doc) => {
          fetchedMessages.push({ ...doc.data(), id: doc.id });
        });
  console.log(fetchedMessages);
        // const sortedMessages = fetchedMessages.sort(
        //   (b, a) =>  b.createdAt -a.createdAt 
        // );
       
          
        setChat(fetchedMessages);
      });
      return () => unsubscribe;
    }
  },[firestoreid,trigger]);
  useEffect(()=>{
console.log(trigger);
  },[trigger])

  const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
  };

  
  const scroll = useRef();

  useEffect(()=>{
    // mentorDetails()
    userDetails()
  },[key])

   
  useEffect(()=>{
 if(search!==""){
  if(mentor==="true"){
    learnerSearch()
  }
  else{
    mentorSearch()
  }
 }
 else{
  userDetails()
 }
  },[search])
  const inputRef = useRef(null);
  useEffect(() => {
    if (popup && inputRef.current) {
      inputRef.current.focus();
    }
  }, [popup]);

  const [closing, setClosing] = useState(false);

useEffect(()=>{
if(closing){
  setTimeout(() => {
    setClosing(false)
  }, 1000);
}
},[closing])

useEffect(()=>{
  console.log(USERID);
  if(USERID!==undefined){
    dispatch(UserProfile())
  }

},[USERID])
useEffect(()=>{
  if(profile){
   setUserEmail(profile.Email)
  }

},[profile])

  return (
    <div className={style.Container}>
      {lodaing?<Loader/>:''}
      {whiteBoradIconShow  ? (
        <div className={style.chatboticon}>
          <div onClick={()=>{
        setWhiteBoardShow(true)
        setWhiteBoardIconShow(false)
      }}  className={style.icontext}>
       <BiClipboard className={style.WhiteboardFloatingIcon} /><p> Whiteboard</p>
      </div>
         
        </div>
      ) : (
        ""
      )}
      {meetingButton  ? (
        <div className={style.Meetingicon}>
          <div onClick={()=>{
            startMeeting()
          }}  className={style.MeetingiconText}>
       <SiGooglemeet  className={style.WhiteboardFloatingIcon} /><p> Start meeting</p>
       {userEmailError && <p className={style.Error}>{userEmailError}</p>}
      </div>
         
        </div>
      ) : (
        ""
      )}
      {popup?
      <>
      <div className={style.Overlay}></div>
        <div className={`${style.Popup} ${closing ? style.closing : ''}`}>
        {chatLoader?<Loader/>:''}
        <div className={style.popupHead}>
          <div className={style.HeadText}>
            <p>{recever}</p>
          </div>
          <div
            className={style.PopupIcon}
            onClick={() => {
              setTimeout(() => {
                setPopup(false);
                setLoading(false);
                setChat([])
              }, 500);
             
              setClosing(true);
            }}
          >
            <AiOutlineClose />
          </div>
        </div>
        <div className={style.PopupContent} >
        {chat.map((message) => {
          const isSenderMe = message.user_name === UserName;
          return(
          <div key={message.id}  className={`${style.message} ${isSenderMe ? style.sender : style.receiver}`}>
           {message.content}
          </div>
          )
         })}
        <span ref={scroll}></span>
        </div>
       
        <div className={style.BotInput}>
          <div className={style.Input}><input 
          ref={inputRef}
          value={message}
          onChange={(e)=>{
            setMessage(e.target.value)
          }}
          onKeyDown={handleKeyPress}
          placeholder='Type a message...'
          />
          
          </div>
          <IoSend  className={style.Icon} onClick={()=>{
            if(message!==""){
              chatCreate()
              setChatLoader(true)
            }
          }} />
        </div>
      </div>
      </>
      :''}
      {invite?
      <div className={style.InvitePopupOverlay}>
        <div className={style.InvitePopup}>
          <div className={style.InvitePopupHead}>
            <p>Invite to join with you</p>
            <span onClick={()=>{
              setInvite(false)
            }} className={style.ClearIcon}><MdClear/></span>
          </div>
          <div className={style.InvitePopupContent}>
            <div className={style.Emailinput}>
              <input value={inviteEmail} onChange={(e)=>{
                setInviteEmail(e.target.value)
              }} type="email" placeholder='Enter Email Address'></input>
              <button onClick={()=>{
                emailInvite()
              }} disabled={inviteEmail===""}>Invite</button>
            </div>
            <div className={style.IviteTextarea}>
            <p>OR Share below Invitation link through your email,  messenger or social media</p>
            
              <textarea value={generateLink}  rows="4" cols="50" ref={textareaRef}/>
              <div className={style.IviteTextareaButton}>
              <button onClick={()=>{
                copyToClipboard()
              }}>Copy Invitation Link</button>
            </div>
  
            </div>
          </div>
        </div>
        
      </div>
      :''}
      {whiteBoradShow?
      <div  className={`${style.WhiteBoradContainer} ${closing ? style.closing : ''}`}>
        <div className={style.WhiteBoradHead}>
          <div className={style.HeadText}>
            White Board
          </div>
          <div
            className={style.WhiteBoradIcon}
            onClick={() => {
              setTimeout(() => {
                setWhiteBoardIconShow(true)
              setWhiteBoardShow(false)
              }, 500);
              setClosing(true);
            }}
          >
            <AiOutlineClose />
          </div>
        </div>
        <div className={style.WhiteBoradContent} >
        <Whiteboard/>
        
        </div>

      </div>
      :''}
       
        <div className={style.Support}>
        <div className={style.Header}>
          <div className={style.Search}>
            <BsSearch/>
            <input type='search' value={allsearch} onChange={(e)=>{
              setAllsearch(e.target.value)
            }} onFocus={(e) => {
              e.target.setAttribute("data-placeholder", e.target.placeholder);
              e.target.placeholder = "";
            }}
            onBlur={(e) => {
              e.target.placeholder = e.target.getAttribute("data-placeholder");
            }} placeholder='Search tutors , mentors and peers...'/>
            {/* {allsearch!==""?
            <span onClick={()=>{
              setSearch("")
            }}><MdClear style={{cursor:"pointer"}}/></span>
            :''} */}
          </div>
          {/* <div className={style.Filter}>
            <BiFilter/>
            <p>Filter</p>
          </div> */}
           <div className={style.Tabbuttons}>
              <button onClick={()=>{
                setInvite(true)
               inviteGenerate()
              }}>Invite</button>
            </div>
        </div>
        {!allsearch?
        <>
          <div className={style.Tabs}>
            <Tabs
            TabIndicatorProps={{ style: { backgroundColor: "#1a1e5d" } }}
              value={selectedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
                
            >
              <Tab
              className={style.NewTAb}
                label="Your Connections"
                sx={{
                  "&.Mui-selected": {
                    // color: "white",
                    // backgroundColor: "#1a1e5d",
                    fontWeight: "bold",
                    
                  },
                  '@media (max-width: 600px)': {
                    fontSize:"0.8rem",
                    margin: "0 .25rem",
                    padding: "0"
                  },
                  '@media (max-width: 400px)': {
                    fontSize:"0.7rem",
                    width:"100px"
                  },
                  fontSize: "1rem",
                  color: "#1a1e5d !important",
                  // backgroundColor: "#dbdbdb",
                  // width: "250px",
                  margin: "0 .5rem",
                  borderRadius: "10px",
                }}
              />
              {/* <Tab
                label="Connection Requests"
                className={style.NewTAb}
                sx={{
                  "&.Mui-selected": {
                    // color: "white",
                    // backgroundColor: "#1a1e5d",
                    fontWeight: "bold",
                  },
                  '@media (max-width: 600px)': {
                    fontSize:"0.8rem",
                    margin: "0 .25rem",
                    padding: "0"
                  },
                  '@media (max-width: 400px)': {
                    fontSize:"0.7rem",
                    width:"100px"
                  },
                  fontSize: "1rem",
                  color: "#1a1e5d !important",
                  // backgroundColor: "#dbdbdb",
                  // width: "250px",
                  margin: "0 .5rem",
                  borderRadius: "10px",
                }}
                onClick={()=>{
                  requestGet()
                  setLoading(true)
                }}
              /> */}
               {/* <Tab
                label="Search"
                sx={{
                  "&.Mui-selected": {
                    // color: "white",
                    // backgroundColor: "#1a1e5d",
                    fontWeight: "bold",
                  },
                  fontSize: "1rem",
                  color: "#1a1e5d !important",
                  // backgroundColor: "#dbdbdb",
                  width: "250px",
                  margin: "0 .5rem",
                  borderRadius: "10px",
                }}
              /> */}
              </Tabs>
          </div>
          <Typography>
            {selectedTab ===0 && (
             <div>
                <div className={style.FirstDiv}>
                {details&&details.length>0?details.map((user,index)=>{
                  return(
                    <div onClick={() => {
                        navigate("/peerprofile",{state:{
                          userID:user._id,
                          Role:user.role
                        }});
                      
                    }} key={index} className={style.FirstCard}>
                  <div className={style.Image}>
                   <div className={style.imageDiv}>
                    {user.profile?<img src={user.profile}/>:
                     <img src={ProfileImage}/>  }</div>
                  </div>
                  <div className={style.Content}>
                    <div className={style.Maincontent}>
                      <>
                        <p>{user.Username} ({user.role}) <br/>{user.About && <span style={{ display: 'block' }}>{user.About}<br/></span>}
      {user.Experience && <span style={{ display: 'block' }}>{user.Experience} </span>}</p>
                      </>
                        <div style={{width:(mentor==="true")?"6rem":"4rem",}} className={style.Icons}>
                          {mentor==="true"?
                          <>
                          {user.role==="Mentor"?"":
                          <span onClick={(e)=>{
                            e.stopPropagation();
                            localStorage.setItem("learnertoken",user.token)
                            localStorage.setItem("learneruserid",user._id)
                            navigate("/learnings",{
                              state:{
                                token:user.token,username:user.Username,userID:user._id,peerRole:user.role,source: "support"
                              }
                              
                            })
                          }}><SiBookstack/></span>
                        }
                        
                         <span onClick={(e)=>{
                          e.stopPropagation();
                            setPopup(true)
                            setRecever(user.Username)
                            setReceverID(user._id)
                            roomCreate(user.Username,user._id)
                            setChatLoader(true)
                           
                          }}><BsChatDots/></span>
                          <span onClick={(e)=>{
                            e.stopPropagation();
                            if(mentor==="true"){
                              navigate("/usercalendar",{
                                state:{userid:user._id,username:user.Username,Role:user.role}
                              })
                            }
                            
                          }}><MdCalendarMonth/></span>
                          </>
                          :
                          <>
                          {user.role==="Mentor"?"":
                          <span onClick={(e)=>{
                            e.stopPropagation();
                            localStorage.setItem("learnertoken",user.token)
                            localStorage.setItem("learneruserid",user._id)
                            navigate("/learnings",{
                              state:{
                                token:user.token,username:user.Username,userID:user._id,peerRole:user.role,source: "support",
                              }
                              
                            })
                          }}><SiBookstack/></span>
                        }
                          <span onClick={(e)=>{
                            e.stopPropagation();
                            setPopup(true)
                            setRecever(user.Username)
                            setReceverID(user._id)
                            roomCreate(user.Username,user._id)
                            setChatLoader(true)
                          }}><BsChatDots/></span>
                          <span onClick={(e)=>{
                            e.stopPropagation();
                            navigate("/usercalendar",{
                              state:{userid:user._id,username:user.Username,Role:user.role}
                            })
                          }}><MdCalendarMonth/></span>
                          </>
                        }
                          
                        </div>
                    </div>
                    {/* <div className={style.Allocated}>
                      Allocated:Cloud Infrastructure & programing
                    </div> */}
                  </div>
                </div>
                  )
                }):<div>
                  {!lodaing?
                  <p> "Oops ! There are many learners and mentors who wants to connect with you. You have not found them yet? Search and request for new connections."</p>
                  :''}
                  </div>}
                
              
              </div>
             </div>
            )}
            {selectedTab===1&&(
              <div className={style.Request}>
                <div className={style.RequestList}>
                  {requestList&&requestList.length>0?requestList.map((requests,index)=>{
                    return(
                      <div key={index} onClick={() => {
                        navigate("/peerprofile",{state:{
                          userID:requests.sender_id,
                          Role:requests.sender_role
                        }});
                      
                    }}  className={style.RequestCard}>
                   <div className={style.RequestHead}>
                    <h3>{requests.sender_name}</h3>
                    <p>{requests.sender_email}</p>
                   </div>
                    <div className={style.RequestButtons}>
                      <button onClick={(e)=>{
                        e.stopPropagation();
                        requestAction(requests.sender_id,requests.connection_request_id,"accept")
                      }}>Accept</button>
                      <button onClick={(e)=>{
                        e.stopPropagation();
                       requestAction(requests.sender_id,requests.connection_request_id,"decline")
                      }}>Decline</button>
                    </div>
                  </div>
                    )
  
                  })
                  :<div>
                    {!lodaing?
                    <p>No pending connection requests for you.</p>
                    :''}
                    </div>}
                </div>
              </div>
            )}
            {selectedTab===2 && (
              <div className={style.SearchHead}>
          
              </div>
              )}
  
          </Typography>
        </>
          
          :
          <div className={style.SearchDiv}> 
          {searcheditem&&searcheditem.length>0?searcheditem.map((search,index)=>{
            return(
              <div key={index} className={style.FirstCard}>
            <div className={style.Image}>
             <div className={style.imageDiv}> <img src={ProfileImage}/>  </div>
            </div>
            <div className={style.Content}>
              <div className={style.Maincontent}>
                <>
                  <p>{search.Username} ({search.Role}) <br/>{search.About&&<span>{search.About}<br/></span>}
                  {search.Experience&&<span>{search.Experience}</span>} Years of Experience </p>
                </>
                  
              </div>
              {search.already_mentor===true||search.connection_status==="accept"?'':
              <div className={style.RequestButton}>
              <button style={{backgroundColor:(buttonState[search._id])||search.request_send?"grey":"#1a1e5d"}} disabled={(buttonState[search._id])||search.request_send} onClick={()=>{
               requestSend(search._id)
              }}>{(buttonState[search._id])||search.request_send?"Requested":"Send Request"}</button>
             </div>
              }
            </div>
          </div>
            )
          }):""}
        </div>}

          {/* <div className={style.SecondDiv}>
            <div className={style.seccondCard}>
              <div className={style.Cardimage}>
                <div className={style.SecondImage}>
                  <img src={ProfileImage}/>
                </div>
                <div className={style.CardDetails}>
                  Ms.Jisha<br/>
                  Mentor<br/>
                  Web Apps
                </div>

              </div>
              <div className={style.seccondCardText}>
                I am working with corporates <br/>as app consultant

              </div>
              <div className={style.CardButton}>
                <button>Book Appoinment</button>
              </div>
            </div>
            <div className={style.seccondCard}>
              <div className={style.Cardimage}>
                <div className={style.SecondImage}>
                  <img src={ProfileImage}/>
                </div>
                <div className={style.CardDetails}>
                  Ms.Jisha<br/>
                  Mentor<br/>
                  Web Apps
                </div>

              </div>
              <div className={style.seccondCardText}>
                I am working with corporates <br/>as app consultant

              </div>
              <div className={style.CardButton}>
                <button>Book Appoinment</button>
              </div>
            </div>
          </div> */}
        </div>
    </div>
  )
}

export default Support