import React, { useState, useEffect,useRef } from "react";
import style from "./Assessment.module.css";
import { useLocation,useNavigate } from "react-router-dom";
import { baseurl } from "../Url";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TrophyImage from "../../assests/Trophy.png";
import SecondTrophyImage from "../../assests/SecondTrophy.png";
import ThirdTrophyImage from "../../assests/ThirdTrophy.png";
import FourthTrophyImage from "../../assests/FourthTrophy.png";
import { Doughnut } from "react-chartjs-2";
import Loader from "../Loder/Loader";
import lessonImage from "../../assests/online lesson.png"
import timeImage from "../../assests/Time.png"
import questionImage from  "../../assests/19 Questionnaire.png"
import motivationImage from "../../assests/Motivation.png"
import {
  Alert,
  Snackbar,

} from "@mui/material";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { useSelector } from 'react-redux';

const Assessment = () => {

  const navigate =useNavigate();
  const location = useLocation();
  const state = location.state;
  const Topic = state?.singleTopic;
  const learning_id = state?.learning;
  const admin_Mapped_Lessons = state?.mapped;
  const main_topic_id = state?.mainID;
  const topicNo = state?.topicNo;
  const aiID = state?.aiID;

  const { userDetails, status: userStatus, error: userError } = useSelector((state) => state.user);

  const key = JSON.parse(localStorage.getItem("access-token"));
  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");
  const UserName=localStorage.getItem("username")

  const [assessment, setAssessment] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAssessment, setCurrentAssessment] = useState({});
  const [validateClicked, setValidateClicked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [assessmentData, setAssessmentData] = useState({});
  const [finishPopup,setFinishPopup]=useState(false)
  const [result, setResult] = useState(false);
  const [summaryDetail,setSummaryDetail]=useState({})
  const inputRefs = useRef({});
  const [loading, setLoading] = useState(true);
  const [trialUser,setTrailUser]=useState(false)
  const [error,setError]=useState("")
  const highlightRefs = useRef({});


  const numbers = Array.from(
    { length: assessment.length },
    (_, index) => index + 1
  );


  const totalCount = assessment.length;
  const correctCount = Object.values(assessmentData).filter(
    (data) => data.status === "correct"
  ).length;
  const incorrectCount = Object.values(assessmentData).filter(
    (data) => data.status === "incorrect"
  ).length;
  const skippedCount = Object.values(assessmentData).filter(
    (data) => data.status === "skipped"
  ).length;

  const handleOptionClick = (option, index) => {
    const uniqueId = currentAssessment.unique_id;
    const isMultiSelect = currentAssessment.isMultiSelect;

    setAssessmentData((prev) => {
        const prevAnswers = prev[uniqueId]?.answers || [];
        let newAnswers;

        if (isMultiSelect) {
            if (prevAnswers.includes(option)) {
                newAnswers = prevAnswers.filter((ans) => ans !== option);
            } else {
                newAnswers = [...prevAnswers, option];
            }
        } else {
            newAnswers = [option];
        }

        return {
            ...prev,
            [uniqueId]: {
                ...prev[uniqueId],
                answers: newAnswers,
            },
        };
    });

    setSelectedOptions((prev) => {
        if (isMultiSelect) {
            const selectedIndexes = prev[uniqueId] || [];
            return {
                ...prev,
                [uniqueId]: selectedIndexes.includes(index)
                    ? selectedIndexes.filter((i) => i !== index)
                    : [...selectedIndexes, index],
            };
        } else {
            return {
                ...prev,
                [uniqueId]: [index],  
            };
        }
    });
};


  const handleInputChange = (index, value) => {
    const uniqueId = currentAssessment.unique_id;
    setAssessmentData((prev) => {
      const updatedAnswers = [...(prev[uniqueId]?.answers || [])];
      updatedAnswers[index] = value;
      return {
        ...prev,
        [uniqueId]: {
          ...prev[uniqueId],
          answers: updatedAnswers,
        },
      };
    });
  };
  
  const formatQuestionText = (text) => {
    return (
      <span
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  };
  const renderQuestionWithInputs = (question) => {
    const parts = question.split(/\[blank (\d+)\]/);
    const uniqueId = currentAssessment.unique_id;
    const status = assessmentData[uniqueId]?.status;
    
    return (
      <p> {/* Flexbox container */}
        {parts.map((part, index) => {
          if (index % 2 === 1) {
            const blankIndex = parseInt(parts[index], 10) - 1;
            let inputStyle = {};
  
            if (status === "correct") {
              inputStyle = { color: "#00DFC7" };
            } else if (status === "incorrect") {
              inputStyle = { color: "#FF2443" };
            } else {
              inputStyle = { color: "#FF7A00" };
            }
  
            // Calculate dynamic width based on input value length
            const inputValue = assessmentData[uniqueId]?.answers[blankIndex] || "";
            const calculatedWidth = inputValue.length * 10 + 20;
            const inputWidth = `${Math.min(Math.max(calculatedWidth, 100), 500)}px`;
  
            return (
              <React.Fragment key={index}>
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => handleInputChange(blankIndex, e.target.value)}
                  className={style.blankInput}
                  disabled={assessmentData[currentAssessment.unique_id]?.answered}
                  style={{ ...inputStyle, width: inputWidth }}
                />
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={index}>
                {formatQuestionText(part)}
              </React.Fragment>
            );
          }
        })}
      </p>
    );
  };
  
  
  const renderQuestionWithDropdowns = (question, options) => {
    const uniqueId = currentAssessment.unique_id;
    const parts = question.split(/(\[dropdown-\d+\])/);
  
    return (
      <div style={{display:"flex",alignItems:"center"}}>
        {parts.map((part, index) => {
          const dropdownMatch = part.match(/\[dropdown-(\d+)\]/);
  
          if (dropdownMatch) {
            const dropdownId = parseInt(dropdownMatch[1], 10); 
            const dropdownOptions = options.find(option => option.id === dropdownId)?.options || [];
  
            return (
              <React.Fragment key={index}>
                <select
                  value={assessmentData[uniqueId]?.answers?.[dropdownId] || ""}
                  onChange={(e) => {
                    const newAnswers = {...(assessmentData[uniqueId]?.answers || {})};
                    newAnswers[dropdownId] = e.target.value;
                    setAssessmentData((prev) => ({
                      ...prev,
                      [uniqueId]: {
                        ...prev[uniqueId],
                        answers: newAnswers,
                      },
                    }));
                  }}
                  className={style.blankDropdown}
                  disabled={assessmentData[currentAssessment.unique_id]?.answered}
                >
                  <option disabled value="">
                    Select an option
                  </option>
                  {dropdownOptions.map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </React.Fragment>
            );
          }
          return <React.Fragment key={index}>{formatQuestionText(part)}</React.Fragment>;
        })}
      </div>
    );
  };
  
  
  
  

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
  
    const sourceDroppableId = result.source.droppableId;
    const destinationDroppableId = result.destination.droppableId;
    const uniqueId = currentAssessment.unique_id;
  
    setAssessmentData((prev) => {
      const prevAnswers = prev[uniqueId]?.answers || { left: [], right: [] };
  
      let newAnswers = { ...prevAnswers };
  
      if (sourceDroppableId.startsWith("left") && destinationDroppableId.startsWith("left")) {
        const items = Array.from(newAnswers.left);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        newAnswers.left = items;
      } else if (sourceDroppableId.startsWith("right") && destinationDroppableId.startsWith("right")) {
        const items = Array.from(newAnswers.right);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        newAnswers.right = items;
      }
  
      return {
        ...prev,
        [uniqueId]: {
          ...prev[uniqueId],
          answers: newAnswers,
        },
      };
    });
  };



  const handleArrangeDragEnd = (result) => {
    if (!result.destination) return;

    const uniqueId = currentAssessment.unique_id;
    const items = Array.from(assessmentData[uniqueId]?.answers || []);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAssessmentData((prev) => ({
      ...prev,
      [uniqueId]: {
        ...prev[uniqueId],
        answers: items,
      },
    }));
  };

  const handleCellValueChange = (rowIndex, colIndex, value, clueID, clueNumber) => {
    const uniqueId = currentAssessment.unique_id;

    setAssessmentData(prevData => {
        const newAnswers = prevData[uniqueId]?.answers.map((row, i) =>
            row.map((cell, j) =>
                i === rowIndex && j === colIndex
                    ? { ...cell, cluevalue: value.toUpperCase(), clueID, clueNumber }
                    : cell
            )
        ) || [];

        return {
            ...prevData,
            [uniqueId]: {
                ...prevData[uniqueId],
                answers: newAnswers,
            },
        };
    });
    const rows = assessmentData[uniqueId]?.answers.length || 0;
    const cols = assessmentData[uniqueId]?.answers[0]?.length || 0;

    let nextRowIndex = rowIndex+ 1;
    let nextColIndex = colIndex ; 

    if (nextRowIndex >= rows) {
        nextColIndex += 1; 
        nextRowIndex = 0; 
    }
    if (nextRowIndex < rows && inputRefs.current[uniqueId]?.[nextRowIndex * cols + nextColIndex]) {
        const nextInputRef = inputRefs.current[uniqueId][nextRowIndex * cols + nextColIndex];
        if (nextInputRef) {
            console.log("Focusing on:", nextInputRef);
            nextInputRef.focus();
        }
    }
};

const getHighlightColor = (status) => {
  switch (status) {
    case 'correct':
      return '#00FFC1'; // Color for correct status
    case 'incorrect':
      return '#FF7C8E'; // Color for incorrect status
    default:
      return '#FF7A00'; // Default highlight color
  }
};

const handleWordSelection = () => {
  const selection = window.getSelection();
  const selectedText = selection.toString().trim();
  const uniqueId = currentAssessment.unique_id;

  if (selectedText && assessmentData[uniqueId]) {
      const container = highlightRefs.current[uniqueId];
      const originalContent = assessmentData[uniqueId].originalContent || container.innerHTML;
      const contentWithoutHighlights = originalContent.replace(/<span class="highlighted"[^>]*>(.*?)<\/span>/g, '$1');
      const startIndex = contentWithoutHighlights.indexOf(selectedText);
      
      if (startIndex !== -1) {
          const endIndex = startIndex + selectedText.length;
          const highlightedRegex = new RegExp(`<span class="highlighted"[^>]*>${selectedText}</span>`);
          const isAlreadyHighlighted = highlightedRegex.test(container.innerHTML);
          let newContent;
          if (isAlreadyHighlighted) {
              newContent = contentWithoutHighlights;
          } else {
              const highlightColor = getHighlightColor(assessmentData[uniqueId]?.status);
              const highlightedText = `<span class="highlighted" style="color: ${highlightColor}; white-space: pre-wrap;">${selectedText}</span>`;
              newContent = contentWithoutHighlights.slice(0, startIndex) +
                  highlightedText +
                  contentWithoutHighlights.slice(endIndex);
          }

          setAssessmentData((prev) => ({
              ...prev,
              [uniqueId]: {
                  ...prev[uniqueId],
                  highlightContent: newContent,
                  lastSelectedText: isAlreadyHighlighted ? '' : selectedText,
              },
          }));

          selection.removeAllRanges();
      }
  }
};










  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
    setValidateClicked(false);
  };

  const handleSkip = () => {
    const uniqueId = currentAssessment?.unique_id;
    if (assessmentData[uniqueId]?.status === 'viewed') {
      setAssessmentData((prev) => ({
        ...prev,
        [uniqueId]: {
          ...prev[uniqueId],
          status: 'skipped',
        },
      }));
    }

    
    if (uniqueId && assessmentData[uniqueId]) {
        const currentAssmnt = assessment[currentIndex];
        let resetAnswers;
        let resetHighlightContent = "";
        let resetLastSelectedText = "";
        let resetAcrossClues = [];
        let resetDownClues = [];

        if (currentAssmnt.type === "Match the following" || currentAssmnt.type === "Match the following with image") {
          const filteredLeft = (currentAssmnt.left || []).filter(
            (leftData) => leftData.trim() !== ""
        );
        const filteredRight = (currentAssmnt.right || []).filter(
            (rightData) => rightData.trim() !== ""
        );
            resetAnswers = {
                left: filteredLeft,
                right: filteredRight,
            };
        } else if (currentAssmnt.type === "Rearrange") {
            resetAnswers = currentAssmnt.options || [];
        } else if (currentAssmnt.type === 'cross word puzzle') {
            resetAnswers = currentAssmnt.options.map(row => (
                row.map(cell => ({
                    clueID: cell.clueID || "",
                    clueNumber: cell.clueNumber,
                    cluevalue: '',
                    disabled: cell.cluevalue === '',
                }))
            ));

            resetAcrossClues = currentAssmnt.left.map(clueObj => ({
                id: clueObj.id,
                number: clueObj.number,
                clue: clueObj.clue,
                answer: ''
            }));
            resetDownClues = currentAssmnt.right.map(clueObj => ({
                id: clueObj.id,
                number: clueObj.number,
                clue: clueObj.clue,
                answer: '' 
            }));
        } else if (currentAssmnt.type === "highlight") {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = currentAssmnt.options;
            resetHighlightContent = tempDiv.textContent || tempDiv.innerText || ""; 
            resetLastSelectedText = "";
        } else {
            resetAnswers = [];
        }

        setAssessmentData((prev) => ({
            ...prev,
            [uniqueId]: {
                ...prev[uniqueId],
                answers: resetAnswers,
                highlightContent: resetHighlightContent,
                lastSelectedText: resetLastSelectedText,
                cluesAcross: resetAcrossClues,
                cluesDown: resetDownClues,
            },
        }));
        setSelectedOptions((prev) => {
          const { [uniqueId]: _, ...rest } = prev; 
          return rest;
      });
    }

    // Proceed to next question
    setCurrentIndex(currentIndex + 1);
    setValidateClicked(false);
};


async function assessmentGet() {
  await fetch(`${baseurl}/ai/assignment/generation/for/user/new`, {
    method: "POST",
    body: JSON.stringify({
      Organization_ID: OrgID,
      topic_id: Topic.id,
      topic_name: Topic.value,
      learning_id: learning_id,
      main_topic_id: main_topic_id,
      user_id: USERID,
      admin_mapped: "true",
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if (data.status === true) {
        const modifiedAssessments = data.data[0]?.assessments.map((assessment, index) => {
          const baseQuestion = {
            ...assessment,
            question_no: index + 1,
          };
          const cleanedQuestion = baseQuestion.question.replace(/(<p><br><\/p>)+$/, '');

          if (assessment.type === "Match the following" || assessment.type === "Match the following with image") {
            return {
              ...baseQuestion,
              question: cleanedQuestion, 
              draggable: "right",
            };
          } else if (assessment.type === "multiple choices") {
            const isMultiSelect = assessment.answer.length > 1;
            return {
              ...baseQuestion,
              question: cleanedQuestion, 
              isMultiSelect,
            };
          }
          return {
            ...baseQuestion,
            question: cleanedQuestion, 
          };
        });

        setAssessment(modifiedAssessments);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setAssessment([]);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
}


  async function assessmentAnswer() {
    const questionId = currentAssessment.id
      ? currentAssessment.id.toString()
      : "";
      let answerToSend;
      if (currentAssessment.type === "Match the following"||currentAssessment.type === "Match the following with image") {
          if (currentAssessment.draggable === "right") {
              answerToSend = assessmentData[currentAssessment.unique_id]?.answers?.right || [];
          } else if (currentAssessment.draggable === "left") {
              answerToSend = assessmentData[currentAssessment.unique_id]?.answers?.left || [];
          }
      }else if(currentAssessment.type === "cross word puzzle"){
         answerToSend = assessmentData[currentAssessment.unique_id]?.answers.map(answerGroup => (
          Array.isArray(answerGroup) ? 
            answerGroup.map(answer => ({
                clueID: answer.clueID,
                clueNumber: answer.clueNumber,
                cluevalue: answer.cluevalue,
            })) :
            answerGroup 
      ));
      }else if(currentAssessment.type==="highlight"){
        answerToSend=[assessmentData[currentAssessment.unique_id]?.lastSelectedText]||[]
      }else if (currentAssessment.type === "fill in the blanks") {
        answerToSend = assessmentData[currentAssessment.unique_id]?.answers.map(answer => answer.trim()) || [];
      }else if (currentAssessment.type === "select the answers") {
        const answersObj = assessmentData[currentAssessment.unique_id]?.answers || {};
        answerToSend = Object.values(answersObj).length > 0 ? Object.values(answersObj) : [];
      } else {
          answerToSend = assessmentData[currentAssessment.unique_id]?.answers || [];
      }
  

    await fetch(`${baseurl}/user/assignment/answers/validation`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        topic_id: Topic.id,
        topic_name: Topic.value,
        question: currentAssessment.question,
        question_id: questionId,
        answer: answerToSend,
        ai_id: aiID,
        main_topic_id: main_topic_id,
        user_id: USERID,
        question_type: currentAssessment.type,
        options: currentAssessment.type === "highlight" ? [] : currentAssessment.options || [],
        unique_id: currentAssessment.unique_id,
        generated_by: currentAssessment.generation === "admin" ? "admin" : "",
        left: currentAssessment.left ? currentAssessment.left : [],
        right: currentAssessment.right ? currentAssessment.right : [],
        actual_ans:currentAssessment.type === "cross word puzzle"?currentAssessment.options:currentAssessment.answer,
        question_explained:currentAssessment.question_explained,
        time_taken: "",
        markif_correct: currentAssessment.mark,
        markif_incorrect: currentAssessment.negative_mark,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        const newStatus = data.data[0].answer_is === "Correct" ? "correct" : "incorrect";
        const matchFieldExists = data.data[0]?.match !== undefined;
        setAssessmentData((prev) => ({
          ...prev,
          [currentAssessment.unique_id]: {
            ...prev[currentAssessment.unique_id],
            status: newStatus,
            explanations: data.data[0].question_explained,
            answered:true,
            ...(matchFieldExists && { match: data.data[0].match }),
          },
        }));
        setValidateClicked(true);
      });
  }

  async function endAssessment() {
    await fetch(`${baseurl}/assement/end-batch-test`, {
      method: "POST",
      body: JSON.stringify({
        learning_id: learning_id,
        topic_id: Topic.id,
        user_id: USERID,
        main_topic_id: main_topic_id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
       if(data.status===true){
        setResult(true)
        setSummaryDetail(data.data)
        setFinishPopup(false)
       }
      });
  }

  async function assessmentSummary(userID,attemptID) {
    await fetch(`${baseurl}/get-assessments/summary`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userID,
        attempt_id: attemptID
    }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
       if(data.status===true){
        setResult(true)
        setSummaryDetail(data.summary)
        setFinishPopup(false)
       }
      });
  }


  useEffect(() => {
    assessmentGet();
  }, [location.state]);

  useEffect(() => {
    if (assessment.length > 0) {
        const currentAssmnt = assessment[currentIndex];
        const uniqueId = currentAssmnt?.unique_id;

        setCurrentAssessment(currentAssmnt);

        if (!assessmentData[uniqueId]) {
            let initialAnswers;
            let initialAcrossClues = [];
            let initialDownClues = [];
            let highlightContent = ""
            let lastSelectedText = ""

            if (currentAssmnt.type === "Match the following" || currentAssmnt.type === "Match the following with image") {
                const filteredLeft = (currentAssmnt.left || []).filter(
                    (leftData) => leftData.trim() !== ""
                );
                const filteredRight = (currentAssmnt.right || []).filter(
                    (rightData) => rightData.trim() !== ""
                );
                initialAnswers = {
                    left: filteredLeft,
                    right: filteredRight,
                };
            } else if (currentAssmnt.type === "Rearrange") {
                initialAnswers = currentAssmnt.options || [];
            } else if (currentAssmnt.type === 'cross word puzzle') {
                const grid = currentAssmnt.options.map(row => (
                    row.map(cell => ({
                        clueID: cell.clueID || "",
                        clueNumber: cell.clueNumber,
                        cluevalue: '',
                        disabled: cell.cluevalue === '',
                    }))
                ));
                initialAnswers = grid;

                initialAcrossClues = currentAssmnt.left.map(clueObj => ({
                    id: clueObj.id,
                    number: clueObj.number,
                    clue: clueObj.clue,
                    answer: ''
                }));
                initialDownClues = currentAssmnt.right.map(clueObj => ({
                    id: clueObj.id,
                    number: clueObj.number,
                    clue: clueObj.clue,
                    answer: ''
                }));
            }else if(currentAssmnt.type === "highlight"){
              const tempDiv = document.createElement('div');
              tempDiv.innerHTML = currentAssmnt.options;
              highlightContent = tempDiv.textContent || tempDiv.innerText || ""; 
              lastSelectedText = "";
            } else {
                initialAnswers = [];
            }

            setAssessmentData((prev) => ({
                ...prev,
                [uniqueId]: {
                    answers: initialAnswers,
                    status: "",
                    explanations: "",
                    cluesAcross: initialAcrossClues, 
                    cluesDown: initialDownClues, 
                    highlightContent:highlightContent,
                    lastSelectedText:lastSelectedText,
                    originalContent: highlightContent
                },
            }));
        } else if (!assessmentData[uniqueId]?.status) {
            setAssessmentData((prev) => ({
                ...prev,
                [uniqueId]: {
                    ...prev[uniqueId],
                    status: 'viewed',
                },
            }));
        }
    }
}, [currentIndex, assessment, assessmentData]);

useEffect(() => {
  if (currentAssessment) {
      if (!inputRefs.current[currentAssessment.unique_id]) {
          inputRefs.current[currentAssessment.unique_id] = [];
      }
  }
}, [currentAssessment]);
useEffect(() => {
  const uniqueId = currentAssessment.unique_id;
  const container = highlightRefs.current[uniqueId];

  if (container) {
    const spans = container.querySelectorAll('.highlighted');
    spans.forEach((span) => {
      const newColor = getHighlightColor(assessmentData[uniqueId]?.status);
      span.style.color = newColor; 
    });
  }
}, [assessmentData[currentAssessment.unique_id]?.status]);

useEffect(()=>{
const userType=userStatus === 'succeeded' && userDetails &&userDetails.user_type
console.log(userType);

if(userType==="Trail"){
  setTrailUser(true)
}
else{
  setTrailUser(false)
}
},[])
  

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={error}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setError(false);
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          sx={{ width: "100%", fontSize: "1rem" }}
        >
          Please enter answer..
        </Alert>
      </Snackbar>
      <div className={style.assessmentSection}>
        <div className={style.header} style={{background:trialUser?"#FF8800":""}}>
          <div className={style.headerText}>
            {trialUser&&result?<h4>Trial Test</h4>:
           <>
              <span>Topic {topicNo}</span>
              <h4>{Topic.value}</h4>
           </>
            }
          </div>
        </div>
        {loading?<Loader/>:
        <>
        {currentAssessment.id ? (
          <div className={style.body}>

           <div className={style.bodyTop}>
              <div style={{border:result?"none":""}} className={style.bodyLeft}>
              {result ? (
                  <>
                    <div className={style.resultSection}>
                          {
                            summaryDetail?.percentage!== undefined && (
                              <>
                                {summaryDetail.percentage >= 85 ? (
                                  <>
                                    <div className={style.resultSectionHeder}>
                                      <h4 style={{color:"#D09B00"}}>Congratulations! </h4>
                                      <p>You’ve done an excellent job in the test. Keep up the great work.</p>
                                    </div>
                                    <div className={style.resultSectionImage}>
                                      <img src={TrophyImage} alt="Winner" />
                                    </div>
                                  </>
                                ) : summaryDetail.percentage >= 60 ? (
                                  <>
                                    <div className={style.resultSectionHeder}>
                                      <h4 style={{color:"#00CBF4"}}>Well Done! </h4>
                                      <p>You’ve performed well in the test. Keep up the good work.</p>
                                    </div>
                                    <div className={style.resultSectionImage}>
                                      <img src={SecondTrophyImage} alt="Winner" />
                                    </div>
                                  </>
                                ) : summaryDetail.percentage >= 40 ? (
                                  <>
                                    <div className={style.resultSectionHeder}>
                                      <h4 style={{color:"#FF8800"}}>Nice effort!</h4>
                                      <p>There’s room for improvement, but you’re on the right track. Keep practicing.</p>
                                    </div>
                                    <div className={style.resultSectionImage}>
                                      <img src={ThirdTrophyImage} alt="Average" />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className={style.resultSectionHeder}>
                                      <h4 style={{color:"#FF2443"}}>Don't give up!</h4>
                                      <p>With consistent practice, you can achieve your goals.</p>
                                    </div>
                                    <div className={style.resultSectionImage}>
                                      <img src={FourthTrophyImage} alt="Try Again" />
                                    </div>
                                  </>
                                )}
                                <div className={style.resultSectionBottomContent}>
                                  <p>You’ve scored</p>
                                  <h4>{summaryDetail.correct_answers}</h4>
                                </div>
                              </>
                            )
                          }
                        </div>
                        {!trialUser?
                        <div className={style.resultBottom}>
                          <p>The journey to mastering grammar continues! </p>
                          <h4>Let's move on to the next topic and keep improving.</h4>
                          <button onClick={(e) => {
                  e.preventDefault();
                 navigate("/new/learnings")
                }}>Next topic &nbsp;{">>"}</button>
                        </div>
                        :null}
                  </>
                ) : (
                <>
                  <div className={style.questionSection}>
                    <div className={style.question}>
                      <div className={style.questionLeft}><span>{currentAssessment.question_no}.</span></div>
                      <div className={style.questionRight}>
                        <div className={style.questionContainer}>
                          {currentAssessment.type === "fill in the blanks" ? (
                            <div className={style.inputWrapper}>
                              {renderQuestionWithInputs( currentAssessment.question )}
                            </div>
                          ) : currentAssessment.type === "select the answers" ? (
                            <p>
                              {renderQuestionWithDropdowns(
                                currentAssessment.question,
                                currentAssessment.options
                              )}
                            </p>
                          ): (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: currentAssessment.question,
                              }}
                            ></p>
                          )}
                        </div>
                        <div className={style.questionBody}>
                      {currentAssessment.type === "ordinary" ? (
                        <div className={style.ordinaryContainer}>
                          <div className={style.ordinarySection}>
                            <div className={style.ordinarySectionRight}>
                            <input
                                disabled={assessmentData[currentAssessment.unique_id]?.answered}
                                style={{
                                  backgroundColor: assessmentData[currentAssessment.unique_id]?.status === "correct"
                                    ? "#00DFC7"
                                    : assessmentData[currentAssessment.unique_id]?.status === "incorrect"
                                    ? "#FF7C8E"
                                    : "transparent"
                                }}
                                onChange={(e) => {
                                  const uniqueId = currentAssessment.unique_id;
                                  setAssessmentData((prev) => ({
                                    ...prev,
                                    [uniqueId]: {
                                      ...prev[uniqueId],
                                      answers: [e.target.value],
                                    },
                                  }));
                                }}
                                value={assessmentData[currentAssessment.unique_id]?.answers[0] || ""}
                              />
                            </div>
                          </div>
                        </div>
                      ) : currentAssessment.type === "multiple choices" ? (
                        <div className={style.multipleChoiceContainer}>
                          {currentAssessment.options?.filter(option => option.trim() !== "").map((option, index) => {
                             const selectedIndexes = selectedOptions[currentAssessment.unique_id] || [];
                             const isSelected = selectedIndexes.includes(index);
                             const status = assessmentData[currentAssessment.unique_id]?.status;
                 
                             let optionClass = "";
                             if (isSelected) {
                                 optionClass =
                                     status === "correct"
                                         ? style.correct
                                         : status === "incorrect"
                                         ? style.incorrect
                                         : style.selected;
                             } else {
                                 optionClass = style.unselected;
                             }
                 
                            return (
                              <div
                                key={index}
                                onClick={() =>{
                                  if(assessmentData[currentAssessment.unique_id]?.answered!==true){
                                   handleOptionClick(option, index)}}
                                  }
                                className={`${style.multipleChoiceAnswer} ${optionClass}`}
                              >
                                <p>{option}</p>
                              </div>
                            );
                          })}
                        </div>
                      ) : currentAssessment.type === "Match the following" ? (
                        <div className={style.matchFollowingContainer}>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                          {(assessmentData[currentAssessment.unique_id]?.answers?.left || []).filter(leftData => leftData.trim() !== "").map((leftData, i) => {
                             const matchValue = assessmentData[currentAssessment.unique_id]?.match?.[i];
                             let cardClassName;
                             if (matchValue === "1") {
                               cardClassName = style.correctCard;
                             } else if (matchValue === "0") {
                               cardClassName = style.errorCard;
                             } else {
                               cardClassName = style.defaultCard;
                             }
                             return (
                            <React.Fragment key={i}>
                              <Droppable droppableId={`left-${i}`}>
                                {(provided) => (
                                  <div
                                    className={style.matchFollowingLeft}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    <Draggable
                                      draggableId={`left-${i}`}
                                      index={i}
                                      isDragDisabled={
                                        currentAssessment.draggable === "right" ||
                                        assessmentData[currentAssessment.unique_id]?.answered
                                      }
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={`${style.matchFollowingCard} ${cardClassName}`}
                                        >
                                          <p>{leftData}</p>
                                        </div>
                                      )}
                                    </Draggable>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                    
                              <Droppable droppableId={`right-${i}`}>
                                {(provided) => (
                                  <div
                                    className={style.matchFollowingRight}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    <Draggable
                                      draggableId={`right-${i}`}
                                      index={i}
                                      isDragDisabled={
                                        currentAssessment.draggable === "left" ||
                                        assessmentData[currentAssessment.unique_id]?.answered
                                      }
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={`${style.matchFollowingCard} ${cardClassName}`}
                                        >
                                          <p>{(assessmentData[currentAssessment.unique_id]?.answers?.right || [])[i]}</p>
                                        </div>
                                      )}
                                    </Draggable>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </React.Fragment>
                          )})}
                        </DragDropContext>
                      </div>
                     
                      ) : currentAssessment.type === "Match the following with image" ? (
                        <div className={style.matchFollowingContainer}>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                          {(assessmentData[currentAssessment.unique_id]?.answers?.left || []).filter(leftData => leftData.trim() !== "").map((leftData, i) => {
                             const matchValue = assessmentData[currentAssessment.unique_id]?.match?.[i];
                             let cardClassName;
                             if (matchValue === "1") {
                               cardClassName = style.correctCard;
                             } else if (matchValue === "0") {
                               cardClassName = style.errorCard;
                             } else {
                               cardClassName = style.defaultCard;
                             }
                             return (
                            <React.Fragment key={i}>
                              <Droppable droppableId={`left-${i}`}>
                                {(provided) => (
                                  <div
                                    className={style.matchFollowingLeft}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    <Draggable
                                      draggableId={`left-${i}`}
                                      index={i}
                                      isDragDisabled={
                                        currentAssessment.draggable === "right" ||
                                        assessmentData[currentAssessment.unique_id]?.answered
                                      }
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={`${style.matchFollowingImageCard} ${cardClassName}`}
                                        >
                                          <img src={leftData} alt={`Option ${i + 1}`}  />
                                        </div>
                                      )}
                                    </Draggable>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                    
                              <Droppable droppableId={`right-${i}`}>
                                {(provided) => (
                                  <div
                                    className={style.matchFollowingRight}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    <Draggable
                                      draggableId={`right-${i}`}
                                      index={i}
                                      isDragDisabled={
                                        currentAssessment.draggable === "left" ||
                                        assessmentData[currentAssessment.unique_id]?.answered
                                      }
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={`${style.matchFollowingCard} ${cardClassName}`}
                                        >
                                          <p>{(assessmentData[currentAssessment.unique_id]?.answers?.right || [])[i]}</p>
                                        </div>
                                      )}
                                    </Draggable>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </React.Fragment>
                          )})}
                        </DragDropContext>
                      </div>
                      ) : currentAssessment.type === "Rearrange" ? (
                        <DragDropContext onDragEnd={handleArrangeDragEnd}>
                          <Droppable droppableId="rearrange" direction="horizontal">
                            {(provided) => (
                              <div
                                className={style.reArrangeContainer}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {assessmentData[currentAssessment.unique_id]?.answers.map((option, index) => (
                                  <Draggable
                                    key={index}
                                    draggableId={`option-${index}`}
                                    index={index}
                                  >
                                    {(provided) => (
                                      
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={style.reArrangeCard}
                                      >
                                        <p>{option}</p>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                          <div className={style.statusMark}>
                            {assessmentData[currentAssessment.unique_id]?.answered && (
                              assessmentData[currentAssessment.unique_id]?.status === "correct" ? (
                                <FaCheck style={{ color: "#00DFC7"}} />
                              ) : (
                                <IoMdClose style={{ color: "#FF7C8E" }} />
                              )
                            )}
                          </div>
                        </DragDropContext>
                      ) : currentAssessment.type === "cross word puzzle"? (
                        <div className={style.crossWordContainer}>
                        <div className={style.crossWordPuzzle}>
                        {assessmentData[currentAssessment.unique_id]?.answers.map((row, rowIndex) => (
                          <div key={rowIndex} className={style.row}>
                            {row.map((cell, colIndex) => (
                              <div key={`${rowIndex}-${colIndex}`} className={style.cellContainer}>
                                {!cell.disabled && (
                                  <input
                                    type="text"
                                    maxLength="1"
                                    className={ style.activeInput}
                                    value={cell.value}
                                    onChange={(e) => handleCellValueChange(rowIndex, colIndex, e.target.value, cell.clueID, cell.clueNumber)}
                                    disabled={cell.disabled}
                                    ref={el => {
                                      if (el) {
                                          if (!inputRefs.current[currentAssessment.unique_id]) {
                                              inputRefs.current[currentAssessment.unique_id] = [];
                                          }
                                          inputRefs.current[currentAssessment.unique_id][rowIndex * (assessmentData[currentAssessment.unique_id]?.answers[0]?.length || 0) + colIndex] = el;
                                      }
                                  }}
                                  />
                                )}
                                {cell.clueNumber && (  
                                  <div className={style.clueNumber}>{cell.clueNumber}</div>
                                )}
                              </div>
                            ))}
                          </div>
                          ))}
                      </div>
                      <div className={style.PuzzleQuestion}>
                        <div className={style.QuestionAcross}>
                        <h4>Across</h4>
                        {assessmentData[currentAssessment.unique_id]?.cluesAcross.map((clueObj, index) => (
                          <div key={index} className={style.QuestionAcrossInputContainer}>
                            <span>{clueObj.number}.{clueObj.clue} </span>
                            
                          </div>
                        ))}
                        
                        </div>
                        <div className={style.QuestionDown}>
                        <h4>Down</h4>
                        {assessmentData[currentAssessment.unique_id]?.cluesDown.map((clueObj, index) => (
                            <div key={index} className={style.QuestionDownInputContainer}>
                              <span>{clueObj.number}.{clueObj.clue} </span>
                              
                            </div>
                          ))}
                          
                          </div>
                      </div>
                        </div>
                      ):currentAssessment.type === "Rewrite" ? (
                        <div className={style.ordinaryContainer}>
                          <div className={style.ordinarySection}>
                            <div className={style.ordinarySectionRight}>
                            <textarea
                            rows={6}
                                disabled={assessmentData[currentAssessment.unique_id]?.answered}
                                style={{
                                  backgroundColor: assessmentData[currentAssessment.unique_id]?.status === "correct"
                                    ? "#00DFC7"
                                    : assessmentData[currentAssessment.unique_id]?.status === "incorrect"
                                    ? "#FF7C8E"
                                    : "transparent"
                                }}
                                onChange={(e) => {
                                  const uniqueId = currentAssessment.unique_id;
                                  setAssessmentData((prev) => ({
                                    ...prev,
                                    [uniqueId]: {
                                      ...prev[uniqueId],
                                      answers: [e.target.value],
                                    },
                                  }));
                                }}
                                value={assessmentData[currentAssessment.unique_id]?.answers[0] || ""}
                              />
                            </div>
                          </div>
                        </div>
                      ):currentAssessment.type === "highlight" ? (
                        <div
                        ref={(el) => (highlightRefs.current[currentAssessment.unique_id] = el)}
                          dangerouslySetInnerHTML={{ __html: assessmentData[currentAssessment.unique_id]?.highlightContent || "" }}
                          onMouseUp={handleWordSelection}
                          onDoubleClick={(event) => event.preventDefault()}
                          onClick={(event) => event.preventDefault()}
                          style={{
                            height: "100px", 
                            overflowY: "auto",  
                            outline: "none", 
                            width: "100%",
                            pointerEvents: assessmentData[currentAssessment.unique_id]?.answered ? 'none' : 'auto', 
                            userSelect: assessmentData[currentAssessment.unique_id]?.answered ? 'none' : 'text', 
                          }}
                        />
                      ):(
                        ""
                      )}
                    </div>
                    <div className={style.questionButtonSection}>
                      <div className={style.questionButtonLeft}>
                        {currentAssessment.type === "Match the following" ?<span>*Drag and place the answer.</span>:""}
                        {currentIndex!==assessment.length-1?
                        <p onClick={() =>{
                          if(currentIndex !== assessment.length - 1){
                              handleSkip()
                          }
                        }}
                            >Skip</p>
                            :null}
                      </div>
                      <div className={style.questionButtonRight}>
                        {assessmentData[currentAssessment.unique_id]?.answered ? (
                          <>
                          {currentIndex!==assessment.length-1?
                            <button
                              onClick={() => handleNext()}
                              disabled={currentIndex === assessment.length - 1}
                              style={{ background: "#00CBF480" }}
                            >
                              Next
                            </button>:null}
                          </>
                        ) : (
                          <button
                        onClick={() => {
                          const uniqueId = currentAssessment.unique_id;
                          const answers = assessmentData[uniqueId]?.answers || [];

                          if (
                            currentAssessment.type === "Match the following" ||
                            currentAssessment.type === "Match the following with image"
                          ) {
                            const rightFromAssessment = currentAssessment.right.filter(right => right.trim() !== "") || [];
                            const rightFromData = answers?.right || [];

                            const areRightAnswersSame =
                              rightFromAssessment.length === rightFromData.length &&
                              rightFromAssessment.every((value, index) => value === rightFromData[index]);

                            if (areRightAnswersSame) {
                              setError(true);
                              return;
                            } else {
                              assessmentAnswer();
                              setValidateClicked(true);
                            }
                          }else if (currentAssessment.type === "Rearrange") {
                            const options = currentAssessment.options.filter(op => op.trim() !== "") || [];
                            const areAnswersSame =
                            options.length === answers.length &&
                            options.every((value, index) => value === answers[index]);
                            if (areAnswersSame) {
                              setError(true);
                              return;
                            } else {
                              assessmentAnswer();
                              setValidateClicked(true);
                            }
                            
                          }
                          else if(currentAssessment.type === "highlight"){
                            if(assessmentData[uniqueId]?.lastSelectedText===""){
                              setError(true);                            
                            }
                            else{
                              assessmentAnswer();
                             setValidateClicked(true);
                            }
                          }else if (currentAssessment.type === "select the answers") {
                            // Check if all dropdowns are answered
                            const allAnswered = currentAssessment.options.every((option, index) => {
                              const dropdownId = option.id; 
                              return answers[dropdownId] && answers[dropdownId].trim() !== ""; 
                            });
                      
                            if (!allAnswered) {
                              setError(true); 
                              return;
                            }
                      
                            assessmentAnswer();
                            setValidateClicked(true);
                          } 
                          else if (answers.length > 0) {
                            assessmentAnswer();
                            setValidateClicked(true);
                          } 
                          else {
                            setError(true);
                          }
                        }}
                      >
                        Submit
                      </button>

                        )}
                      </div>
                    </div>
                    {assessmentData[currentAssessment.unique_id]?.answered ? (
                    <div className={style.explanationSection}>
                      
                          <div  className={style.resultText}>
                            <p  style={{color: assessmentData[currentAssessment.unique_id]?.status === "correct" ? "#00DFC7" : "#FF2443"}}>
                            {assessmentData[currentAssessment.unique_id]?.status === "correct"
                              ? "You are correct!"
                              : "That’s ok! Keep going. "}
                            </p>
                          </div>
                       
                      { assessmentData[currentAssessment.unique_id]?.answered && assessmentData[currentAssessment.unique_id]?.explanations? (
                        <>
                          <div className={style.explanationHeading}>
                            <p>Explanation:</p>
                          </div>
                          <div className={style.explanationText}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: assessmentData[currentAssessment.unique_id]?.explanations,
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                      </div>
                    </div>
                  </div>
                </>
                )}
              </div>
              <div className={style.bodyRight}>
              {result ? (
                  <div className={style.summeryContainer}>
                    <div className={style.summeryTopContent}>
                      <div className={style.roundGraph}>
                        <Doughnut
                          data={{
                            labels: ["Completed", "Remaining"],
                            datasets: [
                              {
                                data: [
                                  summaryDetail?.percentage || 0, 
                                  100 - (summaryDetail?.percentage || 0), 
                                ],
              
                                backgroundColor: ["#03CD09", "#3E3D4212"],
                                borderWidth: 0,
                              },
                            ],
                          }}
                          options={{
                            cutout: "85%",
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          }}
                        />
                        <div
                          className={style.graphText}
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            pointerEvents: "none",
                          }}
                        >
                          {Math.round(summaryDetail?.percentage || 0)}%
  
                        </div>
                      </div>
                      <div className={style.summeryTopText}>
                        <p>% of marks acquired</p>
                      </div>
                    </div>
                    <div className={style.summeryBottomContent}>
                      <div className={style.summeryBottomData}>
                        <div className={style.summeryBottomDataLeft}>
                          <span style={{ background: "#00DFC7" }}>{summaryDetail?.correct_answers || 0}</span>
                        </div>
                        <div className={style.summeryBottomDataRight}>
                          <p>Correct Answers</p>
                        </div>
                      </div>
                      <div className={style.summeryBottomData}>
                        <div className={style.summeryBottomDataLeft}>
                          <span style={{ background: "#FF2443" }}>{summaryDetail?.wrong_answers || 0} </span>
                        </div>
                        <div className={style.summeryBottomDataRight}>
                          <p>Wrong Answers</p>
                        </div>
                      </div>
                      <div className={style.summeryBottomData}>
                        <div className={style.summeryBottomDataLeft}>
                          <span style={{ background: "#FFBE00" }}>{summaryDetail?.assessments_count-(summaryDetail?.correct_answers +summaryDetail?.wrong_answers)}</span>
                        </div>
                        <div className={style.summeryBottomDataRight}>
                          <p>Skipped Answers</p>
                        </div>
                      </div>
                      <div className={style.summeryBottomData}>
                        <div className={style.summeryBottomDataLeft}>
                          <span style={{ background: "#595BDB" }}>{summaryDetail?.assessments_count || 0} </span>
                        </div>
                        <div className={style.summeryBottomDataRight}>
                          <p>Total Questions</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                <>
                  <div className={style.markContainer}>
                    <div className={style.markData}>
                      <div className={style.markDataLeft}>
                        <p>Total mark</p>
                      </div>
                      <div
                        style={{ marginBottom: "8px" }}
                        className={style.markDataRight}
                      >
                        <p>
                          <span>{correctCount}</span>/{totalCount}
                        </p>
                      </div>
                    </div>
                    <div className={style.markData}>
                      <div className={style.markDataLeft}>
                        <p>Correct</p>
                      </div>
                      <div className={style.markDataRight}>
                        <p>{correctCount}</p>
                      </div>
                    </div>
                    <div className={style.markData}>
                      <div className={style.markDataLeft}>
                        <p>Wrong</p>
                      </div>
                      <div className={style.markDataRight}>
                        <p>{incorrectCount}</p>
                      </div>
                    </div>
                    <div className={style.markData}>
                      <div className={style.markDataLeft}>
                        <p>Unattended</p>
                      </div>
                      <div className={style.markDataRight}>
                        <p>{skippedCount}</p>
                      </div>
                    </div>
                  </div>
                  <div className={style.FinishButton}>
                        <button onClick={()=>{ 
                          setFinishPopup(true)
                        }}>End Test</button>
                      </div>
                  <div className={style.questionPaletteHeader}>
                    <p>Question Palette:</p>
                  </div>
                  <div className={style.questionPalette}>
                    {numbers.map((number, index) => {
                      const questionId = assessment[index]?.unique_id;
                      const status = assessmentData[questionId]?.status;
    
                      let backgroundColor;
                      if (currentIndex === index) {
                        backgroundColor = "#D9D9D9"; 
                      } else if (status === "correct") {
                        backgroundColor = "#00DFC7";
                      } else if (status === "incorrect") {
                        backgroundColor = "#FF7C8E";
                      }else if (status === "skipped") {
                        backgroundColor = "#818085";
                      } else {
                        backgroundColor = "#FFFFFF";
                      }
    
                      const numberStyle = {
                        backgroundColor,
                      };
    
                      return (
                        <div
                        onClick={() => {
                          const uniqueId = currentAssessment?.unique_id;
                          if (assessmentData[uniqueId]?.status === 'viewed') {
                            setAssessmentData((prev) => ({
                              ...prev,
                              [uniqueId]: {
                                ...prev[uniqueId],
                                status: 'skipped',
                              },
                            }));
                            if (uniqueId && assessmentData[uniqueId]) {
                              const currentAssmnt = assessment[currentIndex];
                              let resetAnswers;
                              let resetHighlightContent = "";
                              let resetLastSelectedText = "";
                              let resetAcrossClues = [];
                              let resetDownClues = [];
                      
                              if (currentAssmnt.type === "Match the following" || currentAssmnt.type === "Match the following with image") {
                                const filteredLeft = (currentAssmnt.left || []).filter(
                                  (leftData) => leftData.trim() !== ""
                              );
                              const filteredRight = (currentAssmnt.right || []).filter(
                                  (rightData) => rightData.trim() !== ""
                              );
                                  resetAnswers = {
                                      left: filteredLeft,
                                      right: filteredRight,
                                  };
                              } else if (currentAssmnt.type === "Rearrange") {
                                  resetAnswers = currentAssmnt.options || [];
                              } else if (currentAssmnt.type === 'cross word puzzle') {
                                  resetAnswers = currentAssmnt.options.map(row => (
                                      row.map(cell => ({
                                          clueID: cell.clueID || "",
                                          clueNumber: cell.clueNumber,
                                          cluevalue: '',
                                          disabled: cell.cluevalue === '',
                                      }))
                                  ));
                      
                                  resetAcrossClues = currentAssmnt.left.map(clueObj => ({
                                      id: clueObj.id,
                                      number: clueObj.number,
                                      clue: clueObj.clue,
                                      answer: ''
                                  }));
                                  resetDownClues = currentAssmnt.right.map(clueObj => ({
                                      id: clueObj.id,
                                      number: clueObj.number,
                                      clue: clueObj.clue,
                                      answer: '' 
                                  }));
                              } else if (currentAssmnt.type === "highlight") {
                                  const tempDiv = document.createElement('div');
                                  tempDiv.innerHTML = currentAssmnt.options;
                                  resetHighlightContent = tempDiv.textContent || tempDiv.innerText || ""; 
                                  resetLastSelectedText = "";
                              } else {
                                  resetAnswers = [];
                              }
                      
                              setAssessmentData((prev) => ({
                                  ...prev,
                                  [uniqueId]: {
                                      ...prev[uniqueId],
                                      answers: resetAnswers,
                                      highlightContent: resetHighlightContent,
                                      lastSelectedText: resetLastSelectedText,
                                      cluesAcross: resetAcrossClues,
                                      cluesDown: resetDownClues,
                                  },
                              }));
                              setSelectedOptions((prev) => {
                                const { [uniqueId]: _, ...rest } = prev; 
                                return rest;
                            });
                          }
                          }
                          setCurrentIndex(number - 1);
                          setValidateClicked(false);
                        }}
                          key={number}
                          className={style.number}
                          style={numberStyle}
                        >
                          <p> {number}</p>
                        </div>
                      );
                    })}
                  </div>
                </>
                )}
              </div>
           </div>
           {trialUser&&result?(
           <div className={style.bodyBottom}>
            <div className={style.subscriptionHeader}>
              <h4>You have successfully completed your trial!</h4>
                <span>Ready to take your English to the next level? </span>
                <p>Subscribe now and gain unlimited access to:</p>
                <button onClick={()=>{
                  navigate("/subscribe",{
                    state:{
                      userID:USERID,
                      Username:UserName
                    }
                  })
                }}>Subscribe Now</button>
            </div>
            <div className={style.subscriptionGrid}>
              <div className={style.subscriptionCard}>
                <div className={style.subscriptionCardImage}><img src={lessonImage} alt="Lesson"/></div>
                <div className={style.subscriptionCardText}>
                  <p>Comprehensive Lessons:</p>
                  <span> Master every aspect of English with native designed lessons.</span>
                </div>
              </div>
              <div className={style.subscriptionCard}>
                <div className={style.subscriptionCardImage}><img src={motivationImage} alt="Motivation"/></div>
                <div className={style.subscriptionCardText}>
                  <p>Motivational Milestones: 
                  </p>
                  <span> Celebrate your progress and stay motivated with each milestone.</span>
                </div>
              </div>
              <div className={style.subscriptionCard}>
                <div className={style.subscriptionCardImage}><img src={questionImage} alt="Question"/></div>
                <div className={style.subscriptionCardText}>
                  <p>Assessment Questionnaires: 
                  </p>
                  <span> Measure your mastery and refine your skills through targeted quizzes.</span>
                </div>
              </div>
              <div className={style.subscriptionCard}>
                <div className={style.subscriptionCardImage}><img src={timeImage} alt="Time"/></div>
                <div className={style.subscriptionCardText}>
                  <p>Flexible Learning:</p>
                  <span>Study anytime, anywhere, at your own pace.</span>
                </div>
              </div>


            </div>
            <div className={style.subscriptionBottom}>
             
                <p>Subscribe Today and <br/>
                Transform Your English Learning Journey!</p>
                <button onClick={()=>{
                  navigate("/subscribe",{
                    state:{
                      userID:USERID,
                      Username:UserName
                    }
                  })
                }}>Subscribe Now</button>
            </div>
           </div>)
           :
           null
           }
          </div>
        ) : (
          <div className={style.info}><p>No Assessment found</p></div>
        )}
        </>}
      </div>

      {finishPopup?
      <>
      <div className={style.popupOverlay}></div>
        <div className={style.Popup}>
          <div className={style.popupText}>
            <p>Are you sure you want to end the test?</p>
          </div>
          <div className={style.PopupButton}>
          <button
            className={style.noButton}
              onClick={() => {
                setFinishPopup(false);
              }}
            >
              No
            </button>
            <button
            className={style.yesButton}
              onClick={(e) => {
                e.preventDefault();
               endAssessment();
              }}
            >
              Yes
            </button>
           
          </div>
        </div>
      </>:""}
    </div>
  );
};

export default Assessment;
